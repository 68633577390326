import React, { PropsWithChildren } from "react"
import { Button, ButtonSizes } from "dsl/src/atoms/Button"
import Link from "dsl/src/atoms/Link/Link"
import Loader, { LoaderSizes, LoaderTypes } from "dsl/src/atoms/Loader/Loader"
import RadioButton from "dsl/src/atoms/RadioButton/RadioButton"
import { Select } from "dsl/src/atoms/Select/Select"
import { SelectItemData, SelectSizes } from "dsl/src/atoms/Select/types"
import Typography, { TType } from "dsl/src/atoms/Typography/Typography"
import { observer } from "mobx-react-lite"
import {
  Category,
  FilterParamsCollection,
  ListingResult,
} from "shared-libs/src/js/libs/services/listing-services/utils/listing-services.types"
import { FilterListType } from "shared-libs/src/js/shared-views/listings/utils/listing.types"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import { prepareImgixUrl } from "../../../../libs/helpers/prepare-imgix-url"
import { TM } from "../../../TypographyI18n"
import { useTranslate } from "../../../hooks/useTranslate"
import { I18N } from "../../../i18n"
import { CardTool } from "../../atoms/CardTool/CardTool"
import { Thumb88 } from "../../atoms/Thumb88/Thumb88"
import { EditorToolList } from "./EditorToolList"
import styles from "./EditorToolProductSwitcher.module.scss"

interface FilterProps {
  filter: FilterListType
  selectedFilters: FilterParamsCollection
  setRadioFilter: (slug: string, optionId: number) => void
}

const Filter = observer((props: FilterProps) => {
  const { filter, selectedFilters, setRadioFilter } = props
  const { filters: options } = filter

  if (!options.length) {
    return null
  }

  const selectedOptions = selectedFilters[filter.slug] || []

  const handleRadioFilter = (optionId: number): void => {
    setRadioFilter(filter.slug, optionId)
  }

  const isSelected = (optionId: number): boolean =>
    selectedOptions.includes(optionId)

  return (
    <div className={styles.filter_wrapper}>
      <FilterHeader title={filter.title} />
      <div className={styles.filter_options}>
        {options.map((option) => (
          <FilterRadio
            key={option.id}
            handleRadioFilter={handleRadioFilter}
            isSelected={isSelected}
            optionId={option.id}
            title={option.title}
          />
        ))}
      </div>
    </div>
  )
})

interface FilterHeaderProps {
  title: string
}

const FilterHeader = (props: FilterHeaderProps) => (
  <Typography type={TType.Body14_24}>{props.title}</Typography>
)

interface FilterRadioProps {
  handleRadioFilter: (optionId: number) => void
  isSelected: (optionId: number) => boolean
  optionId: number
  title: string
}

const FilterRadio = (props: FilterRadioProps) => {
  const { isSelected, optionId, title, handleRadioFilter } = props
  const handleClick = () => handleRadioFilter(optionId)

  return (
    <div className={styles.filter_option} onClick={handleClick}>
      <RadioButton selected={isSelected(optionId)} />
      <Typography type={TType.Body14_24}>{title}</Typography>
    </div>
  )
}

const ProductTilesPresenter = observer(() => {
  const [containerSet] = useContainerSet((c) => [c.productSwitcher])
  const t = useTranslate()

  if (!containerSet) {
    return null
  }

  const { listingStore } = containerSet.productSwitcher

  if (!listingStore) {
    return null
  }

  if (listingStore.isLoading) {
    return (
      <div className={styles.empty_list}>
        <Loader type={LoaderTypes.circular} size={LoaderSizes.medium} />
      </div>
    )
  }

  if (listingStore.items.length === 0) {
    return (
      <div className={styles.empty_list}>
        <TM
          id={I18N.editorTools.productSwitcher.noResults}
          type={TType.Header20_24}
        />
        <Button
          size={ButtonSizes.small}
          onClick={listingStore.clearFilters.bind(listingStore)}
        >
          {t(I18N.editorTools.productSwitcher.clearFilters)}
        </Button>
      </div>
    )
  }

  return (
    <EditorToolList size="small">
      {listingStore.items.map((item) => (
        <ProductTile key={item.id} item={item} />
      ))}
    </EditorToolList>
  )
})

interface ProductTileProps {
  item: ListingResult
}

const ProductTile = observer((props: ProductTileProps) => {
  const [containerSet] = useContainerSet((c) => [
    c.productSwitcher,
    c.designAndProductDriver,
    c.analytics,
  ])

  const [isLoading, setIsLoading] = React.useState(false)

  if (!containerSet) {
    return null
  }

  const { analyticsController } = containerSet.analytics
  const { productSwitcherUiController } = containerSet.productSwitcher
  const { productDesignUiController, productDesignStore } =
    containerSet.designAndProductDriver

  const { item } = props
  const productId = item.attributes.product.id
  const variantSku = item.attributes.default_variant.sku
  const legacyShortSku = item.sku
  const isSelected = productSwitcherUiController?.isSelected(
    productId,
    legacyShortSku
  )
  const thumbnail = item.thumbnail
  const imageUrl = thumbnail?.optimized?.cdnUrl || thumbnail?.url
  const handleClick = async () => {
    if (isSelected) {
      return
    }

    setIsLoading(true)

    if (productDesignStore.state.isDesignTouched) {
      await productDesignUiController.save()
    }

    analyticsController?.trackProductSwitched()
    productSwitcherUiController?.switchProduct(variantSku, legacyShortSku)
  }

  return (
    <div className={styles.list_item} onClick={handleClick}>
      <Thumb88
        isActive={isSelected}
        imageURL={
          imageUrl &&
          prepareImgixUrl(imageUrl, { auto: "format", w: 176, q: 75 })
        }
        caption={item.content.title}
        truncateCaption={false}
        e2eTarget="product"
        e2eTargetName={`${item.id}`}
        hoverIcon={null}
        imgLoading="lazy"
        isLoading={isLoading}
      />
      {isSelected && !!item.url && (
        <Link
          href={productSwitcherUiController?.getProductPageUrl(item.url)}
          target="_blank"
        >
          <TM
            id={I18N.editorTools.productSwitcher.productPage}
            type={TType.Body12_16}
          />
        </Link>
      )}
    </div>
  )
})

const ProductSwitcherCard = (props: PropsWithChildren<{}>) => {
  return (
    <CardTool sizeXs cardName="product-switcher">
      <div className={styles.wrapper}>
        <TM
          id={I18N.tabs.productSwitcher}
          type={TType.Header16_24}
          htmlElement="h2"
        />
        {props.children}
      </div>
    </CardTool>
  )
}

export const EditorToolProductSwitcher = observer(() => {
  const [containerSet] = useContainerSet((c) => [c.productSwitcher])
  const t = useTranslate()

  if (!containerSet) {
    return (
      <ProductSwitcherCard>
        <div className={styles.empty_list}>
          <Loader type={LoaderTypes.circular} size={LoaderSizes.medium} />
        </div>
      </ProductSwitcherCard>
    )
  }

  const { listingStore } = containerSet.productSwitcher

  if (!listingStore) {
    return null
  }

  const formatItem = (category: Category): SelectItemData => {
    const name = category.slug === "all" ? t(category.title) : category.title

    return { ...category, name }
  }

  const formatSelectedItem = (category: Category): SelectItemData => {
    const item = formatItem(category)

    return {
      ...item,
      name: `${t(I18N.editorTools.productSwitcher.category)}: ${item.name}`,
    }
  }

  const items = listingStore.categories.map(formatItem)

  return (
    <ProductSwitcherCard>
      <div className={styles.selects_wrapper}>
        <Select
          items={items}
          selectedItem={formatSelectedItem(listingStore.selectedCategory)}
          size={SelectSizes.medium}
          handleSelectedItemChange={listingStore.setSelectedCategory.bind(
            listingStore
          )}
          withMenuFluid
        />

        <>
          {listingStore.filters.map((filter) =>
            filter.filterType !== "radio" ? null : (
              <Filter
                key={filter.slug}
                filter={filter}
                selectedFilters={listingStore.selectedFilters}
                setRadioFilter={listingStore.setRadioFilter.bind(listingStore)}
              />
            )
          )}
        </>

        <ProductTilesPresenter />
      </div>
    </ProductSwitcherCard>
  )
})
