import { AxiosInstance } from "axios"
import { OrderDto } from "../../types"
import { parseResponse } from "../helpers/parse-response"

export class OrderApi {
  constructor(private readonly api: AxiosInstance) {}

  public async get(number: string): Promise<OrderDto> {
    const response = await this.api.get(`/orders/${number}`)

    return parseResponse<OrderDto>(response)
  }
}
