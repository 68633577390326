// Events
import { Product } from "@ph/product-api"

export enum PricingEvents {
  loadPricing = "ph.pricing.load",
  loadPricingSuccess = "ph.pricing.loadSuccess",
  sendPricingDataToAuth = "ph.pricing.sendPricingDataToAuth",
}

export type PricingEventsTypes = {
  [PricingEvents.loadPricing]: (product: Product) => void
  [PricingEvents.loadPricingSuccess]: () => void
  [PricingEvents.sendPricingDataToAuth]: () => void
}
