import { AxiosInstance } from "axios"
import { parseResponse } from "../helpers/parse-response"
import { PricingDto, PricingPayload, VolumePriceDto } from "../../types"
import _groupBy from "lodash/groupBy"

export class PricingApi {
  constructor(private readonly api: AxiosInstance) {}

  public async get(params: PricingPayload): Promise<PricingDto> {
    const response = await this.api.get("/volume_prices", {
      params,
    })

    return _groupBy(
      parseResponse<VolumePriceDto[]>(response),
      (volumePrice) => volumePrice.variant.external_pim_sku
    )
  }
}
