import React from "react"

export enum ButtonSizes {
  large = "large",
  medium = "medium",
  small = "small"
}

type BorderRadius = "2px" | "4px"

export enum ButtonModes {
  primary = "primary",
  secondary = "secondary",
  destructive = "destructive",
  secondaryWhite = "secondary-white",
  secondaryMariner = "secondary-mariner",
  secondaryWhiteThree = "secondary-white-three",
  supportingTransparent = "supporting-transparent"
}
export interface ButtonBaseProps {
  /** For full-width buttons */
  fluid?: boolean
  /** For buttons that go full-width on mobile */
  fluidOnMobile?: boolean
  /** for loading after action */
  isLoading?: boolean
  /** for "Button with icon" */
  icon?: React.ComponentType | React.ReactNode
  /** Use one of ButtonSizes; Default: "medium" */
  size?: ButtonSizes
  /** Use one of ButtonModes. */
  mode?: ButtonModes

  mobileSize?: ButtonSizes
  e2eTarget?: string
  e2eTargetName?: string
  borderRadius?: BorderRadius
  isIconOnLeft?: boolean
}

export interface ButtonPlainProps
  extends ButtonBaseProps,
    React.ButtonHTMLAttributes<HTMLButtonElement> {}

export interface ButtonLinkProps
  extends ButtonBaseProps,
    React.AnchorHTMLAttributes<HTMLAnchorElement> {
  disabled?: boolean
  e2eTarget?: string
  e2eTargetName?: string
}
