import React from "react"
import cxBinder from "classnames/bind"
import styles from "./DropdownItem.module.scss"
const cx = cxBinder.bind(styles)

interface DropdownItemProps {
  name: string
  onClick: () => void
  e2eTarget?: string
  e2eTargetName?: string
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  withDivision?: boolean
}

export const DropdownItem = ({
  name,
  onClick,
  e2eTarget,
  e2eTargetName,
  Icon,
  withDivision,
}: DropdownItemProps) => {
  return (
    <>
      <div
        className={styles.wrapper}
        onClick={onClick}
        e2e-target={e2eTarget}
        e2e-target-name={e2eTargetName}
      >
        {Icon && <Icon />}
        {name}
      </div>
      <hr
        className={cx({
          wrapper__no_divider: !withDivision,
          wrapper__divider: withDivision,
        })}
      />
    </>
  )
}
