import React from "react"
import cxBinder from "classnames/bind"

import {
  getNextOrderDiscount,
  getUserCurrencySign,
  isEnglishLocale,
} from "../../utils/wallet.helpers"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { MoneyWalletCardProps } from "../../utils/wallet.types"
import { walletI18n } from "../../utils/wallet.translations"
import { UserBenefits } from "./UserBenefits"
import { SortedAccounts } from "./SortedAccounts"
import { NoCurrentStep } from "./NoCurrentStep"

import { TM } from "../../../../../shared-components/i18n/TypographyWithTranslate"
import RegionConfig from "../../../../../../configs/region-config/interfaces/js/region-config"

import styles from "./MoneyWalletCard.module.scss"

const cx = cxBinder.bind(styles)

export const MoneyWalletCard = ({
  walletData,
  isHorizontal,
}: MoneyWalletCardProps) => {
  const { amount, currency, current_step: currentStep, accounts } = walletData

  const nextOrderDiscount = getNextOrderDiscount(walletData)
  const currencySign = getUserCurrencySign(currency)
  const locale = RegionConfig.getCurrentLocale()
  const currencySignFirst = isEnglishLocale(locale)
  return (
    <>
      <TM type={TType.Header13_500} id={walletI18n.yourBalance} />

      <div
        className={cx({
          isHorizontal,
        })}
      >
        <div className={styles["balance-wrapper"]}>
          <div
            className={styles["balance-details"]}
            e2e-target="balance-details"
          >
            {currencySignFirst ? (
              <>
                <div className={styles["currency-position"]}>
                  <Typography type={TType.Header15_500}>
                    {currencySign}
                  </Typography>
                </div>

                <Typography type={TType.Header40_400}>{amount}</Typography>
              </>
            ) : (
              <>
                <Typography type={TType.Header40_400}>{amount}</Typography>
                <Typography type={TType.Header15_500}>
                  {currencySign}
                </Typography>
              </>
            )}
          </div>
          <div
            className={cx({
              "balance-content": !isHorizontal,
              "balance-content-horizontal": isHorizontal,
            })}
          >
            <SortedAccounts
              currencySignFirst={currencySignFirst}
              accounts={accounts}
              isHorizontal={isHorizontal}
            />

            {!currentStep && <NoCurrentStep isHorizontal={isHorizontal} />}
          </div>
        </div>

        <UserBenefits
          currentStep={currentStep}
          isHorizontal={isHorizontal}
          nextOrderDiscount={nextOrderDiscount}
        />
      </div>
    </>
  )
}
