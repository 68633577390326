var _a, _b;
var assetsPath = "/product-key-visuals/";
var desktopPath = "/product-dimensions/desktop/1280x800_";
var mobilePath = "/product-dimensions/mobile/640x600_";
var customPath = "/product-custom-dimensions/";
import BOX_CONSTS from "../consts/boxConsts";
var sizeAssets = {
    P13: {
        desktop: desktopPath + "P13.png",
        mobile: mobilePath + "P13.png",
    },
    P15: {
        desktop: desktopPath + "P15.png",
        mobile: mobilePath + "P15.png",
    },
    P15i: {
        desktop: desktopPath + "P15I.png",
        mobile: mobilePath + "P15I.png",
    },
    P18: {
        desktop: desktopPath + "P18.png",
        mobile: mobilePath + "P18.png",
    },
    P18i: {
        desktop: desktopPath + "P18I.png",
        mobile: mobilePath + "P18I.png",
    },
    P18ii: {
        desktop: desktopPath + "P18II.png",
        mobile: mobilePath + "P18II.png",
    },
    P20: {
        desktop: desktopPath + "P20.png",
        mobile: mobilePath + "P20.png",
    },
    P21: {
        desktop: desktopPath + "P21.png",
        mobile: mobilePath + "P21.png",
    },
    P21i: {
        desktop: desktopPath + "P21I.png",
        mobile: mobilePath + "P21I.png",
    },
    P24: {
        desktop: desktopPath + "P24.png",
        mobile: mobilePath + "P24.png",
    },
    P24i: {
        desktop: desktopPath + "P24I.png",
        mobile: mobilePath + "P24I.png",
    },
    P24ii: {
        desktop: desktopPath + "P24II.png",
        mobile: mobilePath + "P24II.png",
    },
    P24iii: {
        desktop: desktopPath + "P24III.png",
        mobile: mobilePath + "P24III.png",
    },
    P25: {
        desktop: desktopPath + "P25.png",
        mobile: mobilePath + "P25.png",
    },
    P26: {
        desktop: desktopPath + "P26.png",
        mobile: mobilePath + "P26.png",
    },
    P28: {
        desktop: desktopPath + "P28I.png",
        mobile: mobilePath + "P28I.png",
    },
    P29: {
        desktop: desktopPath + "P29.png",
        mobile: mobilePath + "P29.png",
    },
    P30: {
        desktop: desktopPath + "P30.png",
        mobile: mobilePath + "P30.png",
    },
    P34: {
        desktop: desktopPath + "P34.png",
        mobile: mobilePath + "P34.png",
    },
    P35: {
        desktop: desktopPath + "P35.png",
        mobile: mobilePath + "P35.png",
    },
    P36: {
        desktop: desktopPath + "P36.png",
        mobile: mobilePath + "P36.png",
    },
    P39: {
        desktop: desktopPath + "P39.png",
        mobile: mobilePath + "P39.png",
    },
    P40: {
        desktop: desktopPath + "P40I.png",
        mobile: mobilePath + "P40I.png",
    },
    P43: {
        desktop: desktopPath + "P43.png",
        mobile: mobilePath + "P43.png",
    },
    P45: {
        desktop: desktopPath + "P45.png",
        mobile: mobilePath + "P45.png",
    },
    custom: {
        desktop: customPath + "ProductBox/Desktop/Custom/{locale}.png",
        mobile: customPath + "ProductBox/Mobile/Custom/{locale}.png",
    },
};
export default (_a = {},
    _a[BOX_CONSTS.PRODUCT_BOX] = (_b = {},
        _b[BOX_CONSTS.PRODUCT_BOX_DEFAULT_VARIANT] = {
            keyVisual: [assetsPath + "classic_productbox_desktop.png"],
            sizeAssets: sizeAssets,
        },
        _b),
    _a);
