import EuRegion from "./eu"
import * as shared from "../shared/shared-consts"

const Region = {
  ...EuRegion,

  languageTag: "ro-RO",
  iterableLocale: "ro-RO",
  locale: "ro",
  phoneCountryCode: "+40",
  defaultShippingCountryCode: "RO",
  mainDomain: shared.RO_MAIN_DOMAIN,

  secondaryAnalyticsCode: "UA-71580330-30",
  productRegion: "ro",
  countryCode: "ro",
  emailAddress: "hello@packhelp.com",
  emailSenderName: "Packhelp.com",
  phoneNumber: "",

  logoSVGIcon: "v3-packhelp_color_on_white",
  logoSVGIconWhite: "v3-packhelp_white_logo_on_black",

  additionalCustoms: 0,

  pageTitle: "Editor - Packhelp.ro",
  footerLabel: "Packhelp",
  frontendBoxDesignRoute: "box/design",
  frontendAccountOrdersRoute: "account/orders",

  frontendTermsOfServiceRoute: "/terms_of_service/", // english is on purpose
  frontendPrivacyPolicyRoute: "/privacy_policy/", // english is on purpose

  domain: {
    basename: "packhelp",
    tld: "com",
    pathBased: true,
    pathRegion: "/ro-ro",
  },
  externalLinks: {
    affiliates: "/program-afiliati/",
    suppliers: "/furnizori/",
    fscCertification: "/atestari-fsc/",
    helpPlantTrees: "/ajutati-plantarea-copacilor/",
    ecoProperties: "/proprietati-eco/",
    sustainability: "/sustenabilitate/",
    designShowcase: "/catalog-model/",
    inspirationsHandbook: "/deveniti-o-inspiratie/",
    mobileAppLandingPageUrl: "/aplicatia-mobila/",
    brief: "/pe-scurt/",
    artworkTipsDielines: "/sfaturi-modele-sablon/",
    help: "/ajutor/",
    thankYou: "/multumim/",
    designGuidelines: "/ghid/editor/modele/",
    about: "/despre/",
    career: "/cariere/",
    shop: "/magazin/",
    services: "/services/",
    industries: "/i/",
    bundles: "pachete-ambalaje",
    inspirations: "/inspiratii/",
    impressum: "/impressum/",
    returnPolicy: "/ajutor/returnare/",
    blog: "/blog/",
    contact: "/contact/",
    holidays: "/sarbatori/",
    privacyPolicy: "/privacy_policy/",
    termsOfService: "/terms_of_service/",
    termsOfServicePlus: "/terms-of-services-plus/",
    accountSignOut: "/sign_out/",
    accountSignIn: "/sign_in/",
    accountRegister: "/registration",
    accountOrders: "/account/orders",
    accountOrderDetails: "/account/orders/:orderNumber",
    accountSettings: "/account/settings",
    accountWallet: "/account/packhelp-wallet",
    accountBoxes: "/account/boxes",
    accountSupportReforestation: "/account/support-reforestation",
    accountCart: "/cart/",
    samples: "/sample-packs",
    customOffer: "offer/:token/review",
    quoteRequest: "account/quote_requests/:qrNumber",
    cart: "/cart/",
    orderDetails: "/order",
    crossSell: "/cart/recommended-products-and-services/s",
    fullAndEcoColorHelpSite: "/ajutor/zona/culoare-completa-eco/",
    safeZonesHelpSite: "/ajutor/zona/site/",
    samplePacksLink:
      "/box/design?sku=box-eu--samples--samples--samples--samples--samples&dielineMode=false",
    termsAcceptedSite: "/site-uri-acceptate/",
    paymentSummary: "/order/:orderId/payment/summary",
    packhelpWallet: "/packhelp-wallet",
    ecoBadge: "/sigla-eco-packhelp/",
    leadership: "/lideri-in-domeniu/",
    press: "/presa/",
    urlWhitelistedToBeFetchedFromUrl: [
      "/order/:orderNumber/payment",
      "/order/:orderNumber/details",
    ],
    products: {
      "mailer-box-plain": "/cutii-livrare-simple/",
      "mailer-box-eco": "/cutii-carton-eco/",
      "mailer-box-eco-white": "/cutii-carton-eco/",
      "mailer-box-full-color": "/mailer-box-full-color/",
      "mailer-box-eco-color": "/cutie-de-carton-eco-colorate/",
      "delivery-mailer-box": "/cutii-livrare-comert-electronic/",
      "delivery-mailer-box-plain": "/cutii-livrare-simple-2/",
      "shipping-box-plain": "/cutii-mari-carton/",
      "shipping-box": "/cutii-standard/",
      "product-box": "/cutii-produse-clasice/",
      "product-box-two-pieces": "/cutii-articole-capac/",
      "poly-mailer": "/pungi-curierat-biodegradabile/",
      "paper-mailer": "/plicuri-cu-burduf/",
      "paper-bag": "/pungi-hartie-imprimate/",
      "rigid-envelope": "/plicuri-la-comanda/",
      "product-box-wine-box": "/cutii-vin-personalizate/",
      "tissue-paper": "/hartie-matase-personalizata/",
      "packing-paper": "/hartie-ambalare/",
      "packing-tape": "/benza-adezive-personalizate/",
      "sleeved-mailer-box": "/cutii-cu-manson/",
      "kraft-tape": "/nastri-adesivi-kraft/",
      "bio-poly-mailer": "/pungi-curierat-biodegradabile/",
      "paper-can": "/cutii-rotunde/",
      "mailer-box-white-ink": "/cutii-alb-pe-kraft/",
      "mailer-box-full-color-double-sided": "/cutii-curierat-duo/",
      "rigid-box": "/cutii-rigide/",
      "rigid-box-two-pieces": "/cutii-rigide-cu-capac/",
      "special-box": "/scatola-speciale-su-misura/", // no links in CMS
      "magnetic-box": "/cutii-magnetice/",
      "carrier-box": "/cutii-cu-maner/",
      "rigid-drawer-box": "/cutii-carton-glisante/",
      "cake-box": "/cutii-tort/",
      "bio-poly-mailer-plain": "/pungi-pre-proiectate-bio/",
      "pre-printed-mailer-box-eco": "/cutti-de-curierat-eco-pre-printate/",
      "pre-printed-mailer-box-full-colour": "/cutii-de-curierat-pre-printate/",
      "doypack-bag": "/pungi-stand-up/",
      "square-label": "/etichete-personalizate/",
      "tote-bag": "/sacosa-de-panza/",
      "printed-card": "/bilet-de-multumire/",
      "pre-printed-kraft-tape": "/banda-kraft-preimprimata/",
      "recycled-poly-mailer": "/foliopak-z-recyklingu/", // pp missing?
    },
    categories: {
      "mailer-boxes": "/cutii-curierat/",
      "custom-shipping-boxes": "/cutii-standard-personalizabile/",
      "primary-packaging": "/cutii-produse/",
      "eco-friendly-packaging":
        "/magazin/?eco-proprietati=materiale-reciclate/",
      "luxury-packaging": "/cutii-de-lux/",
    },
    fb_url: shared.EU_MAIN_DOMAIN,
    fb_sitename: "Packhelp",
    fb_image: "/share_image_1200x630.png",
    share_img: "/share_image_120x120.png", // Editor
    boxEditorPath: "/box/design",
    productEditor: "/editor/product/design",
    exampleDesign: "79485?language=ro&templateMode=true",
    productQuotationSystem: "/quote",
    pqsSuccessPage: "/quote/success",
    plus: {
      catalogue: "/plus/catalogue/",
      successPage: "/plus/success",
      items: "/packhelp-plus/items",
      quotes: "/packhelp-plus/quotes",
      orders: "/packhelp-plus/orders",
      quoteShow: "/packhelp-plus/quotes/:token",
      orderShow: "/packhelp-plus/orders/:orderNumber/details",
      orderPlaced: "/packhelp-plus/orders/:orderNumber/placed",
      orderItems: "/packhelp-plus/orders/:orderNumber/items",
      orderPaymentSummary: "/packhelp-plus/orders/:orderNumber/payment-summary",
      personalDetails: "/packhelp-plus/personal-details",
    },
    pro: {
      features: "pro/features/",
      suppliers: "pro/for-suppliers/",
      termsOfService: "pro/terms-conditions/",
    },
    industriesListing: "/i/",
    whiteLabel: "/enterprise/",
  },

  ...shared.SHARED_ECOMMERCE_PROPERTIES.eu,
  ...shared.SHARED_CURRENCY_PROPERTIES.ron,
  allowedPaymentMethods: ["check", "stripe", "card"],

  remindMeOnDesktop: {
    remindme_form_url:
      "//packhelp.us12.list-manage.com/subscribe/post-json?u=2060c1de14ec30d28a9fab813&amp;id=489822501e&c=?",
    remindme_hidden_input_name: "b_2060c1de14ec30d28a9fab813_489822501e",
  },

  datesFormattingRules: shared.DATES_FORMATTING_RULES_EU,

  googleConversionId: "859901857",
  googleAnalytics: {
    primaryCode: "UA-71580330-2",
    secondaryCode: "UA-71580330-30",
    segmentCode: "uXDomf9GW2BCffFseKvLrarxNiGNLI5O",
  },
  googleAdWords: {
    remarketingCode: "39198",
    conversionCode: "859901857",
  },

  facebookAppId: "1660833110841347",
  supportsPlus: false,
}

export default Region
