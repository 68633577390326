import { AfterPurchaseEditStore } from "../../after-purchase-edit-store/after-purchase-edit-store"

export class AfterPurchaseEditController {
  constructor(
    private readonly afterPurchaseEditStore: AfterPurchaseEditStore,
    private readonly designMeta: {
      id?: string | number
      status: string
    }
  ) {}

  public setAfterPurchaseEdit = async (orderState: string) => {
    if (!this.designMeta.id) {
      this.afterPurchaseEditStore.setIsAfterPurchaseEdit(false)
      return
    }

    if (orderState === "complete" && this.designMeta.status === "draft") {
      this.afterPurchaseEditStore.setIsAfterPurchaseEdit(true)
      return
    }

    this.afterPurchaseEditStore.setIsAfterPurchaseEdit(false)
  }
}
