import { AfterPurchaseEditStore } from "../stores/after-purchase-edit-store/after-purchase-edit-store"
import { AfterPurchaseEditController } from "../stores/_controllers/after-purchase-edit/after-purchase-edit-controller"
import { BootstrapClassicRootStoreContainer } from "./root-store-bootstrap-container"

export async function provideAfterPurchaseEditContainer(
  rootBootstrapper: BootstrapClassicRootStoreContainer
): Promise<AfterPurchaseEditContainer> {
  const afterPurchaseEditStore = new AfterPurchaseEditStore()
  const afterPurchaseEditController = new AfterPurchaseEditController(
    afterPurchaseEditStore,
    rootBootstrapper.designMeta
  )

  return {
    afterPurchaseEditStore,
    afterPurchaseEditController,
  }
}

export type AfterPurchaseEditContainer = {
  afterPurchaseEditStore: AfterPurchaseEditStore
  afterPurchaseEditController: AfterPurchaseEditController
}
