import type { TemplatesController } from "../stores/_controllers/templates-controller"
import type { TemplatesStore } from "../stores/templates-store/templates.store"
import type { ApiSessionContainer } from "./api-session-container"
import type { EcommerceMaybeContainer } from "./ecommerce-maybe-container"
import type { DesignAndProductDriverContainer } from "./design-and-product-driver-container"
import { UtilEnvContainer } from "./util-container"

async function importDeps() {
  const { TemplateManager } = await import("../services/template-manager")
  const { TemplatesStore } = await import(
    "../stores/templates-store/templates.store"
  )
  const { TemplatesController } = await import(
    "../stores/_controllers/templates-controller"
  )

  return {
    TemplateManager,
    TemplatesStore,
    TemplatesController,
  }
}

export async function provideTemplatesMaybeContainer(
  utilEnvContainer: UtilEnvContainer,
  designAndProduct: DesignAndProductDriverContainer,
  apiSessionContainer: ApiSessionContainer,
  ecommerceContainer: EcommerceMaybeContainer
): Promise<TemplatesMaybeContainer> {
  const { productDriver } = designAndProduct
  const { productRenderPilot } = productDriver.state
  const { appConfig } = utilEnvContainer

  if (
    !ecommerceContainer.available ||
    !appConfig.api.ecommerce.features.ecommerce.templates ||
    !productRenderPilot.uiConfig.features.templates ||
    productRenderPilot.getEditorMode() !== "editor"
  ) {
    return {
      available: false,
      templatesStore: undefined,
      templatesController: undefined,
    }
  }

  const { TemplateManager, TemplatesStore, TemplatesController } =
    await importDeps()

  const { apiService, directusApiService } = apiSessionContainer
  const { taxStore } = ecommerceContainer
  const { productDesignStore, assetStore } = designAndProduct
  const { ee } = utilEnvContainer

  const templatesManager = new TemplateManager({
    phApi: apiService,
    directusApi: directusApiService,
  })

  const templatesStore = new TemplatesStore(
    templatesManager,
    taxStore,
    appConfig.locale
  )

  const templatesController = new TemplatesController(
    templatesStore,
    productDriver,
    templatesManager,
    assetStore,
    productDesignStore,
    utilEnvContainer,
    ee
  )

  return {
    available: true,
    templatesStore,
    templatesController,
  }
}

export type TemplatesMaybeContainer =
  | {
      available: true
      templatesStore: TemplatesStore
      templatesController: TemplatesController
    }
  | {
      available: false
      templatesStore: undefined
      templatesController: undefined
    }
