export const walletI18n = {
  tiersGetBackOnNextOrder: "wallet.tiers.get-back-on-next-order",
  tiersStayAndGain: "wallet.tiers.stay-and-gain",
  reminderExpiresOn: "wallet.reminder.expires-on",
  orderMoreGetMore: "wallet.order-more-get-more",
  hereIsYourDiscount: "wallet.here-is-your-discount",
  boxEditorMiddleErrorDpiReadMore: "box-editor.middle.error.dpi-read-more",
  remindMe: "wallet.remind-me",
  genericSaveAndContinue: "generic.save-and-continue",
  genericTitle: "wallet.generic.title",
  yourBalance: "wallet.new.your-balance",
  reminderSpendItBefore: "wallet.reminder.spend-it-before",
  yourBenefits: "wallet.new.your-benefits",
  newTooltipCashback: "wallet.new.tooltip.cashback-new",
  tooltipCashback: "wallet.new.tooltip.cashback",
  newLevel: "wallet.new.level",
  newTooltipConcern: "wallet.new.tooltip.concern",
  newTooltipDiscoverButton: "wallet.new.tooltip.discover-button",
  newTooltipEditorTitle: "wallet.new.tooltip.editor.title",
  newTooltipEditorDescription: "wallet.new.tooltip.editor.description",
  priceRibbonShipmentFreeNew: "box-configurator.price-ribbon.shipment-free-new",
  walletLevelsCashback: "wallet.levels.cashback",
  walletLevelsFreeDelivery: "wallet.levels.free-delivery",
  newTooltipCongratulationsAlternative:
    "wallet.new.tooltip.congratulations-alternative",
  newFirstOrderCashback: "wallet.new.new-first-order-cashback",
  makeOrderAndUnlock: "wallet.new.make-order-and-unlock",
  waitingForYourPaymentToCalculate:
    "wallet.new.waiting-for-your-payment-to-calculate",
  loyaltyProgram: "wallet.loyalty-program",
  expires: "wallet.expires",
  limitInfo: "wallet.limit-info",
  valuePropositionSignInAndSaveWithPackhelpWallet:
    "wallet.value-proposition.sign-in-and-start-saving-with-packhelp-wallet",
  valuePropositionWhatUGet: "wallet.value-proposition.what-u-get",
  valuePropositionOne: "wallet.value-proposition.value-one",
  valuePropositionTwo: "wallet.value-proposition.value-two",
  valuePropositionThree: "wallet.value-proposition.value-three",
  valuePropositionSignInAndSave:
    "wallet.value-proposition.sign-in-and-start-saving",
  newToPackhelp: "generic.new-to-packhelp",
  createAnAccount: "generic.create-an-account",
}

export const walletMinimalStepDiscount = "5%"
