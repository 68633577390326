import React, { useState, useCallback } from "react"
import {
  useDropzone,
  DropzoneRootProps,
  DropzoneInputProps,
} from "react-dropzone"

import Typography, { TType } from "dsl/src/atoms/Typography/Typography"
import Link, { LinkAsButton } from "dsl/src/atoms/Link/Link"
import { ReactComponent as UploadIcon } from "../../../assets/svg/Upload2.svg"
import { ReactComponent as SuccessIcon } from "../../../assets/svg/success.svg"
import { ReactComponent as ErrorIcon } from "../../../assets/svg/error2.svg"
import { ReactComponent as GlassIcon } from "../../../assets/svg/glass.svg"
import { Button } from "dsl/src/atoms/Button"
import Loader, { LoaderSizes, LoaderTypes } from "dsl/src/atoms/Loader/Loader"
import { DbyUploadModal } from "../../../dsl/organisms/Modal/dby-upload-modal/DbyUploadModal"
import { useTranslate } from "shared-libs/src/js/shared-components/i18n/useTranslate"
import { FormattedHTMLMessage } from "react-intl"
import { UploadDropZone } from "../../../dsl/organisms/Upload/UploadDropzone"
import { observer } from "mobx-react-lite"
import {
  getTranslationKeyForAssetError,
  AssetError,
} from "../../../../services/asset-service/asset-errors"
import { DbyModeContainer } from "../../../../_containers/dby-mode-container"
import { I18N } from "../../../i18n"
import { EditorModeController } from "../../../../stores/_controllers/editor-mode.controller"
import cxBinder from "classnames/bind"
import { parseFileNaming } from "../../../../services/asset-service/utils"
import { DbyModeStore } from "../../../../stores/dby-mode-store/dby-mode-store"
import { ProductRenderPilot } from "../../../../libs/products-render-config/product-render-pilot"

import styles from "./DbyUploader.module.scss"

const cx = cxBinder.bind(styles)

interface UploadStateProps {
  onDesignOnline: () => void
  toggleDownloadDielinesModal: () => void
  getInputProps: (props?: DropzoneInputProps | undefined) => DropzoneInputProps
  getRootProps: (props?: DropzoneRootProps | undefined) => DropzoneRootProps
  isProductDbyOnly: boolean
}

const UploadState = observer(
  ({
    toggleDownloadDielinesModal,
    onDesignOnline,
    getInputProps,
    getRootProps,
    isProductDbyOnly,
  }: UploadStateProps) => {
    const t = useTranslate()

    return (
      <div className={styles.upload_state}>
        <UploadIcon className={styles.upload_state__icon} />
        <Typography
          type={TType.Body17_350}
          className={styles.upload_state__instructions}
        >
          {t(I18N.component.dbyUploader.dropHere)}
        </Typography>
        <UploadDropZone inputProps={getInputProps()} rootProps={getRootProps()}>
          <div className={styles.upload_state__button}>
            <Button onClick={() => null} fluid borderRadius="4px">
              {t(I18N.component.dbyUploader.chooseFile)}
            </Button>
          </div>
        </UploadDropZone>

        <hr className={styles.upload_state__divider} />

        <div className={styles.upload_state__footer}>
          <Typography type={TType.Body13_350}>
            {t(I18N.component.dbyUploader.notReady1)}
          </Typography>
          {!isProductDbyOnly && (
            <Typography type={TType.Body13_350}>
              {t(I18N.component.dbyUploader.notReady2)}
            </Typography>
          )}
          <Typography type={TType.Body13_350}>
            <LinkAsButton onClick={toggleDownloadDielinesModal}>
              <Typography type={TType.Body13_350}>
                {t(I18N.component.dbyUploader.downloadDielines)}
              </Typography>
            </LinkAsButton>
            {!isProductDbyOnly && (
              <>
                &nbsp;{t(I18N.component.dbyUploader.or)}&nbsp;
                <LinkAsButton onClick={onDesignOnline}>
                  <Typography type={TType.Body13_350}>
                    {t(I18N.component.dbyUploader.designOnline)}
                  </Typography>
                  .
                </LinkAsButton>
              </>
            )}
          </Typography>
        </div>
      </div>
    )
  }
)

interface LoadingStateProps {
  filename: string
}

const LoadingState = ({ filename }: LoadingStateProps) => {
  const t = useTranslate()
  return (
    <div className={styles.loading_state}>
      <UploadIcon className={styles.loading_state__icon} />
      <Typography
        type={TType.Body17_350}
        className={styles.loading_state__instructions}
      >
        {t(I18N.component.dbyUploader.uploading)}
      </Typography>
      <div className={styles.loading_state__loader}>
        <Loader type={LoaderTypes.circular} size={LoaderSizes.medium} />
      </div>
      <div className={styles.loading_state__footer}>
        <Typography type={TType.Body13_350}>{filename}</Typography>
      </div>
    </div>
  )
}

interface ErrorStateProps {
  translateadErrorMessage: string
  filename: string
  getInputProps: (props?: DropzoneInputProps | undefined) => DropzoneInputProps
  getRootProps: (props?: DropzoneRootProps | undefined) => DropzoneRootProps
  dielineUrl?: string
}

const ErrorState = ({
  translateadErrorMessage,
  filename,
  getInputProps,
  getRootProps,
  dielineUrl,
}: ErrorStateProps) => {
  const t = useTranslate()
  return (
    <div className={styles.error_state}>
      <ErrorIcon className={styles.error_state__icon} />
      <Typography
        type={TType.Body15_350}
        className={styles.error_state__message}
      >
        <FormattedHTMLMessage
          id={I18N.component.dbyUploader.errorMessage}
          values={{ filename: filename }}
        />
        <div>{translateadErrorMessage}</div>
      </Typography>
      <UploadDropZone inputProps={getInputProps()} rootProps={getRootProps()}>
        <Button onClick={() => null} fluid borderRadius="4px">
          {t(I18N.component.dbyUploader.chooseDifferent)}
        </Button>
      </UploadDropZone>

      <div className={styles.error_state__requirements}>
        <Typography type={TType.Body13_350}>
          {t(I18N.component.dbyUploader.formatGuidelines)}
        </Typography>
      </div>
      <hr className={styles.error_state__divider} />
      <div className={styles.error_state__guidelines}>
        <Typography type={TType.Body13_350}>
          {t(I18N.component.dbyUploader.needHelp)}
        </Typography>
        {dielineUrl && (
          <Link href={dielineUrl}>
            <Typography type={TType.Body13_350}>
              {t(I18N.component.dbyUploader.downloadGuidelines)}
            </Typography>
          </Link>
        )}
      </div>
    </div>
  )
}

interface UploadedStateProps {
  isEditMode: boolean
  dbyModeStore: DbyModeStore
}

const UploadedState = ({ isEditMode, dbyModeStore }: UploadedStateProps) => {
  const t = useTranslate()
  const [isPreviewModalOpened, setIsPreviewModalOpened] = useState(false)

  const filename = dbyModeStore.shortenedUploadedFilename
  const imageUrl = dbyModeStore.uploadedFilePreviewUrl

  return (
    <div className={styles.uploaded_state}>
      <SuccessIcon className={styles.uploaded_state__icon} />
      <Typography
        type={TType.Body17_350}
        className={styles.uploaded_state__instructions}
      >
        {t(I18N.component.dbyUploader.uploaded)}
      </Typography>
      <div className={styles.uploaded_state__preview}>
        <div
          className={styles.uploaded_state__image}
          style={{ backgroundImage: `url("${imageUrl}")` }}
        />
        <div
          className={styles.uploaded_state__curtain}
          onClick={() => setIsPreviewModalOpened(true)}
        >
          <GlassIcon />
        </div>
      </div>
      <div className={styles.uploaded_state__footer}>
        <Typography
          type={TType.Body13_350}
          className={styles.uploaded_state__filename}
        >
          {filename}
        </Typography>
        {isEditMode && (
          <div
            className={styles.uploaded_state__dropzone}
            onClick={() => {
              dbyModeStore.removeUploadedFile()
            }}
          >
            <LinkAsButton onClick={() => null}>
              <Typography type={TType.Body13_350}>
                {t(I18N.component.dbyUploader.delete)}
              </Typography>
            </LinkAsButton>
          </div>
        )}
      </div>
      {isPreviewModalOpened && (
        <DbyUploadModal
          onClose={() => setIsPreviewModalOpened(false)}
          imageUrl={imageUrl}
        />
      )}
    </div>
  )
}

interface DbyUploaderProps {
  dbyMode: DbyModeContainer
  editorModeController: EditorModeController
  isEditMode: boolean
  isProductDbyOnly: boolean
  productRenderPilot: ProductRenderPilot
}

export const DbyUploader = observer(
  ({
    dbyMode,
    editorModeController,
    isEditMode,
    isProductDbyOnly,
    productRenderPilot,
  }: DbyUploaderProps) => {
    const t = useTranslate()
    const { dbyModeStore, dbyModeController } = dbyMode

    const onDrop = useCallback(
      (acceptedFiles: File[]) => {
        const droppedFile = acceptedFiles[0]
        if (!droppedFile) return

        const fileExtension = droppedFile.name.split(".").pop()

        if (fileExtension !== "ai" && fileExtension !== "pdf") {
          dbyModeStore.setUploadingFilename(
            parseFileNaming(droppedFile).fileName
          )
          dbyModeStore.setUploadError(AssetError.invalidData)
          return
        }

        dbyModeController.uploadFile(droppedFile)
      },
      [dbyModeController]
    )

    const { getRootProps, getInputProps } = useDropzone({
      onDrop: onDrop,
      noClick: false,
      noDrag: false,
      multiple: false,
    })

    const renderState = () => {
      if (dbyModeStore.isUploading) {
        return <LoadingState filename={dbyModeStore.uploadingFilename!} />
      }

      if (dbyModeStore.uploadError) {
        const translateadErrorMessage = t(
          getTranslationKeyForAssetError(dbyModeStore.uploadError, "dby")
        )
        const dielineUrl = productRenderPilot.getPredefinedDbyDielineUrl("pdf")

        return (
          <ErrorState
            translateadErrorMessage={translateadErrorMessage}
            filename={dbyModeStore.shortenedUploadingFilename}
            dielineUrl={dielineUrl}
            getInputProps={getInputProps}
            getRootProps={getRootProps}
          />
        )
      }

      if (dbyModeStore.uploadedFile) {
        return (
          <UploadedState dbyModeStore={dbyModeStore} isEditMode={isEditMode} />
        )
      }

      return (
        <UploadState
          toggleDownloadDielinesModal={() => {
            dbyModeStore.toggleDownloadDielinesModal()
          }}
          onDesignOnline={() => {
            editorModeController.switchToEditorMode()
          }}
          getInputProps={getInputProps}
          getRootProps={getRootProps}
          isProductDbyOnly={isProductDbyOnly}
        />
      )
    }

    return (
      <div
        className={cx("dby_uploader", {
          "dby_uploader--edit-mode": isEditMode,
        })}
        e2e-target="dby-uploader-container"
      >
        <div className={styles.dby_uploader__body}>{renderState()}</div>
      </div>
    )
  }
)
