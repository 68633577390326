import React from "react"
import { AvailableSize, Product, VariantCustomization } from "@ph/product-api"
import { SizeOption } from "./SizeOption"
import { CustomSizeOption } from "./CustomSizeOption"
import _remove from "lodash/remove"
import styles from "./SizeOptionsList.module.scss"

interface SizeOptionsListProps {
  availableSizes: AvailableSize[]
  onSelectOption: (sku: string, customization?: VariantCustomization) => void
  isDisabled: boolean
  product: Product
  isCustomSizeAvailable: boolean
}

export const SizeOptionsList = ({
  availableSizes,
  onSelectOption,
  isDisabled,
  product,
  isCustomSizeAvailable,
}: SizeOptionsListProps) => {
  const customSize = _remove(availableSizes, (size) => size.isCustom)[0]
  const selectedSku = product.variantManager.getSku()

  return (
    <ul className={styles.size_options_list}>
      {isCustomSizeAvailable && (
        <CustomSizeOption
          product={product}
          isDisabled={isDisabled}
          onSelect={(size) => {
            //TODO: custom properties in separate PR
            onSelectOption(selectedSku, { size })
          }}
        />
      )}

      {availableSizes.map((size, index) => (
        <SizeOption
          key={`size-option-${index}`}
          outsideDimensions={size.productVariantDimensions}
          onSelect={() => onSelectOption(size.sku)} //TODO: custom properties in separate PR
          isSelected={!customSize && selectedSku === size.sku}
          isDisabled={isDisabled}
        />
      ))}
    </ul>
  )
}
