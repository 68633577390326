import _first from "lodash/first"
import _findIndex from "lodash/findIndex"
import _sortBy from "lodash/sortBy"
import _find from "lodash/find"
import _isString from "lodash/isString"
import _isEqual from "lodash/isEqual"
import React, { Component, FormEvent } from "react"
import { formatDeal, isNormalInteger } from "./helpers"
import SelectBase from "../../atoms/Select/SelectBase"
import Item, { SelectedItemBehaviour } from "./partials/Item"
import Search from "./partials/Search"
import {
  SelectSizes,
  SelectItemData,
  SelectCommonProps
} from "../../atoms/Select/types"
import { BadgeColorTypes } from "../../atoms/Badge/Badge"
import SelectQtyFooter from "./partials/SelectQtyFooter"
import scrollIntoView from "scroll-into-view-if-needed"
import SelectQtyHeader from "./partials/SelectQtyHeader"

const ITEMS_LIST_WRAPPER_ID = "itemsListMenuWrapper"

type ItemRenderer = (item: SelectItemData) => React.ReactNode
type TagRenderer = (item: PriceBreak) => React.ReactNode

export interface PriceBreak {
  qty: number | string
  deal: number
  price: number
  priceTotalAdjuster?: number
  oldPrice?: number
  isCustomValue?: boolean
}

interface SelectQtyProps extends SelectCommonProps {
  selectedItemBehaviour?: SelectedItemBehaviour
  itemRenderProp?: ItemRenderer
  tagRenderProp?: TagRenderer
  specialTagRenderProp?: TagRenderer
  itemFooterRenderProp?: TagRenderer
  priceBreaks: PriceBreak[]
  step: number
  min: number
  max: number
  maxForStandard?: number
  minForCustom?: number
  selectedQty?: number
  handleQtyChange?: any
  handleInputValueChange?: any
  selectOptions?: any
  outOfTheBoxText?: string
  outOfTheBoxLinkUrl?: string
  outOfTheBoxLinkText?: string
  quoteRequestTag?: string
  getQuoteRequestMaxValueFormatted?: (value: string) => string
  selectSize?: boolean | SelectSizes
  disabled?: boolean
  sku?: string
  handleCloseSelect?: () => void
  sendQuantityChangedToAnalytics?: (number: number, sku: string) => void
  searchbarAttributes?: HTMLInputElement
  short?: boolean
  buttonContent?: React.ReactNode
  placeholder?: string
  searchDisabled?: boolean
  hidePricingDetails?: boolean
  autoWidth?: boolean
  direction?: string
  showBlueHeaderLabel?: boolean
  blueHeaderLabelText?: string
  noBackground?: boolean
}

interface IState {
  inputValue: any
  items: SelectItemData[]
  lastChangeType: string
  selectedItem: SelectItemData | undefined | null
  inputValueBasedItem: SelectItemData | null
  inputValueBasedItemIndex: number
  openTriggered: boolean
}

const withDefaults = (
  defaults: any,
  eventName: string,
  event: KeyboardEvent | FormEvent | MouseEvent
) => {
  if (defaults[eventName]) {
    defaults[eventName](event)
  }
}

class SelectQty extends Component<SelectQtyProps> {
  state: IState

  private withStandardValuesOnly: boolean

  constructor(props: SelectQtyProps) {
    super(props)
    const inputValue = props.selectedQty
    this.state = {
      inputValue,
      lastChangeType: "",
      items: [],
      selectedItem: null,
      inputValueBasedItem: null,
      inputValueBasedItemIndex: -1,
      openTriggered: false
    }
    this.withStandardValuesOnly = this.props.max === this.props.maxForStandard
    this.state.items = this.getItemsData(props, inputValue).items
    this.state.selectedItem = this.getSelectedItemForQty(inputValue)
    this.handleInputValueChange = this.handleInputValueChange.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps: SelectQtyProps) {
    const { selectedQty: nextQty, sku: nextSku } = nextProps
    const { selectedQty: currentQty, sku: currentSku } = this.props
    if (nextQty !== currentQty || nextSku !== currentSku) {
      const { items } = this.getItemsData(nextProps, nextProps.selectedQty)
      const selectedItem = items.filter((item) => {
        return (
          item.data.qty ==
          this.getNearestForValue(nextProps, nextProps.selectedQty)
        )
      })
      this.setState({
        inputValue: nextProps.selectedQty,
        items,
        selectedItem: selectedItem.length ? selectedItem[0] : null
      })
    } else {
      const { items, inputValueBasedItem } = this.getItemsData(
        nextProps,
        nextProps.selectedQty
      )

      this.setState({
        items: items
      })

      if (!_isEqual(this.state.selectedItem, inputValueBasedItem)) {
        this.setState({
          selectedItem: inputValueBasedItem
        })
      }
    }
  }

  UNSAFE_componentWillUpdate(nextProps: SelectQtyProps, nextState: IState) {
    if (
      nextState.selectedItem &&
      this.state.selectedItem &&
      this.state.selectedItem.id != nextState.selectedItem.id &&
      this.props.sku &&
      this.props.sendQuantityChangedToAnalytics
    ) {
      this.props.sendQuantityChangedToAnalytics(
        nextState.selectedItem.id,
        this.props.sku
      )
    }
  }

  getSelectedItemForQty(qty: any) {
    const { items } = this.state
    if (qty === undefined || qty === "" || !items.length) {
      return null
    }
    return _find(items, (item) => item.data.qty === qty)
  }

  getNearestForValue(props: SelectQtyProps, val: any) {
    return Math.round(val / props.step) * props.step
  }

  getItem = (
    priceBreak: PriceBreak,
    tagRenderer?: TagRenderer,
    specialTagRenderer?: TagRenderer,
    nearest?: number,
    itemFooterRender?: TagRenderer
  ): SelectItemData => {
    let tag = tagRenderer
      ? tagRenderer(priceBreak)
      : formatDeal(priceBreak.deal)

    let specialTag = specialTagRenderer ? specialTagRenderer(priceBreak) : null

    let itemFooter = itemFooterRender ? itemFooterRender(priceBreak) : null

    if (this.withStandardValuesOnly) {
      return {
        id: nearest || priceBreak.qty,
        name: `${priceBreak.qty}`,
        tag: tag,
        specialTag: specialTag,
        data: priceBreak,
        itemFooter: itemFooter
      }
    }

    const {
      quoteRequestTag,
      getQuoteRequestMaxValueFormatted,
      maxForStandard
    } = this.props

    const isQuoteRequestMaxValue = priceBreak.qty === this.props.max
    const isAboveStandardValues =
      nearest && maxForStandard ? nearest > maxForStandard : false
    const isQuoteRequestValue =
      priceBreak.isCustomValue || isAboveStandardValues

    const name =
      isQuoteRequestMaxValue && getQuoteRequestMaxValueFormatted
        ? getQuoteRequestMaxValueFormatted(priceBreak.qty.toLocaleString())
        : `${priceBreak.qty}`

    if (isQuoteRequestValue && quoteRequestTag) {
      tag = quoteRequestTag
    }

    const data = isQuoteRequestValue
      ? { qty: priceBreak.qty, isCustomValue: true }
      : priceBreak

    return {
      id: nearest || priceBreak.qty,
      name,
      tag,
      tagColor: isQuoteRequestValue ? BadgeColorTypes.Jagger : undefined,
      specialTag,
      data,
      itemFooter
    }
  }

  /**
   * Because of `inputValue` being controlled prop, and can be manipulated by external state or internal,
   * by props and by our state, both `props` and current `inputValue` need to be passed
   * @param props
   * @param inputValue
   */
  getItemsData(
    props: SelectQtyProps,
    inputValue: any = this.state.inputValue
  ): {
    items: SelectItemData[]
    inputValueBasedItem: SelectItemData
  } {
    /**
     * 1. Get all possible price breaks
     * 2. Find nearest possible item by step for input value
     * 3. Put it in the right position
     */

    // 1
    const items: SelectItemData[] = props.priceBreaks.map((priceBreak) =>
      this.getItem(
        priceBreak,
        props.tagRenderProp,
        props.specialTagRenderProp,
        undefined,
        props.itemFooterRenderProp
      )
    )

    // 2
    const parsedValue = parseInt(inputValue, 10)
    const safeValue = isNaN(parsedValue) ? 0 : parsedValue

    let nearest = this.getNearestForValue(props, safeValue)
    if (nearest < props.min) {
      nearest = props.min
    }
    if (nearest > props.max) {
      nearest = props.max
    }
    if (
      props.maxForStandard &&
      props.minForCustom &&
      nearest > props.maxForStandard &&
      nearest < props.minForCustom
    ) {
      nearest = props.minForCustom
    }

    const priceBreak = this.getPriceBreakForValue(props, nearest)
    priceBreak.qty = nearest

    const inputValueBasedItem = this.getItem(
      priceBreak,
      props.tagRenderProp,
      undefined,
      nearest
    )

    // 3
    if (_findIndex(items, { id: inputValueBasedItem.id }) === -1) {
      items.push(inputValueBasedItem)
      return { items: _sortBy(items, ["id"]), inputValueBasedItem }
    }
    return { items, inputValueBasedItem }
  }

  getPriceBreakForValue(props: SelectQtyProps, qty: any): PriceBreak {
    for (let i = props.priceBreaks.length - 1; i >= 0; --i) {
      if (props.priceBreaks[i].qty <= qty) {
        return Object.assign({}, props.priceBreaks[i])
      }
    }
    return Object.assign({}, props.priceBreaks[0])
  }

  scrollToInputValueBasedItem = () => {
    const { items, inputValueBasedItemIndex } = this.state
    const menuWrap = document.querySelector(`#${ITEMS_LIST_WRAPPER_ID} ul`)

    if (menuWrap) {
      const menuHeight = menuWrap.getBoundingClientRect().height
      const singleItemHeight = menuHeight / items.length
      const scrollOffset = singleItemHeight * inputValueBasedItemIndex

      scrollIntoView(menuWrap, {
        scrollMode: "if-needed",
        behavior: (actions) =>
          actions.forEach(({ el }) => {
            el.scrollTop =
              scrollOffset === 0
                ? scrollOffset
                : scrollOffset - singleItemHeight
          })
      })
    }
  }

  handleInputValueChange(inputValue: any) {
    if (inputValue === "" || (isNormalInteger(inputValue) && inputValue > 0)) {
      const { items, inputValueBasedItem } = this.getItemsData(
        this.props,
        inputValue
      )
      const inputValueBasedItemIndex = _findIndex(items, {
        id: inputValueBasedItem.id
      })

      this.setState(
        {
          inputValue,
          items,
          inputValueBasedItem,
          inputValueBasedItemIndex
        },
        this.scrollToInputValueBasedItem
      )

      this.props.handleInputValueChange &&
        this.props.handleInputValueChange(
          inputValueBasedItem.data.qty,
          inputValueBasedItem.data.price
        )
    }
  }

  get isValid(): boolean {
    return (
      this.state.inputValue >= this.props.min &&
      this.state.inputValue <= this.props.max
    )
  }

  selectItem = (selectedItem: SelectItemData) => {
    const itemToSelect = { ...selectedItem }

    if (!_isString(itemToSelect.name) && itemToSelect.data) {
      itemToSelect.name = `${itemToSelect.data.qty}`
    }

    this.setState({ selectedItem: itemToSelect })
    this.props.handleQtyChange &&
      this.props.handleQtyChange(selectedItem.data.qty)
  }

  onSearchKeyPress = (event: KeyboardEvent, searchRef: any) => {
    const { handleCloseSelect } = this.props
    const { items, inputValueBasedItem } = this.state

    if (items.length && inputValueBasedItem && event.key === "Enter") {
      handleCloseSelect && handleCloseSelect()
      searchRef.current.blur()
    }
  }

  onSearchSubmit = () => {
    const { items, inputValueBasedItem } = this.state

    items.length && inputValueBasedItem && this.selectItem(inputValueBasedItem)
  }

  private renderFooter = () => {
    const {
      outOfTheBoxText = "Need something out of the box? Custom size, different material or bigger order?",
      outOfTheBoxLinkText = "Contact us",
      outOfTheBoxLinkUrl = "#"
    } = this.props

    const showFooter =
      this.withStandardValuesOnly && this.state.inputValue > this.props.max

    return (
      showFooter && (
        <SelectQtyFooter
          {...{
            outOfTheBoxText,
            outOfTheBoxLinkText,
            outOfTheBoxLinkUrl
          }}
        />
      )
    )
  }

  private renderHeader = () => {
    const { blueHeaderLabelText = "Prices include VAT", showBlueHeaderLabel } =
      this.props

    return (
      showBlueHeaderLabel && (
        <SelectQtyHeader
          {...{
            text: blueHeaderLabelText
          }}
        />
      )
    )
  }

  render() {
    const {
      selectOptions,
      selectedItemBehaviour,
      itemRenderProp,
      selectSize,
      disabled,
      handleIsOpenChange,
      searchbarAttributes,
      short,
      placeholder,
      buttonContent,
      searchDisabled,
      hidePricingDetails,
      autoWidth,
      direction,
      noBackground
    } = this.props

    return (
      <SelectBase
        e2eTarget="quantity-select"
        disabled={disabled}
        items={this.state.items}
        size={selectSize ? selectSize : SelectSizes.small}
        searchPlaceholder=""
        searchSelect={true}
        autocomplete
        autoWidth={autoWidth}
        disableNoResultsIndicator
        selectedItem={this.state.selectedItem}
        handleSelectedItemChange={this.selectItem}
        matchSorter={(items) => items}
        inputValue={this.state.inputValue}
        handleInputValueChange={this.handleInputValueChange}
        inputValueBasedItemIndex={this.state.inputValueBasedItemIndex}
        itemsListWrapperId={ITEMS_LIST_WRAPPER_ID}
        direction={direction}
        noBackground={noBackground}
        renderItem={(item: any, isSelected?: boolean) => (
          <Item
            item={item}
            itemRenderProp={itemRenderProp}
            isSelected={isSelected}
            selectedItemBehaviour={selectedItemBehaviour}
            hidePricingDetails={hidePricingDetails}
          />
        )}
        renderSearch={
          !searchDisabled &&
          ((
            searchRef,
            disabled,
            getInputProps,
            toggleMenu,
            onClick,
            isOpen
          ) => {
            const { selectedItem } = this.state
            const inputProps = getInputProps()
            const qtyInputAttrs = {
              type: "tel",
              pattern: "[0-9]*"
            }

            const extendedInputProps = {
              ...inputProps,
              ...qtyInputAttrs,
              ...searchbarAttributes,
              onKeyPress: (event: KeyboardEvent) => {
                withDefaults(inputProps, "onKeyPress", event)
                this.onSearchKeyPress(event, searchRef)
              },
              onFocus: (event: FormEvent) => {
                this.setState({ inputValue: "" })
                withDefaults(inputProps, "onFocus", event)
                toggleMenu && toggleMenu()
              },
              onBlur: (event: FormEvent) => {
                withDefaults(inputProps, "onBlur", event)
                this.onSearchSubmit()
              }
            }

            // works for mobile "go" key press submissions
            const onSubmit = (event: FormEvent) => {
              event.preventDefault()
              withDefaults(inputProps, "onSubmit", event)
              onClick && onClick()
              toggleMenu && toggleMenu()

              this.onSearchSubmit()
              return false
            }

            return (
              /**
               * the form here is a hackey hack for proper handling
               *  the "go" button on mobile devices
               */
              <form onSubmit={onSubmit}>
                <Search
                  disabled={disabled}
                  searchRef={searchRef}
                  isValid={this.isValid}
                  inputProps={extendedInputProps}
                  item={_first(this.state.items)}
                  itemRenderProp={itemRenderProp}
                  menuOpen={isOpen}
                  selectedItem={selectedItem}
                  onOutsideClick={onClick}
                  short={short}
                  buttonContent={buttonContent}
                  placeholder={placeholder}
                  hidePricingDetails={hidePricingDetails}
                />
              </form>
            )
          })
        }
        renderMenuHeader={this.renderHeader}
        renderMenuFooter={this.renderFooter}
        handleIsOpenChange={handleIsOpenChange}
        {...selectOptions}
      />
    )
  }
}

export { SelectQty, SelectQty as default }
