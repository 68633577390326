import type { Design } from "../../../modules/design/types"
import type { VirtualDielinesData } from "../../../modules/ph-api/asset-types"
import { ProductStore } from "../../../stores/product-driver/product.store"
import type {
  DesignPayload,
  DesignDataDto,
  DesignItemPayload,
} from "@ph/design-api"

export class ProductDesignStateToPayloadTransformer {
  constructor(
    private readonly productStore: ProductStore,
    private readonly design: Design
  ) {}

  public transform(): DesignPayload {
    const { data, meta } = this.design

    return {
      variant_sku: this.productStore.productSku,
      name: meta.name,
      prechosen_quantity: meta.quantity,
      public: meta.public,
      thumbnail: this.transformBase64(data.thumbnail, "")!,
      design_data: JSON.stringify(this.transformDesignData()),
      design_items: this.transformDesignItems(),
    }
  }

  private transformDesignData(): DesignDataDto {
    const { data } = this.design

    if (data.type === "editor") {
      return {
        files: data.files,
        virtual_dielines: this.transformVirtualDielines(data.virtualDielines),
        design_data_format_version: data.designDataFormatVersion,
        type: data.type,
      }
    }

    if (data.type === "dby") {
      return {
        files: data.files,
        type: data.type,
      }
    }

    throw new Error("Design type is not supported")
  }

  private transformBase64(
    base64: string,
    fallback?: string
  ): string | undefined {
    /**
     * Sometimes Safari generates incorrect base64 which causes problems with saving.
     *
     * It's a quick fix as we need to find the root of the problem.
     */
    if (base64 === "data:,") {
      window.Sentry?.captureException(new Error("Base64 is incorrect (data:,)"))

      return fallback
    }

    return base64
  }

  private transformVirtualDielines(
    virtualDielinesData: VirtualDielinesData
  ): VirtualDielinesData {
    for (const virtualDieline of Object.values(virtualDielinesData)) {
      if (virtualDieline?.preview) {
        virtualDieline.preview = this.transformBase64(virtualDieline.preview)
      }
    }

    return virtualDielinesData
  }

  private transformDesignItems(): DesignItemPayload[] {
    return this.design.meta.designItems.map((designItem) => {
      return {
        variant_id: designItem.variantId,
        quantity: 1,
      }
    })
  }
}
