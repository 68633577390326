var _a, _b, _c;
import BOX_CONSTS from "../consts/boxConsts";
import productBoxesAssets from "./productBoxesAssets";
import mailerBoxesAssets from "./mailerBoxesAssets";
import productBoxesTwoPiecesAssets from "./productBoxesTwoPiecesAssets";
import productWineBoxesAssets from "./productWineBoxesAssets";
import shippingBoxesAssets from "./shippingBoxesAssets";
import { MailerBoxVariant, WhiteInkMailerBoxVariant, } from "../consts/ProductVariant";
var imageNaturalCardboard = "/product-material-picker/natural-cardboard.png";
var imageWhiteCardboard = "/product-material-picker/white-cardboard.png";
var EditorImage;
(function (EditorImage) {
    EditorImage["KRAFT_CARDBOARD"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/materials/material-cardboard-kraft.png";
    EditorImage["WHITE_CARDBOARD"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/materials/material-cardboard-white.png";
    EditorImage["KRAFT_INSIDE"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/materials/box-material-kraft-inside.png";
    EditorImage["WHITE_INSIDE"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/materials/box-material-white-inside.png";
    EditorImage["GLOSSY_FOIL"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/finishes/product-finish-foilGlossy-box-model.png";
    EditorImage["MATT_FOIL"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/finishes/product-finish-foilMatt-box-model.png";
    EditorImage["DOUBLE_STRIP"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/finishes/finish-double-strip.png";
    EditorImage["SINGLE_STRIP"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/finishes/finish-single-strip.png";
    EditorImage["SLEEVE_KRAFT"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/materials/material-tape-kraft.png";
    EditorImage["SLEEVE_WHITE"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/materials/material-tape-white.png";
    EditorImage["ECO"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/variants/mailer-box-eco.png";
    EditorImage["ECO_WHITE"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/variants/mailer-box-eco-white.png";
    EditorImage["ECO_COLOR"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/variants/mailer-box-eco-color.png";
    EditorImage["FULL_COLOR"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/variants/mailer-box-full-color.png";
    EditorImage["WHITE_ON_KRAFT"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/variants/mailer-box-white-on-kraft.png";
    EditorImage["LITE_COLOR"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/variants/mailer-box-lite-color.png";
})(EditorImage || (EditorImage = {}));
var addonImagePaths = (_a = {},
    _a[BOX_CONSTS.PRINT_MATERIAL_NATURAL_CARDBOARD] = imageNaturalCardboard,
    _a[BOX_CONSTS.PRINT_MATERIAL_WHITE_ONE_SIDE_CARDBOARD] = imageWhiteCardboard,
    _a[BOX_CONSTS.PRINT_MATERIAL_WHITE_CARDBOARD] = imageWhiteCardboard,
    _a[BOX_CONSTS.PRINT_MATERIAL_COATED_CARDBOARD] = imageWhiteCardboard,
    _a[BOX_CONSTS.PRINT_MATERIAL_CARDSTOCK] = imageWhiteCardboard,
    _a[BOX_CONSTS.PRINT_MATERIAL_KRAFT] = imageNaturalCardboard,
    _a[BOX_CONSTS.PRINT_FINISH_SLEEVE_WHITE] = imageWhiteCardboard,
    _a[BOX_CONSTS.PRINT_FINISH_SLEEVE_KRAFT] = imageNaturalCardboard,
    _a);
var addonsSvgIcons = (_b = {},
    _b[BOX_CONSTS.PRINT_FINISH_FOIL_GLOSSY] = "v4-addon-glossy-foil",
    _b[BOX_CONSTS.PRINT_FINISH_FOIL_MATTE] = "v4-addon-matt-foil",
    _b[BOX_CONSTS.PRINT_MATERIAL_COATED_CARDBOARD_WHITE_INSIDE] = "v4-addon-white-inside",
    _b[BOX_CONSTS.PRINT_COLOR_MODE_INDIGO_COLOR_WITH_INSIDE] = "v4-addon-color-inside",
    _b);
var editorImagePaths = (_c = {},
    _c[BOX_CONSTS.PRINT_MATERIAL_NATURAL_CARDBOARD] = EditorImage.KRAFT_CARDBOARD,
    _c[BOX_CONSTS.PRINT_MATERIAL_WHITE_ONE_SIDE_CARDBOARD] = EditorImage.WHITE_CARDBOARD,
    _c[BOX_CONSTS.PRINT_MATERIAL_WHITE_CARDBOARD] = EditorImage.WHITE_CARDBOARD,
    _c[BOX_CONSTS.PRINT_MATERIAL_COATED_CARDBOARD] = EditorImage.WHITE_CARDBOARD,
    _c[BOX_CONSTS.PRINT_MATERIAL_CARDSTOCK] = EditorImage.WHITE_CARDBOARD,
    _c[BOX_CONSTS.PRINT_MATERIAL_KRAFT] = EditorImage.KRAFT_CARDBOARD,
    _c[BOX_CONSTS.PRINT_MATERIAL_COATED_CARDBOARD_WHITE_INSIDE] = EditorImage.WHITE_INSIDE,
    _c[BOX_CONSTS.PRINT_MATERIAL_COATED_CARDBOARD] = EditorImage.KRAFT_INSIDE,
    _c[BOX_CONSTS.PRINT_FINISH_FOIL_GLOSSY] = EditorImage.GLOSSY_FOIL,
    _c[BOX_CONSTS.PRINT_FINISH_FOIL_MATTE] = EditorImage.MATT_FOIL,
    _c[BOX_CONSTS.PRINT_FINISH_DOUBLE_STRIP] = EditorImage.DOUBLE_STRIP,
    _c[BOX_CONSTS.PRINT_FINISH_SINGLE_STRIP] = EditorImage.SINGLE_STRIP,
    _c[BOX_CONSTS.PRINT_FINISH_SLEEVE_WHITE] = EditorImage.SLEEVE_WHITE,
    _c[BOX_CONSTS.PRINT_FINISH_SLEEVE_KRAFT] = EditorImage.SLEEVE_KRAFT,
    _c[MailerBoxVariant.MAILER_BOX_ECO_VARIANT] = EditorImage.ECO,
    _c[MailerBoxVariant.MAILER_BOX_ECO_WHITE_VARIANT] = EditorImage.ECO_WHITE,
    _c[MailerBoxVariant.MAILER_BOX_ECO_COLOR_VARIANT] = EditorImage.ECO_COLOR,
    _c[MailerBoxVariant.MAILER_BOX_FULL_COLOR_VARIANT] = EditorImage.FULL_COLOR,
    _c[MailerBoxVariant.MAILER_BOX_LITE_COLOR_VARIANT] = EditorImage.LITE_COLOR,
    _c[WhiteInkMailerBoxVariant.MAILER_BOX_WHITE_ON_KRAFT_VARIANT] = EditorImage.WHITE_ON_KRAFT,
    _c);
var globalAssets = Object.assign({}, { addonImagePaths: addonImagePaths }, { addonsSvgIcons: addonsSvgIcons }, { editorImagePaths: editorImagePaths });
var productAssets = Object.assign({}, productBoxesAssets, mailerBoxesAssets, productBoxesTwoPiecesAssets, productWineBoxesAssets, shippingBoxesAssets);
var assets = Object.assign({}, { globalAssets: globalAssets }, { productAssets: productAssets });
export default assets;
