import React from "react"
import { FormattedHTMLMessage } from "react-intl"
import { observer } from "mobx-react-lite"
import _times from "lodash/times"
import _orderBy from "lodash/orderBy"
import { ButtonAsLink } from "dsl/src/atoms/Button/Button"
import { ButtonModes } from "dsl/src/atoms/Button"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { getFormattedPrice } from "shared-libs/src/js/shared-views/sustainability/planting-trees/reforestation-calculator/reforestation-calculator.utils"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { useTranslate } from "../../../../hooks/useTranslate"
import { I18N } from "../../../../i18n"
import { TM } from "../../../../TypographyI18n"
import { useCurrency } from "../../../../hooks/useCurrency"
import Modal, { Alignment, InnerSize } from "../Modal"
import { ModalTemplate } from "../partials/ModalTemplate"

import { ReactComponent as IconAi } from "../../../../assets/svg/icon-ai.svg"
import { ReactComponent as IconPdf } from "../../../../assets/svg/icon-pdf.svg"
import { ReactComponent as IconFsc } from "../../../../assets/svg/fsc-badge.svg"
import { ReactComponent as IconLeaf } from "../../../../assets/svg/leaf-badge.svg"
import { ReactComponent as IconTrash } from "../../../../assets/svg/trash-badge.svg"
import { ReactComponent as IconDownload } from "../../../../assets/svg/download.svg"
import { ReactComponent as IconEdit } from "../../../../assets/svg/pen2.svg"

import styles from "./DownloadDielinesModal.module.scss"
import cxBinder from "classnames/bind"

import { EditorToolbarItem } from "../../../../../stores/editor-toolbar-store"
import { Product } from "@ph/product-api"
import { DielineStore } from "../../../../../stores/product-design-store/dieline.store"

import { Loader, LoaderSizes, LoaderTypes } from "dsl/src/atoms/Loader/Loader"
import { eventTree } from "../../../../../stores/editor-events"

const cx = cxBinder.bind(styles)
interface DownloadDielinesModalProps {
  onClose: () => void
}

interface Resource {
  id: "ecoBadge" | "fsc" | "tree" | "ecoProps"
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  title: string
  message: string
  order: number
}

const fscResource: Resource = {
  id: "fsc",
  Icon: IconFsc,
  title: I18N.modals.downloadDielines.resourcesFscLabels,
  message: I18N.modals.downloadDielines.fscLabelsMessage,
  order: 1,
}

const treeResource: Resource = {
  id: "tree",
  Icon: IconLeaf,
  title: I18N.modals.downloadDielines.resourcesPlantTree,
  message: I18N.modals.downloadDielines.plantTreeMessage,
  order: 2,
}

const ecoPropsResource: Resource = {
  id: "ecoProps",
  Icon: IconTrash,
  title: I18N.modals.downloadDielines.resourcesEcoProps,
  message: I18N.modals.downloadDielines.ecoPropsMessage,
  order: 3,
}

const DielinesSection = observer(
  ({
    product,
    dielineStore,
    onClick,
  }: {
    product: Product
    dielineStore: DielineStore
    onClick: () => void
  }) => {
    const t = useTranslate()
    const dielinePdfUrl = dielineStore.getDbyDielineUrl(product, "pdf")
    const dielineAiUrl = dielineStore.getDbyDielineUrl(product, "ai")

    return (
      <>
        <TM
          id={I18N.modals.downloadDielines.headerGuidelines}
          type={TType.Header17_500}
          className={styles.header}
        />

        {dielineStore.isLoadingDbyDielineUrls ? (
          <ButtonAsLink
            icon={
              <Loader type={LoaderTypes.circular} size={LoaderSizes.small} />
            }
            fluid
            isIconOnLeft
            disabled
          >
            {t(I18N.modals.downloadDielines.generatingFile)}
          </ButtonAsLink>
        ) : (
          <>
            {dielineAiUrl && (
              <div className={styles.button}>
                <ButtonAsLink
                  href={dielineAiUrl}
                  icon={<IconAi />}
                  mode={ButtonModes.secondary}
                  fluid
                  isIconOnLeft
                  target="_blank"
                  onClick={onClick}
                >
                  {t(I18N.modals.downloadDielines.downloadAI)}
                </ButtonAsLink>
              </div>
            )}

            {dielinePdfUrl && (
              <ButtonAsLink
                href={dielinePdfUrl}
                icon={<IconPdf />}
                mode={ButtonModes.secondary}
                fluid
                isIconOnLeft
                target="_blank"
                onClick={onClick}
              >
                {t(I18N.modals.downloadDielines.downloadPDF)}
              </ButtonAsLink>
            )}
          </>
        )}
      </>
    )
  }
)

const DownloadDielinesModalBody = observer(
  ({ onClose }: DownloadDielinesModalProps) => {
    const t = useTranslate()
    const currency = useCurrency()

    const [headerSet, headerSetErrors] = useContainerSet((c) => [
      c.designAndProductDriver,
      c.ecommerce,
      c.fscCertificate,
      c.assets,
      c.ui,
      c.envUtil,
    ])

    if (headerSet == null || headerSetErrors != null) return null

    const {
      designAndProductDriver,
      ecommerce,
      fscCertificate,
      assets,
      ui,
      envUtil,
    } = headerSet

    if (!ecommerce.available) {
      return null
    }

    const { ee } = envUtil
    const { dielineStore } = designAndProductDriver
    const { productStore } = designAndProductDriver.productDriver
    const { productRenderPilot } = designAndProductDriver.productDriver.state
    const { ecoShapesStore } = assets
    const { editorToolbarStore, editorModeController } = ui
    const isDbyMode = productRenderPilot.isDbyMode()
    const isAfterPurchaseEdit = productRenderPilot.isAfterPurchaseEdit
    const treePrice = ecommerce.plantingTreesStore?.grossPriceIfApplicable

    let fscPrice

    const getProductSummary = (): string => {
      const {
        variantNameKey,
        size,
        printModeSummaryKey,
        finishKey,
        materialKey,
      } = productRenderPilot.getFullProductDescription()

      const variantNameIntl = t(variantNameKey)
      const printModeIntl = printModeSummaryKey
        ? `, ${t(printModeSummaryKey)}`
        : ""
      const availableMaterials = productRenderPilot.getAvailableMaterials()
      const materialIntl =
        availableMaterials.length > 1 && materialKey
          ? `, ${t(materialKey)}`
          : ""
      const finishIntl = finishKey ? `, ${t(finishKey)}` : ""

      return `${variantNameIntl}, ${size}${printModeIntl}${materialIntl}${finishIntl}`
    }

    if (
      fscCertificate.available &&
      fscCertificate.fscCertificateStore.fscCertificate
    ) {
      fscPrice = ecommerce.taxStore.getPrice(
        fscCertificate.fscCertificateStore.fscCertificate,
        designAndProductDriver.productDesignStore.state.meta.quantity
      ).displayPrice
    }

    const resources: Resource[] = []
    const treeCost = treePrice && getFormattedPrice(treePrice, currency)
    const fscCost = fscPrice && getFormattedPrice(fscPrice, currency)

    const shouldDisplayFsc =
      !!fscPrice && !resources.some((item) => item.id === "fsc")
    const shouldDisplayTree =
      !isAfterPurchaseEdit &&
      treePrice &&
      !resources.some((item) => item.id === "tree")
    const shouldDisplayEcoProps = !productStore.isProductPim

    if (shouldDisplayFsc) {
      resources.push(fscResource)
    }

    if (shouldDisplayTree) {
      resources.push(treeResource)
    }

    if (shouldDisplayEcoProps) {
      resources.push(ecoPropsResource)
    }

    const orderedResources = _orderBy(resources, (r) => r.order)

    const openDbyUploader = () => {
      if (isDbyMode) {
        onClose()
      } else {
        editorModeController.switchToDbyMode()
      }
    }

    const getDownloadUrl = (resource: Resource): string => {
      const urls = {
        ecoProps: ecoShapesStore.ecoShapesDownloadUrl,
        tree: ecoShapesStore.treeBadgesDownloadUrl,
        fsc:
          fscCertificate.available &&
          fscCertificate.fscCertificateStore.fscCertificateShapesDownloadUrl,
      }

      return urls[resource.id] || ""
    }

    const onDownloadArtworkClick = () => {
      ee.emit(
        eventTree.ui.DownloadArtworkClicked,
        designAndProductDriver.productDesignStore.state.meta.quantity,
        designAndProductDriver.productDriver.productStore.productSku
      )
    }

    return (
      <div className={styles.download_dieliene_modal_body}>
        <hr className={styles.divider} />

        <div className={styles.summary}>
          <TM
            id={I18N.modals.downloadDielines.yourConfiguration}
            type={TType.Header17_500}
            className={styles.summary_header}
          />
          <div className={styles.summary_container}>
            <Typography
              type={TType.Body13_350}
              className={styles.summary_caption}
              htmlElement="p"
            >
              {getProductSummary()}
            </Typography>
            {!isAfterPurchaseEdit && (
              <div
                className={styles.summary_icon}
                onClick={() => {
                  onClose()
                  editorToolbarStore.selectTab(EditorToolbarItem.changeSize)
                }}
              >
                <IconEdit />
              </div>
            )}
          </div>
        </div>
        <TM
          id={I18N.modals.downloadDielines.headerInstructions}
          type={TType.Header17_500}
          className={styles.header}
        />

        {_times(3, (index) => {
          const isClickable = index === 2

          return (
            <div className={styles.instruction_body} key={index}>
              <Typography type={TType.Header15_500} className={styles.number}>
                {index + 1}
              </Typography>

              <Typography
                type={TType.Body15_350}
                className={cx({ link: isClickable })}
                onClick={isClickable ? openDbyUploader : () => {}}
              >
                <FormattedHTMLMessage
                  id={
                    isClickable && isDbyMode
                      ? I18N.modals.downloadDielines.instructions.dbyMode
                      : I18N.modals.downloadDielines.instructions[index + 1]
                  }
                />
              </Typography>
            </div>
          )
        })}

        <DielinesSection
          product={productStore.product}
          dielineStore={dielineStore}
          onClick={onDownloadArtworkClick}
        />

        <TM
          id={I18N.modals.downloadDielines.headerResources}
          type={TType.Header17_500}
          className={styles.header}
        />

        {orderedResources.map((item, index) => {
          const downloadUrl = getDownloadUrl(item)

          return (
            <div key={index}>
              {downloadUrl && (
                <a
                  href={downloadUrl}
                  target="_blank"
                  className={styles.resources_body}
                >
                  <item.Icon className={styles.badge} />
                  <div className={styles.container}>
                    <TM id={item.title} type={TType.Header15_500} />
                    <TM
                      id={item.message}
                      type={TType.Body13_350}
                      values={{ treeCost, fscCost }}
                    />
                  </div>
                  <div className={styles.icon}>
                    <IconDownload />
                  </div>
                </a>
              )}
            </div>
          )
        })}
      </div>
    )
  }
)

export const DownloadDielinesModal = observer(
  ({ onClose }: DownloadDielinesModalProps) => {
    return (
      <Modal
        e2eTargetName="download-dielines"
        onClose={onClose}
        maxWidth={InnerSize.extraSmall}
        fullHeight
        align={Alignment.right}
      >
        <ModalTemplate
          header={
            <TM
              id={I18N.modals.downloadDielines.title}
              type={TType.Header32_400}
            />
          }
          footer={<></>}
        >
          <DownloadDielinesModalBody onClose={onClose} />
        </ModalTemplate>
      </Modal>
    )
  }
)
