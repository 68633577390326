export default {
    P13: {
        outside: {
            width: 25,
            length: 25,
            height: 80,
        },
        inside: {
            width: 25,
            length: 25,
            height: 80,
        },
    },
    P15: {
        outside: {
            width: 30,
            length: 30,
            height: 90,
        },
        inside: {
            width: 30,
            length: 30,
            height: 90,
        },
    },
    P15i: {
        outside: {
            width: 50,
            length: 50,
            height: 50,
        },
        inside: {
            width: 50,
            length: 50,
            height: 50,
        },
    },
    P18: {
        outside: {
            width: 35,
            length: 35,
            height: 110,
        },
        inside: {
            width: 35,
            length: 35,
            height: 110,
        },
    },
    P18i: {
        outside: {
            width: 60,
            length: 60,
            height: 60,
        },
        inside: {
            width: 60,
            length: 60,
            height: 60,
        },
    },
    P18ii: {
        outside: {
            width: 70,
            length: 30,
            height: 80,
        },
        inside: {
            width: 70,
            length: 30,
            height: 80,
        },
    },
    P20: {
        outside: {
            width: 50,
            length: 50,
            height: 100,
        },
        inside: {
            width: 50,
            length: 50,
            height: 100,
        },
    },
    P21: {
        outside: {
            width: 40,
            length: 40,
            height: 130,
        },
        inside: {
            width: 40,
            length: 40,
            height: 130,
        },
    },
    P21i: {
        outside: {
            width: 70,
            length: 70,
            height: 70,
        },
        inside: {
            width: 70,
            length: 70,
            height: 70,
        },
    },
    P24: {
        outside: {
            width: 70,
            length: 50,
            height: 120,
        },
        inside: {
            width: 70,
            length: 50,
            height: 120,
        },
    },
    P24i: {
        outside: {
            width: 40,
            length: 40,
            height: 160,
        },
        inside: {
            width: 40,
            length: 40,
            height: 160,
        },
    },
    P24ii: {
        outside: {
            width: 50,
            length: 50,
            height: 140,
        },
        inside: {
            width: 50,
            length: 50,
            height: 140,
        },
    },
    P24iii: {
        outside: {
            width: 80,
            length: 80,
            height: 80,
        },
        inside: {
            width: 80,
            length: 80,
            height: 80,
        },
    },
    P25: {
        outside: {
            width: 70,
            length: 70,
            height: 110,
        },
        inside: {
            width: 70,
            length: 70,
            height: 110,
        },
    },
    P26: {
        outside: {
            width: 80,
            length: 80,
            height: 100,
        },
        inside: {
            width: 80,
            length: 80,
            height: 100,
        },
    },
    P28: {
        outside: {
            width: 60,
            length: 60,
            height: 160,
        },
        inside: {
            width: 60,
            length: 60,
            height: 160,
        },
    },
    P29: {
        outside: {
            width: 90,
            length: 60,
            height: 140,
        },
        inside: {
            width: 90,
            length: 60,
            height: 140,
        },
    },
    P30: {
        outside: {
            width: 100,
            length: 100,
            height: 100,
        },
        inside: {
            width: 100,
            length: 100,
            height: 100,
        },
    },
    P34: {
        outside: {
            width: 180,
            length: 80,
            height: 80,
        },
        inside: {
            width: 180,
            length: 80,
            height: 80,
        },
    },
    P35: {
        outside: {
            width: 100,
            length: 100,
            height: 150,
        },
        inside: {
            width: 100,
            length: 100,
            height: 150,
        },
    },
    P36: {
        outside: {
            width: 120,
            length: 120,
            height: 120,
        },
        inside: {
            width: 120,
            length: 120,
            height: 120,
        },
    },
    P39: {
        outside: {
            width: 130,
            length: 130,
            height: 130,
        },
        inside: {
            width: 130,
            length: 130,
            height: 130,
        },
    },
    P40: {
        outside: {
            width: 100,
            length: 100,
            height: 200,
        },
        inside: {
            width: 100,
            length: 100,
            height: 200,
        },
    },
    P43: {
        outside: {
            width: 180,
            length: 30,
            height: 220,
        },
        inside: {
            width: 180,
            length: 30,
            height: 220,
        },
    },
    P45: {
        outside: {
            width: 150,
            length: 150,
            height: 150,
        },
        inside: {
            width: 150,
            length: 150,
            height: 150,
        },
    },
};
