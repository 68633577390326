import * as shared from "../shared/shared-consts"

const Region = {
  trustpilotId: "b6a3f95a4a",
  trustpilotBusinessId: "5d5aad5728b74600019395c6",
  trustpilotInvitationTemplateId: "5278a79c0da2b11ee0f0088e",

  googleapisKey: shared.GOOGLEAPIS_KEY,
  mainRegion: true,
  hasVat: true,
  secondaryAnalyticsCode: "UA-71580330-8",
  productRegion: "de",
  countryCode: "de",
  languageTag: "de-DE",
  iterableLocale: "de-DE",
  locale: "de",
  phoneCountryCode: "+49",
  defaultShippingCountryCode: "DE",

  additionalCustoms: 0,

  emailAddress: "hello@packhelp.com",
  emailSenderName: "Packhelp.de",
  phoneNumber: "01 2535848",
  mainDomain: shared.DE_MAIN_DOMAIN,
  logoSVGIcon: "v3-packhelp_color_on_white",
  logoSVGIconWhite: "v3-packhelp_white_logo_on_black",
  pageTitle: "Editor - Packhelp.de",
  footerLabel: "Packhelp",
  frontendBoxDesignRoute: "box/design",
  frontendAccountOrdersRoute: "account/orders",
  frontendTermsOfServiceRoute: "/agb/",
  frontendPrivacyPolicyRoute: "/datenschutz/",
  domain: {
    basename: "packhelp",
    tld: "de",
  },
  externalLinks: {
    affiliates: "/werden-sie-ein-affiliate/",
    fscCertification: "/fsc-zertifikat/",
    helpPlantTrees: "/baeume-pflanzen/",
    ecoProperties: "/eco-eigenschaften-leitfaden/",
    sustainability: "/nachhaltigkeit/",
    holidays: "/weihnachtssaison/",
    designShowcase: "/design-vorlagen/",
    inspirationsHandbook: "/inspirations-handbook/",
    mobileAppLandingPageUrl: "/mobile-app/",
    brief: "/brief/",
    artworkTipsDielines: "/gestaltungstipps-und-stanzvorlagen/",
    help: "/hilfe/",
    thankYou: "/danke/",
    designGuidelines: "/hilfe/themen/der-online-assistent/",
    about: "/uber-uns/",
    career: "/werdegang/",
    shop: "/angebot/",
    services: "/services/",
    industries: "/i/",
    bundles: "/verpackungssets",
    inspirations: "/ideen/",
    impressum: "/impressum/",
    returnPolicy: "/hilfe/thema/kann-ich-bestellte-kartons-zuruckgeben/",
    blog: "/blog/",
    contact: "/kontakt-de/",
    privacyPolicy: "/datenschutz/",
    termsOfService: "/agb/",
    termsOfServicePlus: "/terms-of-services-plus/",
    accountSignOut: "/sign_out/",
    accountSignIn: "/sign_in/",
    accountRegister: "/registration",
    accountOrders: "/account/orders",
    accountOrderDetails: "/account/orders/:orderNumber",
    accountSettings: "/account/settings",
    accountSupportReforestation: "/account/support-reforestation",
    accountBoxes: "/account/boxes",
    accountWallet: "/account/packhelp-wallet",
    accountCart: "/cart/", // old checkout key - to be removed
    samples: "/sample-packs",
    customOffer: "/offer/:token/review",
    payment: "/order/:orderId/payment",
    paymentSummary: "/order/:orderId/payment/summary",
    quoteRequest: "account/quote_requests/:qrNumber",
    cart: "/cart/",
    orderDetails: "/order",
    crossSell: "/cart/recommended-products-and-services/s",
    fullAndEcoColorHelpSite:
      "/hilfe/thema/was-ist-der-unterschied-zwischen-full-color-und-eco-color-boxen/",
    safeZonesHelpSite: "/hilfe/thema/abstand-von-den-randern-halten/",
    samplePacksLink:
      "/box/design?sku=box-eu--samples--samples--samples--samples--samples&dielineMode=false",
    termsAcceptedSite: "/nutzungsbedingungen-akzeptiert/",
    packhelpWallet:
      "/das-packhelp-wallet-wir-fuehren-ein-neues-system-ein-mit-dem-sie-bei-ihrer-bestellung-geld-sparen-koennen/",
    ecoBadge: "/packhelp-oeko-abzeichen/",
    leadership: "/leadership/",
    press: "/presse/",
    urlWhitelistedToBeFetchedFromUrl: shared.urlWhitelistedToBeFetchedFromUrl,
    products: {
      "mailer-box-plain": "/versandschachteln-ohne-aufdruck/",
      "mailer-box-eco": "/eco-versandschachteln/",
      "mailer-box-eco-white": "/eco-white-versandschachteln/",
      "mailer-box-full-color": "/full-color-versandschachteln/",
      "mailer-box-eco-color": "/eco-color-versandschachteln/",
      "delivery-mailer-box": "/ecommerce-versandbox/",
      "delivery-mailer-box-plain": "/ecommerce-versandbox-ohne-aufdruck/",
      "shipping-box": "/eco-faltkartons/",
      "shipping-box-plain": "/faltkartons-ohne-aufdruck/",
      "product-box": "/faltschachteln/",
      "product-box-two-pieces": "/stulpschachteln/",
      "poly-mailer": "/klassisch-versandbeutel/",
      "paper-mailer": "/papier-versandbeutel/",
      "paper-bag": "/papiertaschen-mit-henkel/",
      "rigid-envelope": "/karton-versandtaschen-mit-aufdruck/",
      "product-box-wine-box": "/weinverpackungen-mit-aufdruck/",
      "tissue-paper": "/seidenpapier-mit-aufdruck/",
      "packing-paper": "/packpapier/",
      "packing-tape": "/personalisierte-packbaender/",
      "sleeved-mailer-box": "/karton-banderole/",
      "kraft-tape": "/kraft-packbander/",
      "bio-poly-mailer": "/kompostierbar-versandbeutel/",
      "paper-can": "/papierdosen/",
      "mailer-box-white-ink": "/white-on-kraft-versandschachteln/",
      "mailer-box-full-color-double-sided":
        "/versandschachteln-mit-bedruckter-innenseite/",
      "rigid-box": "/stabile-schachteln/",
      "rigid-box-two-pieces": "/stabile-schachtel-mit-deckel/",
      "special-box": "/spezialschachteln/",
      "magnetic-box": "/magnetboxen/",
      "carrier-box": "/schachteln-mit-henkel/",
      "rigid-drawer-box": "/schiebeschachtel/",
      "cake-box": "/tortenkartons/",
      "bio-poly-mailer-plain": "/bedruckter-kompostierbarer-versandbeutel/",
      "pre-printed-mailer-box-eco":
        "/eco-versandschachteln-mit-fertigem-aufdruck/",
      "pre-printed-mailer-box-full-colour":
        "/full-colour-versandschachteln-mit-fertigem-aufdruck/",
      "doypack-bag": "/standbodenbeutel/",
      "square-label": "/bedruckte-etiketten/",
      "tote-bag": "/baumwolltasche-mit-aufdruck/",
      "printed-card": "/dankeskarten/",
      "pre-printed-kraft-tape": "/papierklebeband-mit-fertigem-aufdruck/",
      "recycled-poly-mailer": "/versandbeutel-aus-rezyklat/",
    },
    categories: {
      "mailer-boxes": "/versandschachteln/",
      "custom-shipping-boxes": "/faltkartons/",
      "primary-packaging": "/bedruckten-produktverpackungen/",
      "eco-friendly-packaging": "/nachhaltige-verpackung/",
      "luxury-packaging": "/premium-boxen/",
    },
    fb_url: shared.DE_MAIN_DOMAIN,
    fb_sitename: "Packhelp",
    fb_image: "/share_image_1200x630.png",
    share_img: "/share_image_120x120.png", // Editor
    boxEditorPath: "/box/design",
    productEditor: "/editor/product/design",
    exampleDesign: "79485?language=de&templateMode=true",
    productQuotationSystem: "/quote",
    pqsSuccessPage: "/quote/success",
    plus: {
      catalogue: "/plus/produktkatalog/",
      successPage: "/plus/success",
      items: "/packhelp-plus/items",
      quotes: "/packhelp-plus/quotes",
      quoteShow: "/packhelp-plus/quotes/:token",
      orders: "/packhelp-plus/orders",
      orderShow: "/packhelp-plus/orders/:orderNumber/details",
      orderPlaced: "/packhelp-plus/orders/:orderNumber/placed",
      orderItems: "/packhelp-plus/orders/:orderNumber/items",
      orderPaymentSummary: "/packhelp-plus/orders/:orderNumber/payment-summary",
      "doypack-bag": "/plus/produktkatalog/standbodenbeutel",
      personalDetails: "/packhelp-plus/personal-details",
      "tote-bag": "/plus/produktkatalog/stofftasche-mit-aufdruck/",
      "product-boxes": "plus/produktkatalog/verpackungsart/faltschachteln/",
      "mailer-boxes": "plus/produktkatalog/verpackungsart/versandschachteln/",
    },
    pro: {
      features: "pro/features/",
      suppliers: "pro/for-suppliers/",
      termsOfService: "pro/terms-conditions/",
    },
    industriesListing: "/i/",
    whiteLabel: "/enterprise/",
  },

  outsidePackhelpDomainLinks: {
    ...shared.externalDomainPackhelpLinks,
  },

  ...shared.SHARED_ECOMMERCE_PROPERTIES.eu,
  ...shared.SHARED_CURRENCY_PROPERTIES.eur,
  allowedPaymentMethods: [
    "paypal",
    "check",
    "stripe",
    "p24",
    "ideal",
    "bancontact",
    "eps",
    "giropay",
    "card",
  ],

  remindMeOnDesktop: {
    remindme_form_url:
      "//packhelp.us12.list-manage.com/subscribe/post-json?u=2060c1de14ec30d28a9fab813&amp;id=66418d98fb&c=?",
    remindme_hidden_input_name: "b_2060c1de14ec30d28a9fab813_66418d98fb",
  },

  datesFormattingRules: shared.DATES_FORMATTING_RULES_EU,

  googleConversionId: "859901857",

  googleAnalytics: {
    primaryCode: "UA-71580330-2",
    secondaryCode: "UA-71580330-8",
    segmentCode: "uXDomf9GW2BCffFseKvLrarxNiGNLI5O",
  },
  googleAdWords: {
    remarketingCode: "39342",
    conversionCode: "859901857",
  },

  facebookAppId: "1660833110841347",
  supportsPlus: true,
}

export default Region
