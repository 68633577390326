import { action, observable, makeObservable } from "mobx"

export class AfterPurchaseEditStore {
  @observable public isAfterPurchaseEdit = false

  constructor() {
    makeObservable(this)
  }

  @action
  public setIsAfterPurchaseEdit(isAfterPurchaseEdit: boolean) {
    this.isAfterPurchaseEdit = isAfterPurchaseEdit
  }
}
