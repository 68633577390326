import { getRoutingConfiguration } from "../services/_routing"
import type { CartStore } from "../stores/cart-store/cart.store"
import type { PlantingTreesStore } from "../stores/planting-trees-store/planting-trees.store"
import type { CurrencyStore } from "../stores/pricing-store/currency.store"
import type { ProductPricingStore } from "../stores/pricing-store/product-pricing.store"
import type { TaxStore } from "../stores/pricing-store/tax.store"
import type { ApiSessionContainer } from "./api-session-container"
import type { DesignAndProductDriverContainer } from "./design-and-product-driver-container"
import type { UtilEnvContainer } from "./util-container"
import type { ProductEcommercePilot } from "../libs/products-ecommerce-config/product-ecommerce-pilot"
import { BootstrapClassicRootStoreContainer } from "./root-store-bootstrap-container"
import type { DesignLaterController } from "../stores/_controllers/design-later-controller"
import { AuthMaybeContainer } from "./auth-maybe-container"
import { AfterPurchaseEditContainer } from "./after-purchase-edit-container"
import { CartDto } from "../modules/ecommerce-api/types"

async function importDeps() {
  const { CurrencyStore } = await import(
    "../stores/pricing-store/currency.store"
  )
  const { TaxStore } = await import("../stores/pricing-store/tax.store")
  const { DesignLaterController } = await import(
    "../stores/_controllers/design-later-controller"
  )
  const { PricingManager } = await import("../services/pricing-manager")
  const { ProductEcommercePilot } = await import(
    "../libs/products-ecommerce-config/product-ecommerce-pilot"
  )
  const { ProductPricingStore } = await import(
    "../stores/pricing-store/product-pricing.store"
  )
  const { PlantingTreesStore } = await import(
    "../stores/planting-trees-store/planting-trees.store"
  )
  const { CartStore } = await import("../stores/cart-store/cart.store")

  return {
    CurrencyStore,
    TaxStore,
    DesignLaterController,
    PricingManager,
    ProductEcommercePilot,
    ProductPricingStore,
    PlantingTreesStore,
    CartStore,
  }
}

export async function provideEcommerceMaybeContainer(
  utilEnvContainer: UtilEnvContainer,
  apiSessionContainer: ApiSessionContainer,
  designAndProduct: DesignAndProductDriverContainer,
  rootBootstrapper: BootstrapClassicRootStoreContainer,
  authContainer: AuthMaybeContainer,
  afterPurchaseEditContainer: AfterPurchaseEditContainer
): Promise<EcommerceMaybeContainer> {
  const { uri, appConfig } = utilEnvContainer
  const { apiService, designApi, ecommerceApi } = apiSessionContainer
  const { productDesignStore, productDriver } = designAndProduct
  const { productStore } = rootBootstrapper
  const { authController } = authContainer
  const { afterPurchaseEditController, afterPurchaseEditStore } =
    afterPurchaseEditContainer

  if (
    !appConfig.api.ecommerce.features.ecommerce.basic ||
    uri.isDesignerMode() ||
    uri.isDtpPreviewMode()
  ) {
    return {
      available: false,
      currencyStore: undefined,
      taxStore: undefined,
      plantingTreesStore: undefined,
      productPricingStore: undefined,
      cartStore: undefined,
      cart: undefined,
      designLaterController: undefined,
      productEcommercePilot: undefined,
    }
  }

  const {
    CurrencyStore,
    TaxStore,
    DesignLaterController,
    PricingManager,
    ProductEcommercePilot,
    ProductPricingStore,
    PlantingTreesStore,
    CartStore,
  } = await importDeps()

  const orderNumberFromUrl = uri.getOrderNumber()
  const cart = await ecommerceApi.cart.get()
  const order = orderNumberFromUrl
    ? await ecommerceApi.order.get(orderNumberFromUrl)
    : undefined

  await afterPurchaseEditController.setAfterPurchaseEdit(
    order?.state || cart.state
  )

  productDriver.state.productRenderPilot.setAfterPurchaseEdit(
    afterPurchaseEditStore.isAfterPurchaseEdit
  )

  // DOMAIN: TAX, CURRENCY, TREES PRICING
  const currencyStore = new CurrencyStore(appConfig.locale)
  const taxStore = new TaxStore(
    getRoutingConfiguration().taxConfiguration,
    appConfig.locale
  )

  const plantingTreesStore = new PlantingTreesStore(currencyStore, taxStore)
  const pricingManager = new PricingManager(
    ecommerceApi.pricing,
    taxStore,
    appConfig.locale.productRegion
  )

  const productPricingStore = new ProductPricingStore(
    pricingManager,
    productDriver,
    productDesignStore,
    plantingTreesStore,
    taxStore,
    authController?.authEventEmitter
  )

  await productPricingStore.loadPricing(productStore.product)

  if (taxStore.taxConfig.hasTax) {
    taxStore.guessTaxRate(
      productPricingStore.getVolumePrices(productStore.product)[0]
    )
  }

  const cartStore = new CartStore(
    ecommerceApi.cart,
    apiService,
    designApi,
    plantingTreesStore,
    productStore
  )

  const designLaterController = new DesignLaterController(
    uri,
    cartStore,
    productDriver,
    productDesignStore
  )

  /**
   * Just for now.
   *
   * In the long run we should refactor EcommerceContainer to be optional
   * (i.e. in the designer mode).
   */
  const optionalServices: { productEcommercePilot?: ProductEcommercePilot } = {}

  if (productDriver.productStore.product) {
    optionalServices.productEcommercePilot = new ProductEcommercePilot(
      appConfig,
      () => productDriver.productStore.product
    )
  }

  return {
    available: true,
    currencyStore,
    taxStore,
    plantingTreesStore,
    productPricingStore,
    cartStore,
    cart,
    designLaterController,
    ...optionalServices,
  }
}

export type EcommerceMaybeContainer =
  | {
      available: true
      currencyStore: CurrencyStore
      taxStore: TaxStore
      plantingTreesStore: PlantingTreesStore
      productPricingStore: ProductPricingStore
      cartStore: CartStore
      cart: CartDto
      designLaterController: DesignLaterController
      productEcommercePilot?: ProductEcommercePilot
    }
  | {
      available: false
      currencyStore: undefined
      taxStore: undefined
      plantingTreesStore: undefined
      productPricingStore: undefined
      cartStore: undefined
      cart: undefined
      designLaterController: undefined
      productEcommercePilot: undefined
    }
