import {
  isSkuPim,
  Product,
  ProductFactory,
  VariantCustomization,
} from "@ph/product-api"
import { action, computed, makeObservable, observable } from "mobx"
import { AppConfig } from "../../app-config/app.config"

export class ProductStore {
  @observable public product!: Product

  private constructor(private readonly appConfig: AppConfig) {
    makeObservable(this)
  }

  public static async init(
    appConfig: AppConfig,
    sku: string,
    customization?: VariantCustomization
  ): Promise<ProductStore> {
    const productStore = new ProductStore(appConfig)
    await productStore.setProductBySku(sku, customization)

    return productStore
  }

  @computed
  public get isProductPim(): boolean {
    return isSkuPim(this.product.variantManager.getSku())
  }

  @computed
  public get isCustomSizeAvailable(): boolean {
    return (
      this.appConfig.api.ecommerce.features.ecommerce.customSizes &&
      this.product.isEditorCustomSizeEnabled
    )
  }

  @action
  public async setProductBySku(
    sku: string,
    customization?: VariantCustomization
  ): Promise<Product> {
    this.product = await this.loadProduct(sku, customization)

    return this.product
  }

  @computed
  public get productSku(): string {
    return this.product.variantManager.getSku()
  }

  @computed
  public get customization(): VariantCustomization | undefined {
    return this.product.getDefaultVariant().customization
  }

  private async loadProduct(
    sku: string,
    customization?: VariantCustomization
  ): Promise<Product> {
    return new ProductFactory({
      api: {
        ...this.appConfig.api.pim,
        lang: this.appConfig.locale.lang,
        region: this.appConfig.locale.region,
        salesChannelId: this.appConfig.locale.salesChannelId,
      },
      propertiesToExpand: [
        "variants",
        "configurable_properties",
        "pss",
        "editor_assets",
        "editor_related_products",
      ],
      withEmptyPricing: true,
      forceProductConfigForLegacySku: false,
      expandDefaultProperties: false,
    }).call(sku, customization)
  }
}
