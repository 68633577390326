import axios, { AxiosInstance } from "axios"
import { EcommerceApi, EcommerceApiConfig } from "../types"
import { UserApi } from "./endpoints/user-api"
import { LogoApi } from "./endpoints/logo-api"
import { CartApi } from "./endpoints/cart-api"
import { OrderApi } from "./endpoints/order-api"
import { PricingApi } from "./endpoints/pricing-api"
import { QrCodeApi } from "./endpoints/qr-code-api"

export class RestEcommerceApi implements EcommerceApi {
  public readonly user: UserApi
  public readonly logo: LogoApi
  public readonly cart: CartApi
  public readonly order: OrderApi
  public readonly pricing: PricingApi
  public readonly qrCode: QrCodeApi

  private readonly api: AxiosInstance

  constructor(private readonly config: EcommerceApiConfig) {
    this.api = this.getAxiosInstance()

    this.user = new UserApi(this.api)
    this.logo = new LogoApi(this.api)
    this.cart = new CartApi(this.api)
    this.order = new OrderApi(this.api)
    this.pricing = new PricingApi(this.api)
    this.qrCode = new QrCodeApi(this.api)
  }

  public setToken(token: string) {
    this.config.token = token
    this.api.defaults.headers["Access-Token"] = token
  }

  private getAxiosInstance(): AxiosInstance {
    return axios.create({
      baseURL: this.config.baseUrl,
      headers: {
        "Access-Token": this.config.token,
      },
    })
  }
}
