import React, { useState } from "react"
import { Button } from "dsl/src/atoms/Button/Button"
import { observer } from "mobx-react-lite"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import { FinishDesignConfirmationModal } from "../../../dsl/organisms/Modal/finish-design-confirmation-modal/finish-design-confirmation-modal"
import { useTranslate } from "../../../hooks/useTranslate"
import { I18N } from "../../../i18n"

export const EditorHeaderFinishDesign = observer(() => {
  const t = useTranslate()

  const [isDesignConfirmationModalOpen, setIsDesignConfirmationModalOpen] =
    useState<boolean>(false)

  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.ecommerce,
    c.envUtil,
    c.apiSession,
    c.analytics,
  ])

  if (!containerSet) {
    return null
  }

  const { designAndProductDriver, ecommerce, envUtil, apiSession, analytics } =
    containerSet

  const { productDesignUiController, productDesignStore } =
    designAndProductDriver

  const toggleDesignConfirmationModal = () => {
    setIsDesignConfirmationModalOpen(!isDesignConfirmationModalOpen)
  }

  const onDesignFinishClick = async () => {
    productDesignStore?.setIsProductDesignLocking(true)
    await productDesignUiController.lock()
    if (!productDesignStore.state.lockDesignError) {
      productDesignStore?.setProductDesignStatus("locked")
    }
    await productDesignUiController.save()

    const userId = apiSession.user.id
    const orderId = ecommerce.cartStore?.cart?.number
    const designId = envUtil.uri.getDesignIdParam()

    analytics.analyticsController?.trackConfirmButtonClicked(
      designId,
      orderId,
      userId
    )
    if (productDesignStore.state.lockDesignError) {
      toggleDesignConfirmationModal()
      //handle  error - waiting for design decision
    } else {
      if (!orderId) {
        envUtil.uri.redirectToDesign(designId)
      } else {
        envUtil.uri.redirectToOrder(orderId)
      }
    }
  }

  return (
    <>
      <Button
        onClick={toggleDesignConfirmationModal}
        e2eTargetName="review-and-purchase"
      >
        {t(I18N.modals.bndl.finishDesign)}
      </Button>
      {isDesignConfirmationModalOpen && (
        <FinishDesignConfirmationModal
          onConfirm={onDesignFinishClick}
          onClose={() => {
            toggleDesignConfirmationModal()
          }}
          isLocking={productDesignStore.state.isProductDesignLocking}
        />
      )}
    </>
  )
})
