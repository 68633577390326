import { useEffect, useState } from "react"
import moment from "moment"
import dayjs from "dayjs"
import { loadDayJsLocale } from "shared-libs/src/js/libs/others/dayjs-countries"
import RegionConfig from "../../../../../configs/region-config/interfaces/js/region-config"
import {
  SupportedLocales,
  SupportedCurrency,
} from "../../../../../configs/region-config/interfaces/js/region-config.types"
import ApiService, {
  apiServiceWithCurrentToken,
} from "../../../../libs/services/api/api-service"
import {
  getUserWallet,
  updateUserWallet,
} from "../../../../libs/services/api/endpoints/user-endpoints"
import { getUserOrders } from "../../../../libs/services/api/endpoints/order-endpoints"
import { getApiTokenFromCookie } from "../../../../libs/helpers/api-token.helpers"
import { breakpoint } from "dsl/src/theme/breakpoint"
import {
  WalletExpiryReminderOptions,
  WalletDataAttributes,
} from "./wallet.types"
import { appendRedirectParam } from "../../../../libs/services/auth-routing"

export const defaultLevelPercentage = 5

export async function getWalletData(
  currency: SupportedCurrency = getUserCurrency()
) {
  if (getApiTokenFromCookie() === null) {
    return null
  }

  const apiService = new ApiService(null, getApiTokenFromCookie())
  updateDayjsLocaleToCurrent()

  const wallet = await apiService.call(
    //@ts-ignore
    getUserWallet(currency)
  )

  return wallet.data
    ? {
        ...wallet.data.attributes,
        accounts: wallet.included.map(({ attributes }) => ({ ...attributes })),
      }
    : null
}

export async function getOrdersData(): Promise<any> {
  if (getApiTokenFromCookie() === null) {
    return null
  }

  const ordersData = await apiServiceWithCurrentToken.call(getUserOrders())

  return ordersData
}

export async function submitUpdatedWalletData(
  walletData: WalletDataAttributes
): Promise<WalletDataAttributes> {
  const apiService = new ApiService(null, getApiTokenFromCookie())
  const data = await apiService.call(
    //@ts-ignore
    updateUserWallet(walletData)
  )

  return data ? data.attributes : null
}

export function getRemindersInitialValues(
  walletData: WalletDataAttributes
): WalletExpiryReminderOptions[] {
  const values: WalletExpiryReminderOptions[] = []

  if (walletData.email_reminder)
    values.push(WalletExpiryReminderOptions.ByEmail)
  if (walletData.text_reminder)
    values.push(WalletExpiryReminderOptions.ByTextMessage)

  return values
}

export function updateWalletWithReminders(
  walletData: WalletDataAttributes,
  reminders: WalletExpiryReminderOptions[]
): WalletDataAttributes {
  return {
    ...walletData,
    text_reminder: reminders.includes(
      WalletExpiryReminderOptions.ByTextMessage
    ),
    email_reminder: reminders.includes(WalletExpiryReminderOptions.ByEmail),
  }
}

export function useResize() {
  const [width, setWidth] = useState<number>(null!)

  useEffect(() => {
    const updateWidth = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener("resize", updateWidth)

    updateWidth()

    return () => window.removeEventListener("resize", updateWidth)
  }, [width])
}

export function isMobile() {
  return window.innerWidth <= breakpoint.MOBILE["MD"]
}

export function isEnglishLocale(locale: SupportedLocales): boolean {
  return locale === "en"
}

export function navigateToHomePage() {
  window.location.href = RegionConfig.getMainDomainForCurrentRegion()
}

export function separateThousands(amount: string) {
  const withComma = "$&, "
  const withSpace = "$& "
  const replaceNumber = (regex): string =>
    amount.replace(/\d(?=(\d{3})+\.)/g, regex)

  return {
    withComma: (): string => replaceNumber(withComma),
    withSpace: (): string => replaceNumber(withSpace),
  }
}

export const getUserCurrency = () => {
  // @ts-ignore
  return RegionConfig.getCurrencyCode()
}

export const getUserCurrencySign = (currency) => {
  return RegionConfig.getCurrencySignByCurrency(currency.toUpperCase())
}

export const getNextOrderDiscount = (data: WalletDataAttributes) => {
  const { current_step: currentStep, step_discount_rules: stepDiscounts = [] } =
    data

  const lastStepIndex = stepDiscounts.length - 1
  const isWithinWalletRange = currentStep < lastStepIndex

  const nextStepDiscount = stepDiscounts[currentStep + 1]
  const lastStepDiscount = stepDiscounts[lastStepIndex]

  const nextOrderDiscount = isWithinWalletRange
    ? nextStepDiscount
    : lastStepDiscount

  return nextOrderDiscount
}

export const getLocale = (): string => {
  const locale = RegionConfig.getCurrentLocale()

  if (locale === "cz") {
    return "cs"
  }

  return locale
}

async function updateDayjsLocaleToCurrent() {
  const locale = getLocale()
  await loadDayJsLocale(locale)
}

export const getFormattedExpiryDate = (expires_at: string): string => {
  const locale = getLocale()

  updateDayjsLocaleToCurrent()

  const isPolishLocale = locale === "pl"
  const dateFormat = isPolishLocale ? "D MMM, YY" : "D MMM, YY"

  return dayjs(expires_at).format(dateFormat)
}

export const getLandingWalletBlogPage = (): string => {
  return RegionConfig.getLandingExternalLinkForPage("packhelpWallet")!
}

export const getFormattedFuturePrice = (
  percentage: number,
  totalPrice: number = 0,
  currency: SupportedCurrency
): string => {
  const futureAmount = Math.floor(totalPrice * (percentage / 100))
  const locale = getLocale()

  return isEnglishLocale(locale)
    ? getUserCurrencySign(currency) + futureAmount
    : futureAmount + " " + getUserCurrencySign(currency)
}

export const isLastOrderUnpaid = (orders): boolean => {
  if (!orders || orders.length === 0) {
    return false
  }

  return orders[0].payment_state !== "paid"
}

export const getWalletAmount = ({
  walletFunds,
  totalPrice,
  walletPercentageLimit,
}): number => {
  if (!walletFunds) {
    return 0
  }

  const limitedTotalPrice = Math.floor(totalPrice * walletPercentageLimit)

  return Math.min(walletFunds, limitedTotalPrice)
}

export const getUserAuthUrl = (
  type: "register" | "login" = "login"
): string => {
  const registerUrl = RegionConfig.getRegisterUrl()
  const loginUrl = RegionConfig.getSignInUrl()

  const url = type === "register" ? registerUrl : loginUrl
  const redirectUrl = window.location.href
  return appendRedirectParam(url, redirectUrl)
}
