import THREE from "../../../../libs/vendors/THREE"

import {
  GeometryConfig,
  isGroup,
  isMesh,
  SvgModelConfig,
  SvgSpaceConfig,
} from "../types"
import { rotateAboutPoint } from "../helpers/rotate-about-point"
import { ModelContext } from "../../../products-render-config/types"

export class FoldingManager {
  private steps: number = 0
  private percentage: number = 0
  private angles: Record<string, number> = {}

  constructor(
    private readonly model: THREE.Group,
    private readonly config: SvgModelConfig["folding"]
  ) {}

  public setSteps(steps: number): void {
    this.steps = steps
  }

  public getPercentage(): number {
    return this.percentage
  }

  public setPercentage(percentage: number): void {
    this.percentage = percentage

    this.fold(this.model)
  }

  public getTargetPercentage(modelContext: ModelContext): number {
    return this.config.target[modelContext] || 100
  }

  private fold(object: THREE.Object3D): void {
    object.children.forEach((child) => {
      if (isGroup(child)) {
        return this.fold(child)
      }

      const { folding } = child.userData

      if (!folding) {
        return this.fold(child)
      }

      if (!isMesh(child)) {
        return this.fold(child)
      }

      const geometry = child.geometry
      const boundingBox = geometry.boundingBox!

      const foldingStep = this.percentage / (100 / this.steps)
      let currentPercentage = foldingStep % 1

      if (folding.step <= foldingStep) {
        currentPercentage = 1
      }

      if (folding.step > Math.floor(foldingStep) + 1) {
        currentPercentage = 0
      }

      if (this.percentage === 100) {
        currentPercentage = 1
      }

      const currentAngle = folding.angle * currentPercentage
      const currentStepAngle = this.getAngle(child.name) - currentAngle
      this.setAngle(child.name, currentAngle)

      rotateAboutPoint(
        child,
        this.calculateRotationPoint(
          boundingBox,
          geometry.userData as GeometryConfig,
          folding
        ),
        folding.axis,
        (-currentStepAngle * Math.PI) / 180
      )

      this.fold(child)
    })
  }

  private calculateRotationPoint(
    boundingBox: THREE.Box3,
    geometryConfig: GeometryConfig,
    folding: SvgSpaceConfig["folding"]
  ): THREE.Vector3 {
    const rotationPoint = this.getRotationBoundingPoint(boundingBox, folding)
    const offset = this.calculateOffset(folding, geometryConfig.thickness)

    return new THREE.Vector3(
      rotationPoint.x,
      rotationPoint.y,
      rotationPoint.z + offset
    )
  }

  private calculateOffset(
    folding: SvgSpaceConfig["folding"],
    thickness: number
  ): number {
    if (Math.abs(folding.angle) === 180) {
      if (
        (folding.angle > 0 && folding.axis.y === 1) ||
        (folding.angle < 0 && folding.axis.x === 1)
      ) {
        return 0
      }

      return thickness
    }

    if (
      (folding.angle < 0 && folding.axis.y === 1) ||
      (folding.angle > 0 && folding.axis.x === 1)
    ) {
      return 0
    }

    const sign = folding.boundingPoint === "min" ? 1 : -1

    return thickness * sign
  }

  private getRotationBoundingPoint(
    boundingBox: THREE.Box3,
    folding: SvgSpaceConfig["folding"]
  ): THREE.Vector3 {
    const rotationPoint = boundingBox[folding.boundingPoint]

    if (rotationPoint) {
      return rotationPoint
    }

    if (
      (folding.angle > 0 && folding.axis.y === 1) ||
      (folding.angle < 0 && folding.axis.x === 1)
    ) {
      return boundingBox.max
    }

    return boundingBox.min
  }

  private setAngle(name: string, angle: number): void {
    this.angles[name] = angle
  }

  private getAngle(name: string): number {
    return this.angles[name] || 0
  }
}
