import React from "react"
import { FormattedHTMLMessage } from "react-intl"

import cxBinder from "classnames/bind"

import { useFormatMessage } from "../../../../../libs/others/i18n"
import {
  defaultLevelPercentage,
  getFormattedFuturePrice,
  getUserCurrency,
  getUserCurrencySign,
  isEnglishLocale,
} from "../../utils/wallet.helpers"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { WithoutMoneyWalletCardProps } from "../../utils/wallet.types"
import {
  walletI18n,
  walletMinimalStepDiscount,
} from "../../utils/wallet.translations"
import RegionConfig from "../../../../../../configs/region-config/interfaces/js/region-config"

import styles from "./WithoutMoneyWalletCard.module.scss"

const cx = cxBinder.bind(styles)

export const WithoutMoneyWalletCard = ({
  discount,
  totalPrice,
  isHorizontal,
}: WithoutMoneyWalletCardProps) => {
  const translate = useFormatMessage()
  const formattedAmount = getFormattedFuturePrice(
    defaultLevelPercentage,
    totalPrice,
    getUserCurrency()
  )

  const userCurrency = getUserCurrency()
  const currencySign = getUserCurrencySign(userCurrency)
  const locale = RegionConfig.getCurrentLocale()
  const currencySignFirst = isEnglishLocale(locale)
  return (
    <>
      <Typography type={TType.Header13_500}>
        {translate({ id: walletI18n.yourBalance })}
      </Typography>
      <div
        className={cx({
          isHorizontal,
        })}
      >
        <div className={styles["balance-wrapper"]}>
          <div className={styles["balance-details"]}>
            {currencySignFirst ? (
              <>
                <div className={styles["currency-position"]}>
                  <Typography type={TType.Header15_500}>
                    {currencySign}
                  </Typography>
                </div>

                <Typography type={TType.Header40_400}>0</Typography>
              </>
            ) : (
              <>
                <Typography type={TType.Header40_400}>0</Typography>
                <div className={styles["currency-position"]}>
                  <Typography type={TType.Header15_500}>
                    {currencySign}
                  </Typography>
                </div>
              </>
            )}
          </div>

          <div
            className={cx({
              "balance-content": !isHorizontal,
              "balance-content-horizontal": isHorizontal,
            })}
          >
            <div className={styles["connection-horizontal"]}>
              <div className={styles["without-money-wallet"]}>
                <Typography type={TType.Header32_400}>
                  <span className={styles["discount"]}>
                    {discount ? discount : defaultLevelPercentage}%
                  </span>
                </Typography>

                <Typography type={TType.Header15_500}>
                  {totalPrice ? (
                    <FormattedHTMLMessage
                      id={walletI18n.newTooltipCashback}
                      values={{
                        amount: formattedAmount,
                      }}
                    />
                  ) : (
                    translate({ id: walletI18n.tooltipCashback })
                  )}
                </Typography>

                <Typography
                  type={TType.Header15_500}
                  className={styles["program-name"]}
                >
                  {translate({ id: walletI18n.loyaltyProgram })}
                </Typography>
              </div>
            </div>
          </div>
        </div>

        <div
          className={cx({
            "benefits-horizontal": isHorizontal,
          })}
        >
          <Typography type={TType.Header13_500}>
            {translate({ id: walletI18n.yourBenefits })}
          </Typography>

          <div
            className={cx({
              "border-wrapper": !isHorizontal,
            })}
          >
            <div className={styles["benefits"]}>
              <div className={styles["check-mark"]} />
              <Typography
                type={TType.Header15_500}
                className={styles["separator"]}
              >
                {walletMinimalStepDiscount}
              </Typography>

              <Typography type={TType.Body15_350}>
                {translate({ id: walletI18n.tooltipCashback })}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
