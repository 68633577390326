import { EcommerceApi } from "../modules/ecommerce-api/types"

export class InvalidEnvConfig extends Error {}

export interface LocaleEnvConfig {
  region: string
  salesChannelId?: string
  productRegion: string
  country: string
  lang: string
  currency: string
  forcedTax: boolean
  urls: string | Record<string, string>
  displayUnit: "cm" | "in"
}

export interface DatadogEnvConfig {
  appId: string
  clientToken: string
  service: string
  sampleRate: number
  env: string
}

export interface SentryEnvConfig {
  organisation: string
  dsn: string
  environment: string
  release?: string
  instance: string
}

export interface GaEnvConfig {
  code: string
}

export interface SegmentEnvConfig {
  code: string
}

export interface ConfigCatEnvConfig {
  key: string
}

export interface IntegrationsEnvConfig {
  ga?: GaEnvConfig
  segment?: SegmentEnvConfig
  datadog?: DatadogEnvConfig
  sentry?: SentryEnvConfig
  configCat?: ConfigCatEnvConfig
}

export interface SocialAuthEnvConfig {
  facebook?: string
  google?: string
}

export interface DesignApiEnvConfig {
  baseUrl: string
}

export interface StrapiApiEnvConfig {
  baseUrl: string
}

export interface AssetsApiEnvConfig {
  baseUrl: string
  cdnBaseUrl: string
}

export interface PimApiEnvConfig {
  baseUrl: string
  token: string
}

export interface EcommerceApiEnvConfig {
  mode: "rest" | "injected"
  baseUrl?: string
  service?: EcommerceApi
  features: {
    auth: boolean
    user: {
      data: boolean
      logo: boolean
    }
    ecommerce: {
      basic: boolean
      dynamicQrCode: boolean
      fsc: boolean
      trees: boolean
      patterns: boolean
      templates: boolean
      productSwitcher: boolean
      customSizes: boolean
    }
  }
}

export interface BaseApiEnvConfig {
  baseUrl: string
}

export interface ApiEnvConfig {
  design: DesignApiEnvConfig
  ecommerce: EcommerceApiEnvConfig
  strapi: StrapiApiEnvConfig
  assets: AssetsApiEnvConfig
  pim: PimApiEnvConfig
  base: BaseApiEnvConfig
}

export interface BrandingEnvConfig {
  logo?: string
}

export interface EnvConfig {
  locale: LocaleEnvConfig
  api: ApiEnvConfig
  integrations?: IntegrationsEnvConfig
  socialAuth?: SocialAuthEnvConfig
  branding?: BrandingEnvConfig
  salesChannelId?: string
}
