import THREE from "../../../libs/vendors/THREE"
import { GeometryCreator } from "./services/geometry.creator"
import { createCanvas } from "./helpers/create-canvas"
import {
  ModelContext,
  TextureDefinition,
} from "../../products-render-config/types"
import { PivotManager } from "./services/pivot.manager"
import { Size, SvgModelConfig, SvgModelSettings } from "./types"
import { FoldingManager } from "./services/folding.manager"
import { loadImage } from "./helpers/load-image"
import { loadSvgModelConfig } from "./helpers/load-svg-model-config"

const vdSize = 1024
const maxModelSize = 8

export class SvgModel {
  private readonly textureCanvases: Record<string, HTMLCanvasElement>
  private vdCanvases: Record<string, HTMLCanvasElement> = {}
  private readonly modelTextures: Record<string, HTMLImageElement | undefined> =
    {}
  private readonly meshes: THREE.Mesh[] = []
  private readonly foldingManager: FoldingManager
  private readonly holder: THREE.Group

  private constructor(
    private readonly model: THREE.Group,
    private readonly size: Size,
    private readonly config: SvgModelConfig,
    private readonly settings: SvgModelSettings
  ) {
    this.textureCanvases = {
      outside: createCanvas(this.size),
      inside: createCanvas(this.size),
    }
    this.foldingManager = new FoldingManager(this.model, config.folding)

    this.holder = new THREE.Group()
    this.holder.add(this.model)
  }

  public static async loadFromUrl(
    url: string,
    settings: SvgModelSettings
  ): Promise<SvgModel> {
    return new Promise(async (resolve, reject) => {
      const loader = new THREE.SVGLoader()

      loader.load(
        url,
        async (data) => {
          const width = data.xml["attributes"].width.value
          const height = data.xml["attributes"].height.value
          const config = loadSvgModelConfig(data)

          const model = new THREE.Group()
          const svgModel = new SvgModel(
            model,
            { width, height },
            config,
            settings
          )
          const pivotManager = new PivotManager()
          let foldingSteps = 0

          for (const path of data.paths) {
            const spaceId = path.userData?.node.id
            const shapes = THREE.SVGLoader.createShapes(path)

            for (const shape of shapes) {
              const geometry = new GeometryCreator(shape).call({
                thickness: config.thickness,
              })
              const boundingBox = geometry.boundingBox!

              const textureOutside = new THREE.CanvasTexture(
                svgModel.textureCanvases.outside
              )
              textureOutside.encoding = THREE.sRGBEncoding
              textureOutside.magFilter = THREE.LinearFilter
              textureOutside.anisotropy = settings.anisotropy || 1
              textureOutside.wrapS = THREE.RepeatWrapping
              textureOutside.offset.x = boundingBox.min.x / -width
              textureOutside.offset.y = boundingBox.min.y / height
              textureOutside.repeat.set(
                (boundingBox.max.x - boundingBox.min.x) / -width,
                (boundingBox.max.y - boundingBox.min.y) / height
              )

              const materialOutside = new THREE.MeshPhongMaterial({
                name: "outside",
                map: textureOutside,
                side: THREE.FrontSide,
                polygonOffset:
                  spaceId.includes("flap") || spaceId.includes("front_inside"),
                polygonOffsetFactor: 1,
              })

              const materialSide = new THREE.MeshBasicMaterial({
                color: 0xa38a76,
                side: THREE.DoubleSide,
                polygonOffset: true,
                polygonOffsetFactor: 2,
              })

              const textureInside = new THREE.CanvasTexture(
                svgModel.textureCanvases.inside
              )
              textureInside.encoding = THREE.sRGBEncoding
              textureOutside.magFilter = THREE.LinearFilter
              textureOutside.anisotropy = settings.anisotropy || 1
              textureInside.offset.x = boundingBox.min.x / width
              textureInside.offset.y = boundingBox.min.y / height
              textureInside.repeat.set(
                (boundingBox.max.x - boundingBox.min.x) / width,
                (boundingBox.max.y - boundingBox.min.y) / height
              )

              const materialInside = new THREE.MeshPhongMaterial({
                name: "inside",
                map: textureInside,
                side: THREE.FrontSide,
              })

              const mesh = new THREE.Mesh(geometry, [
                materialOutside,
                materialInside,
                materialSide,
              ])
              mesh.name = spaceId
              mesh.castShadow = true
              mesh.add(pivotManager.getPivot(spaceId))

              const spaceConfig = config.spaces[spaceId] || {}
              const { parent: parentSpaceId } = spaceConfig
              mesh.userData = spaceConfig

              if (parentSpaceId) {
                pivotManager.addToPivot(parentSpaceId, mesh)
              } else {
                model.add(mesh)
              }

              foldingSteps = Math.max(
                foldingSteps,
                spaceConfig.folding?.step || 0
              )

              svgModel.meshes.push(mesh)
            }
          }

          svgModel.foldingManager.setSteps(foldingSteps)
          svgModel.setFoldingPercentage(100)
          svgModel.setDefaultModelScale()
          svgModel.setDefaultModelRotation()
          svgModel.centerObject(model)

          const box3 = new THREE.Box3().setFromObject(svgModel.model)
          const ground = new THREE.Mesh(
            new THREE.PlaneGeometry(100, 100),
            new THREE.MeshStandardMaterial({
              color: 0xffffff,
            })
          )
          ground.position.y = box3.min.y
          ground.rotation.x = -Math.PI / 2
          ground.receiveShadow = true
          svgModel.holder.add(ground)

          resolve(svgModel)
        },
        () => {},
        reject
      )
    })
  }

  public static async loadFromString(
    svg: string,
    settings: SvgModelSettings
  ): Promise<SvgModel> {
    return this.loadFromUrl(`data:image/svg+xml;base64,${btoa(svg)}`, settings)
  }

  public getModel(): THREE.Group {
    return this.holder
  }

  public getFoldingPercentage(): number {
    return this.foldingManager.getPercentage()
  }

  public setFoldingPercentage(percentage: number): void {
    this.foldingManager.setPercentage(percentage)
    this.centerObject(this.holder)
  }

  public getTargetFoldingPercentage(modelContext: ModelContext): number {
    return this.foldingManager.getTargetPercentage(modelContext)
  }

  public setVirtualDielineCanvases(
    vdCanvases: Record<string, HTMLCanvasElement>
  ): void {
    this.vdCanvases = vdCanvases
  }

  public async setModelTextures(
    definitions: TextureDefinition[]
  ): Promise<void> {
    await Promise.all(
      definitions.map(async (definition) => {
        const { path, type } = definition

        if (!path) {
          return
        }

        this.modelTextures[type] = await loadImage(path).catch(() => undefined)
      })
    )
  }

  public touchTextures(): void {
    for (const editContext of Object.keys(this.textureCanvases)) {
      const textureCanvas = this.textureCanvases[editContext]
      const vdCanvas = this.vdCanvases[editContext]
      const modelTexture = this.modelTextures[editContext]

      const ctx = textureCanvas.getContext("2d")

      if (!ctx) {
        return
      }

      const drawImage = (image: HTMLImageElement | HTMLCanvasElement) => {
        ctx.drawImage(
          image,
          (vdSize - textureCanvas.width) / 2,
          vdSize - textureCanvas.height,
          vdSize,
          vdSize
        )
      }

      ctx.save()

      ctx.fillStyle = "#ffffff"
      ctx.fillRect(0, 0, vdSize, vdSize)

      ctx.translate(vdSize / 2, vdSize / 2)
      ctx.rotate(Math.PI)
      ctx.translate(-vdSize / 2, -vdSize / 2)

      if (modelTexture) {
        drawImage(modelTexture)
      }

      if (vdCanvas) {
        ctx.globalAlpha = this.settings.blendOpacity || 1
        drawImage(vdCanvas)
        ctx.globalAlpha = 1
      }

      ctx.restore()
    }

    for (const mesh of this.meshes) {
      const materials = mesh.material as THREE.MeshPhongMaterial[]

      for (const material of materials) {
        if (!material.map) {
          continue
        }

        material.map.needsUpdate = true
      }
    }
  }

  public getScaleToFitScreen(): number {
    const box3 = new THREE.Box3().setFromObject(this.model)
    const size = box3.getSize(new THREE.Vector3())

    const maxSize = Math.max(size.x, size.y, size.z)
    const minRatio = Math.min(
      size.x / maxSize,
      size.y / maxSize,
      size.z / maxSize
    )

    const sizeDividend = maxModelSize - 3 * minRatio

    const scale = Math.min(
      sizeDividend / size.x,
      sizeDividend / size.y,
      sizeDividend / size.z
    )

    return scale
  }

  private setDefaultModelScale(): void {
    const scale = this.getScaleToFitScreen()

    this.model.scale.set(scale, scale, scale)
  }

  private setDefaultModelRotation(): void {
    const initialAngle = this.config.rotation.initial || 0

    this.model.rotation.x = Math.PI / -2
    this.model.rotation.z = (initialAngle * Math.PI) / 180
  }

  private centerObject(object: THREE.Object3D): void {
    const box3 = new THREE.Box3().setFromObject(object)
    const center = new THREE.Vector3()
    box3.getCenter(center)
    object.position.sub(center)
  }
}
