import { KeyboardControls } from "../libs/services/keyboard-controls"
import { GetContainerFormat } from "iti"
import { EditorToolbarStore } from "../stores/editor-toolbar-store"
import { EditorToolbarController } from "../stores/_controllers/editor-toolbar-controller"
import { FontsConfigStore } from "../stores/fonts-config-store/fonts-config.store"
import type { BootstrapClassicRootStoreContainer } from "./root-store-bootstrap-container"
import type { DesignAndProductDriverContainer } from "./design-and-product-driver-container"
import type { UtilEnvContainer } from "./util-container"
import { EditorModeController } from "../stores/_controllers/editor-mode.controller"
import { AfterPurchaseEditContainer } from "./after-purchase-edit-container"
import { EcommerceMaybeContainer } from "./ecommerce-maybe-container"
import { TemplatesMaybeContainer } from "./templates-maybe-container"
import { PatternMaybeContainer } from "./asset-pattern-maybe-container"
import { FscCertificateMaybeContainer } from "./fsc-certificate-maybe-container"

export async function provideUiContainer(
  utilEnvContainer: UtilEnvContainer,
  bootstrapClassicRootStore: BootstrapClassicRootStoreContainer,
  designAndProduct: DesignAndProductDriverContainer,
  ecommerceMaybeContainer: EcommerceMaybeContainer,
  afterPurchaseEditContainer: AfterPurchaseEditContainer,
  templatesContainer: TemplatesMaybeContainer,
  patternsContainer: PatternMaybeContainer,
  fscContainer: FscCertificateMaybeContainer
) {
  const { uri, appConfig } = utilEnvContainer
  const { productRenderPilot } = bootstrapClassicRootStore
  const { productDriver, productDesignStore } = designAndProduct
  const { cart } = ecommerceMaybeContainer
  const { afterPurchaseEditStore } = afterPurchaseEditContainer

  const keyboardControls = new KeyboardControls()
  const editorToolbarStore = new EditorToolbarStore(
    productRenderPilot.getEditorMode(),
    {
      templates: templatesContainer.available,
      patterns: patternsContainer.available,
      fsc: fscContainer.available,
      trees: appConfig.api.ecommerce.features.ecommerce.trees,
      productSwitcher:
        appConfig.api.ecommerce.features.ecommerce.productSwitcher,
    }
  )
  const editorToolbarController = new EditorToolbarController(
    editorToolbarStore,
    productDriver,
    productRenderPilot.uiConfig,
    uri
  )
  const fontsConfigStore = new FontsConfigStore()

  const editorModeController = new EditorModeController(
    productDriver.productStore,
    productDesignStore,
    uri,
    afterPurchaseEditStore.isAfterPurchaseEdit,
    cart
  )

  return {
    keyboardControls,
    editorToolbarStore,
    editorToolbarController,
    fontsConfigStore,
    editorModeController,
  }
}

export type UiContainer = GetContainerFormat<typeof provideUiContainer>
