import React, { useState, useEffect } from "react"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { useTranslate } from "../../../../../hooks/useTranslate"
import {
  CustomSizeConfig,
  CustomSizeDimensionConfig,
  Product,
} from "@ph/product-api"
import styles from "./SizeOption.module.scss"
import cxBinder from "classnames/bind"
import { TagIcon } from "../../../../atoms/TagIcon/TagIcon"
import { ReactComponent as IconChecked } from "../../../../../assets/svg/check.svg"
import TextField from "dsl/src/atoms/TextField/TextField"
import FormField from "../../../../molecues/form-field/FormField"
import { Button, ButtonSizes } from "dsl/src/atoms/Button"
import { ReactComponent as IconArrowUp } from "../../../../../assets/svg/ArrowUp.svg"
import { ReactComponent as IconArrowDown } from "../../../../../assets/svg/ArrowDown.svg"
import { I18N } from "../../../../../i18n"

const cx = cxBinder.bind(styles)

type CustomSize = Record<string, number>

export const CustomSizeOption = ({
  product,
  onSelect,
  isDisabled,
}: {
  product: Product
  onSelect: (size: CustomSize) => void
  isDisabled: boolean
}) => {
  const t = useTranslate()
  const variant = product.getDefaultVariant()
  const size = variant.size
  const unit = size.units.length
  const isSelected = !!size.isCustom

  const [customSize, setCustomSize] = useState<CustomSize>({})
  const [isFormVisible, setIsFormVisible] = useState(isSelected)

  useEffect(() => {
    if (isSelected) {
      const { width, height, length } = size

      setCustomSize({
        width,
        height,
        length,
      })
    } else {
      setCustomSize({})
      setIsFormVisible(false)
    }
  }, [size])

  const customSizeConfig = product.getCustomSizeConfig()

  if (!customSizeConfig) {
    return null
  }

  const onApply = () => {
    if (Object.values(customSize).length === 0) {
      return
    }

    onSelect(customSize)
  }

  const getValidationError = (
    config: CustomSizeDimensionConfig,
    validation: { min: boolean; max: boolean } = { min: true, max: true }
  ) => {
    if (!validation.min) {
      return `${t(I18N.generic.min)} ${config.min} ${unit}`
    }

    if (!validation.max) {
      return `${t(I18N.generic.max)} ${config.max} ${unit}`
    }
  }

  const sizeValidation = Object.fromEntries(
    Object.entries<CustomSizeDimensionConfig>(customSizeConfig.dimensions).map(
      ([key, config]) => {
        const { min, max } = config
        const value = customSize[key]

        return [
          key,
          {
            present: !!value,
            min: !value || value >= min,
            max: !value || value <= max,
          },
        ]
      }
    )
  )
  const isSizeIncorrect = Object.values(sizeValidation).some((validation) => {
    return !validation.present || !validation.min || !validation.max
  })
  const isSameSize = Object.entries(customSize).every(
    ([key, value]) => size?.[key] === value
  )
  const isButtonDisabled = isSizeIncorrect || isSameSize

  const valueToString = (value?: number): string => {
    if (!value) {
      return ""
    }

    return value.toString()
  }

  return (
    <div
      className={cx({
        size_option: true,
        "size_option--selected": isSelected,
        "size_option--disabled": isDisabled,
      })}
      onClick={() => !isFormVisible && setIsFormVisible(true)}
    >
      <div
        className={styles.size_option__details}
        onClick={() => setIsFormVisible(!isFormVisible)}
      >
        <div>
          {isFormVisible ? (
            <IconArrowUp className={styles.size_option__arrow} />
          ) : (
            <IconArrowDown className={styles.size_option__arrow} />
          )}
          <Typography type={TType.Header15_500} htmlElement="span">
            {t("generic.sizes.custom-size")}
          </Typography>
          <Typography
            type={TType.Body13_350}
            className={styles.size_option__label}
          >
            {t(I18N.generic.getQuoteMinQty, {
              qty: customSizeConfig.minQuantity,
            })}
          </Typography>
        </div>
        {isSelected ? (
          <TagIcon size="normal" Icon={IconChecked} />
        ) : (
          <span
            className={cx(
              styles.size_option__indicator,
              styles["size_option__indicator--unchecked"]
            )}
          />
        )}
      </div>
      {isFormVisible && (
        <div className={cx({ size_option__form: true })}>
          <div className={styles.size_option__form_row}>
            {Object.entries<CustomSizeDimensionConfig>(
              customSizeConfig.dimensions
            ).map(([key, config]) => {
              const error = getValidationError(config, sizeValidation[key])

              return (
                <FormField
                  field={
                    <TextField
                      type="number"
                      value={valueToString(customSize[key])}
                      suffix={unit}
                      onChange={(e) =>
                        setCustomSize({
                          ...customSize,
                          [key]: Number(e.target["value"]),
                        })
                      }
                      error={!!error}
                    ></TextField>
                  }
                  label={t(I18N.generic.dimensions[key])}
                  error={error}
                />
              )
            })}
          </div>
          <div className={styles.size_option__form_row}>
            <Button
              size={ButtonSizes.small}
              disabled={isButtonDisabled}
              onClick={onApply}
            >
              Apply
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
