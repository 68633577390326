import React from "react"
import { observer } from "mobx-react-lite"
import { isShippingCountryComingSoon } from "shared-libs/src/js/libs/helpers/region-config.helpers"
import { EditorHeaderAddToCart } from "./EditorHeaderAddToCart"
import { EditorHeaderDesignerModeActions } from "./EditorHeaderDesignerModeActions"
import { EditorHeaderFinishDesign } from "./EditorHeaderFinishDesign"
import { EditorHeaderDesignLater } from "./EditorHeaderDesignLater"
import styles from "./EditorHeaderProductActions.module.scss"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import { QuantityPicker } from "../../../dsl/organisms/QuantityPicker/QuantityPicker"
import { EditorHeaderMenuItemWallet } from "../../../dsl/organisms/EditorHeader/EditorHeaderMenu/EditorHeaderMenuItemWallet"
import { EditorHeaderGetQuote } from "./EditorHeaderGetQuote"

const ActionButtons = ({
  isAfterPurchaseEdit,
  isCartFlowAvailable,
  isDesignLaterAvailable,
  isEditorLoading,
  isDesignInCart,
  isAddingToCartLocked,
  isProductDesignSaving,
  addToCart,
}: {
  isAfterPurchaseEdit: boolean
  isCartFlowAvailable: boolean
  isDesignLaterAvailable: boolean
  isEditorLoading: boolean
  isDesignInCart: boolean
  isAddingToCartLocked: boolean
  isProductDesignSaving: boolean
  addToCart: () => void
}) => {
  if (isAfterPurchaseEdit) {
    return (
      <li>
        <EditorHeaderFinishDesign />
      </li>
    )
  }

  if (isCartFlowAvailable) {
    return (
      <>
        {isDesignLaterAvailable && (
          <li>
            <EditorHeaderDesignLater
              isDisabled={isEditorLoading || isDesignInCart}
            />
          </li>
        )}

        <li>
          <EditorHeaderAddToCart
            isAddingToCartLocked={isAddingToCartLocked || isDesignInCart}
            addToCart={addToCart}
          />
        </li>
      </>
    )
  }

  return (
    <li>
      <EditorHeaderGetQuote
        isDisabled={isEditorLoading || isProductDesignSaving}
        isProductDesignSaving={isProductDesignSaving}
      />
    </li>
  )
}

export const EditorHeaderProductActions = observer(() => {
  const [headerSet, headerSetErrors] = useContainerSet((c) => [
    c.apiSession,
    c.designAndProductDriver,
    c.ecommerce,
    c.dbyMode,
    c.envUtil,
  ])

  if (headerSet == null || headerSetErrors != null) return null
  const { apiSession, designAndProductDriver, ecommerce, dbyMode, envUtil } =
    headerSet

  const { productDesignStore, productDriver } = designAndProductDriver
  const { sessionStore } = apiSession
  const { isRendererLoading, productRenderPilot } = productDriver.state
  const isAnyAssetOverscaled = productDriver.assetsDriver.isAnyAssetOverscaled
  const { isDesignInCart, isProductDesignSaving, isDraftDesignInCart } =
    productDesignStore.state
  const sku = productDriver.productStore.productSku
  const { uri } = envUtil
  const isAfterPurchaseEdit = productRenderPilot.isAfterPurchaseEdit

  if (!sku || uri.isDtpPreviewMode()) {
    return null
  }

  if (uri.isDesignerMode()) {
    return <EditorHeaderDesignerModeActions />
  }

  const isDataReadOnly = productDesignStore.state.meta.dataReadOnly
  const { isDesignLocked } = productDesignStore

  const addToCart = async () => {
    await ecommerce.cartStore?.triggerAddingLineItemToCart({
      shouldLockDesign: !isDesignLocked,
    })
  }

  const { activeObjectDriver } = designAndProductDriver.productDriver
  const { activeObjectType } = activeObjectDriver
  const showQtyPickerAndWallet =
    !activeObjectType && !isShippingCountryComingSoon()

  const readonly = isDesignInCart || isDataReadOnly
  const isEditorLoading = isRendererLoading || isProductDesignSaving
  const isDbyMode = productRenderPilot.isDbyMode()
  const isDbyModeFileNotUploaded = !dbyMode.dbyModeStore.uploadedFile
  const isDbyModeFileUploadFailed = !!dbyMode.dbyModeStore.uploadError
  const isAddingToCartLocked =
    isEditorLoading ||
    (!readonly && isAnyAssetOverscaled) ||
    (isDbyMode && (isDbyModeFileNotUploaded || isDbyModeFileUploadFailed))
  const isDesignLaterAvailable = !readonly && !isDraftDesignInCart
  const isUserAnonymous = !sessionStore.user?.email
  const isCartFlowAvailable =
    !!ecommerce?.productPricingStore?.getCurrentProductTotalPrice

  return (
    <ul className={styles.editor_header__menu}>
      {showQtyPickerAndWallet && !isAfterPurchaseEdit && (
        <>
          {!isUserAnonymous && (
            <li>
              <EditorHeaderMenuItemWallet />
            </li>
          )}
          <li>
            <QuantityPicker />
          </li>
        </>
      )}

      <ActionButtons
        addToCart={addToCart}
        isProductDesignSaving={isProductDesignSaving}
        isAddingToCartLocked={isAddingToCartLocked}
        isAfterPurchaseEdit={isAfterPurchaseEdit}
        isCartFlowAvailable={isCartFlowAvailable}
        isDesignInCart={isDesignInCart}
        isDesignLaterAvailable={isDesignLaterAvailable}
        isEditorLoading={isEditorLoading}
      />
    </ul>
  )
})
