import React, { useState, PropsWithChildren } from "react"
import { observer } from "mobx-react-lite"
import { LinkAsButton } from "dsl/src/atoms/Link/Link"
import { Select } from "dsl/src/atoms/Select/Select"
import { SelectSizes } from "dsl/src/atoms/Select/types"

import { Pattern, PatternColours } from "../../../../libs/value-objects/pattern"
import { CategoryPattern } from "../../../../stores/_controllers/patterns-controller"
import { ColoursPreset } from "../../../../libs/products-render-config/types"
import { ThumbXL } from "../../atoms/ThumbXL/ThumbXL"
import { useTranslate } from "../../../hooks/useTranslate"
import { PatternConfiguratorHeader } from "./PatternsConfiguratorHeader"
import { VirtualizedPatternTile } from "./PatternTile"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import type { PatternsStore } from "../../../../stores/patterns-store/patterns.store"

import styles from "./PatternsConfigurator.module.scss"

type PatternsConfiguratorProps = {
  currentPattern: Pattern | undefined
  onPatternSelected: (pattern: Pattern) => void
  removePatternFromDesign: () => void
  onColoursSelect: (coloursMap: PatternColours) => void
  onPatternApproved: () => void
  setPatternsDefaultColours: () => void
  setPatternsInitialColours: () => void
  onPatternReject: () => void
  coloursPreset: ColoursPreset
  arePatternsTouched: boolean
  patternCategories: CategoryPattern[]
  selectedPatternCategory: string | null
  onSelect: (category: string | null) => void
  patternsStore: PatternsStore
  getCategoryTranslationKey: (id: string) => string
}

const I18N = {
  noPatternLabel: "component.editor-tool-style.background.no-pattern",
  allCategories: "design-showcase.patterns.category.all-categories",
  chooseCategory: "design-showcase.patterns.category.choose-category",

  // see all
  // Back to All
}

const PatternGrid = ({ children }: PropsWithChildren<{}>) => (
  <div className={styles["pattern_category_patterns"]}>{children}</div>
)

const PatternCategorySection = (
  p: PropsWithChildren<{
    categoryName: React.ReactNode
    linkText: React.ReactNode
    onLinkClick: () => void
  }>
) => (
  <div className={styles["pattern_category"]}>
    <div className={styles["pattern_category_header"]}>
      <span className={styles["pattern_category_name"]}>{p.categoryName}</span>
      <LinkAsButton onClick={p.onLinkClick}>{p.linkText}</LinkAsButton>
    </div>
    {p.children}
  </div>
)

const PATTERNS_PER_CATEGORY = 7
const PatternsConfigurator = ({
  currentPattern,
  onPatternSelected,
  onColoursSelect,
  onPatternApproved,
  onPatternReject,
  removePatternFromDesign,
  setPatternsDefaultColours,
  setPatternsInitialColours,
  coloursPreset,
  arePatternsTouched,
  patternCategories,
  selectedPatternCategory,
  onSelect: setSelectedPatternCategory,
  patternsStore,
  getCategoryTranslationKey,
}: PatternsConfiguratorProps) => {
  const t = useTranslate()
  // const [touched, setTouched] = useState(false)

  // Project data to <Select/> format
  const patternCategoriesForSelect = patternCategories.map(
    (category, index) => ({
      id: category.name,
      index,
      key: category.name,
      name: t(getCategoryTranslationKey(category.name)),
    })
  )
  if (selectedPatternCategory) {
    patternCategoriesForSelect.unshift({
      id: "none",
      index: -1,
      key: "none",
      name: t("design-showcase.patterns.category.all-categories"),
    })
  }
  const currentPatternCategoryForSelect = patternCategoriesForSelect.find(
    (category) => category.id === selectedPatternCategory
  )

  // Project and filter categories and pattern tiles to show
  const patternCategoriesToShow = selectedPatternCategory
    ? patternCategories.filter(
        (category) => category.name === selectedPatternCategory
      )
    : patternCategories

  function getPatternsWithLimit(patterns: Pattern[]) {
    if (!selectedPatternCategory) {
      return patterns.slice(0, PATTERNS_PER_CATEGORY)
    } else {
      return patterns
    }
  }

  return (
    <>
      <PatternConfiguratorHeader
        arePatternsTouched={arePatternsTouched}
        currentPattern={currentPattern}
        setPatternsDefaultColours={setPatternsDefaultColours}
        setPatternsInitialColours={setPatternsInitialColours}
        onColoursSelect={onColoursSelect}
        onPatternApproved={onPatternApproved}
        onPatternReject={onPatternReject}
        coloursPreset={coloursPreset}
      />

      <Select
        placeholder={t("design-showcase.patterns.category.choose-category")}
        handleSelectedItemChange={(item) => {
          if (item.id === "none") {
            setSelectedPatternCategory(null)
          } else {
            setSelectedPatternCategory(item.id)
          }
        }}
        items={patternCategoriesForSelect}
        selectedItem={currentPatternCategoryForSelect}
        size={SelectSizes.medium}
        withMenuFluid
      />
      <div className={styles.categories_section}>
        {patternCategoriesToShow.map((category) => (
          <PatternCategorySection
            key={category.name}
            categoryName={t(getCategoryTranslationKey(category.name))}
            linkText={
              selectedPatternCategory
                ? t(I18N.allCategories)
                : t(I18N.chooseCategory)
            }
            onLinkClick={() => {
              if (selectedPatternCategory) {
                setSelectedPatternCategory(null)
              } else {
                setSelectedPatternCategory(category.name)
              }
            }}
          >
            <PatternGrid>
              <ThumbXL
                isPlaceholderActive={true}
                isSelected={!currentPattern}
                label={t(I18N.noPatternLabel)}
                onClick={removePatternFromDesign}
                e2eTarget="pattern"
                e2eTargetName="no-pattern"
              />
              {getPatternsWithLimit(category.patterns).map((pattern) => (
                <VirtualizedPatternTile
                  coloursPreset={coloursPreset}
                  isSelected={currentPattern === pattern}
                  key={pattern.id}
                  onPatternClick={() => {
                    onPatternSelected(pattern)
                  }}
                  pattern={pattern}
                  patternsStore={patternsStore}
                />
              ))}
            </PatternGrid>
          </PatternCategorySection>
        ))}
      </div>
    </>
  )
}

const PatternsConfiguratorWithState = observer(() => {
  const [patternCategory, setSelectedPatternCategory] = useState<string | null>(
    null
  )
  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.patterns,
  ])

  if (!containerSet) {
    return null
  }

  const { productDriver } = containerSet.designAndProductDriver
  const patternsContainer = containerSet.patterns

  if (patternsContainer.available !== true) return null

  const coloursPreset =
    productDriver.state.productRenderPilot.getColoursPreset()

  const onPatternSelected = (pattern: Pattern) => {
    patternsContainer.patternController.applyPattern(pattern)
  }

  const onPaintPatternsWithColours = (patternColour: PatternColours) => {
    patternsContainer.patternsStore.paintPatternsWithColours(patternColour)
  }

  const onPatternApproved = () => {
    patternsContainer.patternsStore.onPatternApproved()
  }

  const onPatternReject = () => {
    patternsContainer.patternsStore.onPatternReject()
  }

  const removePatternFromDesign = () => {
    patternsContainer.patternController.removePattern()
  }

  const setPatternsDefaultColours = () => {
    patternsContainer.patternsStore.setPatternsDefaultColours()
  }

  const setPatternsInitialColours = () => {
    patternsContainer.patternsStore.setOriginalPatterns()
  }

  const patternCategories =
    patternsContainer.patternController.getPatternCategories()

  return (
    <PatternsConfigurator
      arePatternsTouched={patternsContainer.patternsStore.arePatternsTouched}
      currentPattern={patternsContainer.patternsStore.activePattern}
      setPatternsDefaultColours={setPatternsDefaultColours}
      setPatternsInitialColours={setPatternsInitialColours}
      removePatternFromDesign={removePatternFromDesign}
      onColoursSelect={onPaintPatternsWithColours}
      onPatternApproved={onPatternApproved}
      onPatternReject={onPatternReject}
      onPatternSelected={onPatternSelected}
      coloursPreset={coloursPreset}
      patternCategories={patternCategories}
      selectedPatternCategory={patternCategory}
      onSelect={setSelectedPatternCategory}
      patternsStore={patternsContainer.patternsStore}
      getCategoryTranslationKey={
        patternsContainer.patternController.getCategoryTranslationKey
      }
    />
  )
})

export default React.memo(PatternsConfiguratorWithState)
