import EuRegion from "./eu"
import * as shared from "../shared/shared-consts"

const Region = {
  ...EuRegion,

  languageTag: "sv-SE",
  iterableLocale: "sv-SE",
  locale: "sv",
  phoneCountryCode: "+40",
  defaultShippingCountryCode: "SW",
  mainDomain: shared.SE_MAIN_DOMAIN,

  secondaryAnalyticsCode: "UA-71580330-31",
  productRegion: "se",
  countryCode: "se",
  emailAddress: "hello@packhelp.com",
  emailSenderName: "Packhelp.com",
  phoneNumber: "",

  logoSVGIcon: "v3-packhelp_color_on_white",
  logoSVGIconWhite: "v3-packhelp_white_logo_on_black",

  additionalCustoms: 0,

  pageTitle: "Editor - Packhelp.se",
  footerLabel: "Packhelp",
  frontendBoxDesignRoute: "box/design",
  frontendAccountOrdersRoute: "account/orders",

  frontendTermsOfServiceRoute: "/terms_of_service/", // english is on purpose
  frontendPrivacyPolicyRoute: "/privacy_policy/", // english is on purpose

  domain: {
    basename: "packhelp",
    tld: "com",
    pathBased: true,
    pathRegion: "/sv-se",
  },
  externalLinks: {
    affiliates: "/bli-en-affiliate/",
    suppliers: "/leverantor/",
    fscCertification: "/forpackningar-certifierade-med-fsc/",
    helpPlantTrees: "/hjalp-plantera-trad/",
    ecoProperties: "/miljoegenskaper/",
    sustainability: "/hallbarhet/",
    designShowcase: "/design-monter/",
    inspirationsHandbook: "/bli-en-inspiration/",
    mobileAppLandingPageUrl: "/mobilapp/",
    brief: "/briefing/",
    artworkTipsDielines: "/dielines-tips/",
    help: "/hjalp/",
    thankYou: "/tack/",
    designGuidelines: "/hjalp/tema/redigerare/",
    about: "/om-oss/",
    career: "/karriarer/",
    shop: "/webbshop/",
    services: "/services/",
    industries: "/i/",
    bundles: "/produktset",
    inspirations: "/forpackningsinspiration/",
    impressum: "/impressum/",
    returnPolicy: "/hjalp/amne/kan-jag-returnera-de-bestallda-boxarna/",
    blog: "/blogg/",
    contact: "/kontakt/",
    holidays: "/semester/",
    privacyPolicy: "/privacy_policy/",
    termsOfService: "/terms_of_service/",
    termsOfServicePlus: "/terms-of-services-plus/",
    accountSignOut: "/sign_out/",
    accountSignIn: "/sign_in/",
    accountRegister: "/registration",
    accountOrders: "/account/orders",
    accountOrderDetails: "/account/orders/:orderNumber",
    accountSettings: "/account/settings",
    accountWallet: "/account/packhelp-wallet",
    accountBoxes: "/account/boxes",
    accountSupportReforestation: "/account/support-reforestation",
    accountCart: "/cart/",
    samples: "/sample-packs",
    customOffer: "offer/:token/review",
    quoteRequest: "account/quote_requests/:qrNumber",
    cart: "/cart/",
    orderDetails: "/order",
    crossSell: "/cart/recommended-products-and-services/s",
    fullAndEcoColorHelpSite:
      "/skillnaden-mellan-enkel-fargad-postbox-och-miljovanlig-fargad-postbox/",
    safeZonesHelpSite: "/hjalp/amne/sakra-kanter/",
    samplePacksLink:
      "/box/design?sku=box-eu--samples--samples--samples--samples--samples&dielineMode=false",
    termsAcceptedSite: "/villkor-accepterade/",
    paymentSummary: "/order/:orderId/payment/summary",
    packhelpWallet: "/packhelp-wallet",
    ecoBadge: "/packhelpos-miljomarke/",
    leadership: "/ledarskap/",
    press: "/press/",
    urlWhitelistedToBeFetchedFromUrl: [
      "/order/:orderNumber/payment",
      "/order/:orderNumber/details",
    ],
    products: {
      "mailer-box-plain": "/enkel-brevbox/",
      "mailer-box-eco": "/miljovanlig-brevbox/",
      "mailer-box-eco-white": "/miljovanlig-brevbox/",
      "mailer-box-full-color": "/brevbox-i-fullfarg/",
      "mailer-box-eco-color": "/miljovanliga-brevboxar-i-farg/",
      "delivery-mailer-box": "/leveransbox-e-handel/",
      "delivery-mailer-box-plain": "/enkel-leveransbox/",
      "shipping-box-plain": "/enkel-fraktbox/",
      "shipping-box": "/fraktboxar/",
      "product-box": "/klassik-produktbox/",
      "product-box-two-pieces": "/tvadelad-produktbox/",
      "poly-mailer": "/klassiskt-postorderpase/",
      "paper-mailer": "/expanderbart-kraft-kuvert/",
      "paper-bag": "/anpassade-trycka-papperspasar-med-handtag/",
      "rigid-envelope": "/anpassade-kuvert-i-wellpapp/",
      "product-box-wine-box": "/anpassade-vinboxar/",
      "tissue-paper": "/custom-tissue-paper/",
      "packing-paper": "/packpapper/",
      "packing-tape": "/packtejp/",
      "sleeved-mailer-box": "/brevbox-med-omslag/",
      "kraft-tape": "/nastri-adesivi-kraft/",
      "bio-poly-mailer": "/miljovanlig-postorderpase/",
      "paper-can": "/tryckta-pappburkar/",
      "mailer-box-white-ink": "/vitt-pa-kraft-brevbox/",
      "mailer-box-full-color-double-sided": "/dubbelsidig-postkartong/",
      "rigid-box": "/presentkartong-med-lock/",
      "rigid-box-two-pieces": "/box-i-tva-delar/",
      "special-box": "/scatola-speciale-su-misura/", // no links in CMS
      "magnetic-box": "/magnetbox/",
      "carrier-box": "/lada-med-handtag/",
      "rigid-drawer-box": "/utdragbar-box-i-kartong/",
      "cake-box": "/tartkartong/",
      "bio-poly-mailer-plain": "/fortryckta-miljovanliga-postorderpasar/",
      "pre-printed-mailer-box-eco": "/fortryckt-miljovanlig-brevbox/",
      "pre-printed-mailer-box-full-colour": "/fortryck-brevbox-i-fullfarg/",
      "doypack-bag": "/standup-pase/",
      "square-label": "/tryckta-etiketter/",
      "tote-bag": "/tryckt-tygpase/",
      "printed-card": "/tackkort/",
      "pre-printed-kraft-tape": "/fortryckt-krafttejp/",
      "recycled-poly-mailer": "/atervunna-polyetenpasar/",
    },
    categories: {
      "mailer-boxes": "/brevboxar/",
      "custom-shipping-boxes": "/anpassade-fraktboxar/",
      "primary-packaging": "/produktboxar/",
      "eco-friendly-packaging":
        "/webbshop/?miljöegenskaper=atervunna-material/",
      "luxury-packaging": "/rigida-boxar/",
    },
    fb_url: shared.EU_MAIN_DOMAIN,
    fb_sitename: "Packhelp",
    fb_image: "/share_image_1200x630.png",
    share_img: "/share_image_120x120.png", // Editor
    boxEditorPath: "/box/design",
    productEditor: "/editor/product/design",
    exampleDesign: "79485?language=se&templateMode=true",
    productQuotationSystem: "/quote",
    pqsSuccessPage: "/quote/success",
    plus: {
      catalogue: "/plus/catalogue/",
      successPage: "/plus/success",
      items: "/packhelp-plus/items",
      quotes: "/packhelp-plus/quotes",
      orders: "/packhelp-plus/orders",
      quoteShow: "/packhelp-plus/quotes/:token",
      orderShow: "/packhelp-plus/orders/:orderNumber/details",
      orderPlaced: "/packhelp-plus/orders/:orderNumber/placed",
      orderItems: "/packhelp-plus/orders/:orderNumber/items",
      orderPaymentSummary: "/packhelp-plus/orders/:orderNumber/payment-summary",
      personalDetails: "/packhelp-plus/personal-details",
    },
    pro: {
      features: "pro/features/",
      suppliers: "pro/for-suppliers/",
      termsOfService: "pro/terms-conditions/",
    },
    industriesListing: "/i/",
    whiteLabel: "/enterprise/",
  },

  ...shared.SHARED_ECOMMERCE_PROPERTIES.eu,
  ...shared.SHARED_CURRENCY_PROPERTIES.sek,
  allowedPaymentMethods: ["check", "stripe", "card"],

  remindMeOnDesktop: {
    remindme_form_url:
      "//packhelp.us12.list-manage.com/subscribe/post-json?u=2060c1de14ec30d28a9fab813&amp;id=489822501e&c=?",
    remindme_hidden_input_name: "b_2060c1de14ec30d28a9fab813_489822501e",
  },

  datesFormattingRules: shared.DATES_FORMATTING_RULES_EU,

  googleConversionId: "859901857",
  googleAnalytics: {
    primaryCode: "UA-71580330-2",
    secondaryCode: "UA-71580330-31",
    segmentCode: "uXDomf9GW2BCffFseKvLrarxNiGNLI5O",
  },
  googleAdWords: {
    remarketingCode: "39198",
    conversionCode: "859901857",
  },

  facebookAppId: "1660833110841347",
  supportsPlus: false,
}

export default Region
