import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { Button } from "dsl/src/atoms/Button/Button"
import { useSetChatPosition } from "shared-libs/src/js/libs/services/live-chat/use-set-chat-position"
import { DownloadOrPrintModal } from "../../../dsl/organisms/Modal/download-or-print/DownloadOrPrintModal"
import { i18n } from "../../../dsl/organisms/Modal/download-or-print/utils/download-or-print.translations"
import { observer } from "mobx-react-lite"
import { Loader, LoaderColors, LoaderTypes } from "dsl/src/atoms/Loader/Loader"
import styles from "./EditorHeaderAddToCart.module.scss"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"

type EditorHeaderAddToCartProps = {
  isAddingToCartLocked: boolean
  addToCart: () => void
}
export const EditorHeaderAddToCart = observer(
  ({ isAddingToCartLocked, addToCart }: EditorHeaderAddToCartProps) => {
    const { setChatPosition } = useSetChatPosition()
    const [isDownloadOrPrintModalOpen, setDownloadOrPrintModal] =
      useState<boolean>(false)

    useEffect(() => {
      isDownloadOrPrintModalOpen
        ? setChatPosition(["0px", "400px"])
        : setChatPosition()

      return () => {
        setChatPosition()
      }
    }, [isDownloadOrPrintModalOpen, setChatPosition])

    const [containerSet] = useContainerSet((c) => [
      c.designAndProductDriver,
      c.ecommerce,
      c.analytics,
    ])

    if (!containerSet || !containerSet.ecommerce.available) {
      return null
    }

    const { analytics } = containerSet
    const { productDesignStore } = containerSet.designAndProductDriver

    const { isProductDesignSaving } = productDesignStore.state
    const toggleDownloadOrPrintModal = () => {
      analytics.analyticsController?.trackSeePriceClicked()
      setDownloadOrPrintModal(!isDownloadOrPrintModalOpen)
      analytics.analyticsController?.trackReviewAndPurchaseButtonClicked()
    }

    return (
      <>
        <Button
          disabled={isAddingToCartLocked}
          onClick={toggleDownloadOrPrintModal}
          e2eTargetName="review-and-purchase"
        >
          {isProductDesignSaving ? (
            <>
              <div className={styles.loader_wrapper}>
                <Loader type={LoaderTypes.circular} color={LoaderColors.gray} />
              </div>
              <FormattedMessage id={i18n.saving} />
            </>
          ) : (
            <FormattedMessage id={i18n.review_and_purchase} />
          )}
        </Button>
        {isDownloadOrPrintModalOpen && (
          <DownloadOrPrintModal
            onClose={toggleDownloadOrPrintModal}
            submitAction={addToCart}
          />
        )}
      </>
    )
  }
)
