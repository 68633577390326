import cxBinder from "classnames/bind"
import scrollIntoView from "scroll-into-view-if-needed"
import Downshift from "downshift"
import defaultMatchSorter from "match-sorter"
import React, { useRef } from "react"
import Typography, { TType } from "../Typography/Typography"
import ControllerButton from "./partials/ControllerButton"
import Menu from "./partials/Menu"
import styles from "./Select.module.scss"
import {
  SelectBaseProps,
  SelectDirection,
  SelectSizes,
  BaseMenuProps
} from "./types"

const cx = cxBinder.bind(styles)

const SelectBase = (props: SelectBaseProps) => {
  const {
    size,
    renderItem,
    renderMenuHeader,
    renderMenuFooter,
    renderSearch,
    items,
    disabled,
    autocomplete,
    shouldSort,
    selectedItem,
    handleSelectedItemChange,
    handleIsOpenChange,
    inputValue,
    handleInputValueChange,
    isOpen,
    onOpenMenuCallback,
    searchPlaceholder,
    disableNoResultsIndicator,
    placeholder,
    validation,
    matchSorter,
    onSortItems,
    stateReducer,
    direction,
    hideInput,
    withMenuFluid,
    withMobileMenuFluid,
    autoWidth,
    searchSelect,
    e2eTarget,
    e2eTargetName,
    itemsListWrapperId,
    inputValueBasedItemIndex,
    hideMenu,
    id,
    withPulsatingGlow = false,
    withError = false,
    noBackground = false,
    ...other
  } = props

  const searchRef: any = useRef(null)
  const menuRef: any = useRef(null)

  const getItems = (filter: any) => {
    const sortItems = () => {
      const sortedItems = matchSorter
        ? matchSorter(items, filter)
        : defaultMatchSorter(items, filter, {
            keys: ["name"]
          })
      onSortItems && onSortItems(sortedItems)
      return sortedItems
    }
    return filter && shouldSort ? sortItems() : items
  }

  const controlledProps = {
    selectedItem: props && props.hasOwnProperty("selectedItem"),
    isOpen: props && props.hasOwnProperty("isOpen")
  }

  const renderMenu = (props: BaseMenuProps) => {
    const {
      items,
      getMenuProps,
      highlightedIndex,
      getItemProps,
      inputValue,
      selectedItem,
      isOpen: open
    } = props

    const menuProps = getMenuProps({
      open,
      itemsListWrapperId,
      inputValueBasedItemIndex
    })

    return (
      <Menu
        {...menuProps}
        autocomplete={autocomplete}
        disabled={disabled}
        isOpen={open}
        items={items}
        renderItem={renderItem}
        highlightedIndex={highlightedIndex}
        selectedItem={selectedItem}
        getItemProps={getItemProps}
        disableNoResultsIndicator={disableNoResultsIndicator}
        inputValue={inputValue}
        renderMenuHeader={renderMenuHeader}
        renderMenuFooter={renderMenuFooter}
        direction={direction}
        withMenuFluid={withMenuFluid}
        withMobileMenuFluid={withMobileMenuFluid}
        autoWidth={autoWidth}
        menuRef={menuRef}
        hideMenu={hideMenu}
      />
    )
  }

  const downShiftProps: any = {
    itemToString: (item: any) => (item ? item.name : ""),
    stateReducer,
    defaultHighlightedIndex: 1,
    onStateChange(changes: any) {
      if (changes.selectedItem && changes.selectedItem.disabled) {
        return
      }

      if (handleSelectedItemChange && changes.hasOwnProperty("selectedItem")) {
        const lastId = selectedItem ? selectedItem.id : null
        const newId = changes.selectedItem ? changes.selectedItem.id : null
        if (lastId !== newId) {
          searchRef.current &&
            searchRef.current.blur &&
            searchRef.current.blur()
          handleSelectedItemChange(changes.selectedItem)
        }
      }

      if (
        handleIsOpenChange &&
        changes.hasOwnProperty("isOpen") &&
        isOpen !== changes.isOpen
      ) {
        handleIsOpenChange(changes.isOpen)

        if (!changes.isOpen && searchRef && searchRef.current) {
          searchRef.current.blur()
        }
      }

      if (changes.isOpen && searchRef && searchRef.current) {
        setTimeout(() => {
          if (searchSelect) {
            searchRef.current.setSelectionRange(0, 9999)
          }
        })
      }
    }
  }

  if (controlledProps.selectedItem) {
    downShiftProps.selectedItem = selectedItem ? selectedItem : null /// if we reset value to falsy it has to be null
  }

  if (controlledProps.isOpen) {
    downShiftProps.isOpen = isOpen
  }

  downShiftProps.onInputValueChange = handleInputValueChange
  downShiftProps.inputValue = inputValue + ""
  downShiftProps.defaultHighlightedIndex = -1

  return (
    <Downshift {...downShiftProps}>
      {(props) => {
        const {
          inputValue,
          getInputProps,
          getMenuProps,
          getItemProps,
          getToggleButtonProps,
          selectedItem,
          highlightedIndex,
          isOpen,
          toggleMenu
        } = props

        const isError = validation && !validation(inputValue, selectedItem)
        const items = getItems(inputValue)
        const paramsForMenu = {
          items,
          getMenuProps,
          getInputProps,
          highlightedIndex,
          getItemProps,
          inputValue,
          selectedItem,
          isOpen
        }

        const handleMenuToggle = () => {
          if (!disabled) {
            toggleMenu()

            if (!isOpen && onOpenMenuCallback) {
              onOpenMenuCallback()
            }
          }
        }

        const focusMenu = () => {
          const search = searchRef && searchRef.current
          setTimeout(() => {
            !isOpen &&
              search &&
              search.focus() &&
              search.setSelectionRange(0, 9999)
          })
        }

        return (
          <div
            className={cx("base", {
              "is-opened": isOpen,
              "is-error": isError,
              "is-disabled": disabled
            })}
            e2e-target={e2eTarget}
            e2e-target-name={e2eTargetName}
            {...other}
          >
            {direction == SelectDirection.up && renderMenu(paramsForMenu)}
            {!renderSearch && (
              <input
                className={styles["focusable-element"]}
                type="text"
                {...getInputProps()}
                id={id}
              />
            )}
            <div
              className={styles.placeholder}
              onClick={renderSearch ? focusMenu : handleMenuToggle}
            >
              {renderSearch &&
                renderSearch(
                  searchRef,
                  disabled,
                  getInputProps,
                  toggleMenu,
                  focusMenu,
                  isOpen
                )}

              {!hideInput && (
                <>
                  {!renderSearch && (
                    <div
                      className={cx("input", "input--fake", {
                        "input--xsmall": size === SelectSizes.xsmall,
                        "input--small": size === SelectSizes.small,
                        "input--medium": size === SelectSizes.medium,
                        "input--large": size === SelectSizes.large,
                        "input--with-pulsating-glow": withPulsatingGlow,
                        "input--with-error": withError
                      })}
                    >
                      <div
                        className={cx("item", "item--placeholder", {
                          "no-background": noBackground
                        })}
                      >
                        {selectedItem && renderItem ? (
                          renderItem(selectedItem, true)
                        ) : (
                          <Typography type={TType.Body15_350}>
                            {placeholder ? placeholder : "Select"}
                          </Typography>
                        )}
                      </div>
                    </div>
                  )}

                  {!disabled && (
                    <ControllerButton
                      {...getToggleButtonProps()}
                      selectedItem={selectedItem}
                      onClick={() => {
                        if (isOpen && !disabled) {
                          toggleMenu({ isOpen: !isOpen })
                        }
                      }}
                    />
                  )}
                </>
              )}
            </div>

            {direction != SelectDirection.up && renderMenu(paramsForMenu)}
          </div>
        )
      }}
    </Downshift>
  )
}

export { SelectBase, SelectBase as default }
