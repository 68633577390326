var SkuMaterial;
(function (SkuMaterial) {
    SkuMaterial["SKU_MATERIAL_WHITE_ONE_SIDE_CARDBOARD"] = "cardboard-white-one-side";
    SkuMaterial["SKU_MATERIAL_WHITE_TWO_SIDES_CARDBOARD"] = "cardboard-white-two-sides";
    SkuMaterial["SKU_MATERIAL_WHITE_ONE_SIDE_NATURAL_CARDBOARD"] = "cardboard-natural-white-one-side";
    SkuMaterial["SKU_MATERIAL_WHITE_CARDBOARD"] = "cardboard-white";
    SkuMaterial["SKU_MATERIAL_COATED_CARDBOARD"] = "cardboard-coated";
    SkuMaterial["SKU_MATERIAL_COATED_CARDBOARD_WHITE_INSIDE"] = "cardboard-coated-white-inside";
    SkuMaterial["SKU_MATERIAL_NATURAL_CARDBOARD_WHITE_INSIDE"] = "cardboard-natural-white-inside";
    SkuMaterial["SKU_MATERIAL_NATURAL_CARDBOARD"] = "cardboard-natural";
    SkuMaterial["SKU_MATERIAL_CARDSTOCK"] = "cardboard-cardstock";
})(SkuMaterial || (SkuMaterial = {}));
export default SkuMaterial;
