import React, { useState } from "react"
import Picker, { PickerProps } from "../Picker/Picker"
import { I18N } from "../../../i18n"
import { useTranslate } from "../../../hooks/useTranslate"
import { PantoneColorsPreset } from "../../../../libs/products-render-config/types"
import PickerColorPalette from "dsl/src/organisms/PickerColorPalette/PickerColorPalette"
import { ReactComponent as IconArrowUp } from "../../../assets/svg/ArrowUp.svg"
import cxBinder from "classnames/bind"
import styles from "../ColourPicker/ColourPicker.module.scss"
import { PantoneColorsDatabase } from "../../../../modules/colors/pantone-colors-database"
import PantoneColorsGroupingProvider from "./PantoneColorsGroupingProvider"
import { PantonePickerColorObject } from "dsl/src/organisms/PickerColor/PickerColorTypes"
import {
  PantonePickerComponent,
  PantonePickerComponentProps,
} from "./PantonePickerComponent"

const cx = cxBinder.bind(styles)
const i18n = I18N.component.colorPicker.pantone

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>
type PickerWithoutLinkClick = Omit<PickerProps, "onLinkClick">

type ComponentWithoutStringsAndColorsProvider = Omit<
  PantonePickerComponentProps,
  "noSearchResultsString" | "placeholderString" | "colorsProvider"
>

export interface PantonePickerProps
  extends ComponentWithoutStringsAndColorsProvider,
    PickerWithoutLinkClick {
  searchPlaceholderString?: string
  noSearchResultsString?: (searchValue: string) => string
  pantoneColorsPreset?: PantoneColorsPreset
}

function getColorsProvider(pantoneColorsPreset: PantoneColorsPreset): {
  groupingProvider?: PantoneColorsGroupingProvider
  colorsList?: PantonePickerColorObject[]
} {
  const pantoneDb = new PantoneColorsDatabase()

  if (
    [
      PantoneColorsPreset.COVERING_BLACK_FOIL,
      PantoneColorsPreset.TISSUE_BACKGROUND,
    ].includes(pantoneColorsPreset)
  ) {
    return {
      colorsList: pantoneDb.getByPreset(pantoneColorsPreset),
    }
  }

  return {
    groupingProvider: new PantoneColorsGroupingProvider(
      pantoneDb.getByPreset(pantoneColorsPreset)
    ),
  }
}

function PantonePicker({
  selectedColor,
  onApplyColor,
  onBackClick,
  onDoneClick,
  noSearchResultsString,
  searchPlaceholderString,
  pantoneColorsPreset = PantoneColorsPreset.ALL,
  ...other
}: PantonePickerProps) {
  const { groupingProvider, colorsList } =
    getColorsProvider(pantoneColorsPreset)
  const [moreColorsVisible, showMoreColors] = useState<boolean>(false)

  const onFooterLinkClick = (e) => {
    e.preventDefault()
    showMoreColors(!moreColorsVisible)
  }

  const t = useTranslate()

  const defaultColorsList =
    colorsList || groupingProvider?.groups.Defaults || []

  return (
    <Picker
      onBackClick={onBackClick}
      onDoneClick={onDoneClick}
      onLinkClick={onFooterLinkClick}
      linkText={
        moreColorsVisible
          ? t("component.colour-picker.less-colours")
          : t("component.colour-picker.more-colours")
      }
      Icon={IconArrowUp}
      {...other}
    >
      {!moreColorsVisible ? (
        <div className={cx({ wrapper: true, wrapper_narrow: other.isInline })}>
          <PickerColorPalette
            collapsed
            selectedColor={selectedColor?.color}
            onColorSelect={onApplyColor}
            isNarrow={other.isInline}
            colorsList={defaultColorsList}
          />
        </div>
      ) : (
        <PantonePickerComponent
          selectedColor={selectedColor}
          onApplyColor={onApplyColor}
          placeholderString={
            searchPlaceholderString ? searchPlaceholderString : t(i18n.search)
          }
          noSearchResultsString={
            noSearchResultsString
              ? noSearchResultsString
              : (searchValue) => `${t(i18n.noResults)} ${searchValue}`
          }
          colorsList={colorsList}
          groupingProvider={groupingProvider}
        />
      )}
    </Picker>
  )
}

export default PantonePicker
