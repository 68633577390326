import React from "react"

import {
  Notification,
  NotificationTypes,
} from "dsl/src/molecules/Notification/Notification"
import { useFormatMessage } from "../../../../../libs/others/i18n"
import { isLastOrderUnpaid } from "../../utils/wallet.helpers"
import { WaitingForPaymentNotificationProps } from "../../utils/wallet.types"
import { walletI18n } from "../../utils/wallet.translations"

import styles from "./WaitingForPaymentNotification.module.scss"

export const WaitingForPaymentNotification = ({
  orders,
}: WaitingForPaymentNotificationProps) => {
  const translate = useFormatMessage()

  const showWaitingForPaymentNotification = orders
    ? isLastOrderUnpaid(orders)
    : false

  if (!showWaitingForPaymentNotification) {
    return null
  }

  return (
    <div className={styles.notification}>
      <Notification type={NotificationTypes.Warning}>
        {translate({ id: walletI18n.waitingForYourPaymentToCalculate })}
      </Notification>
    </div>
  )
}
