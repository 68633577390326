import { EditContext } from "../../libs/products-render-config/types"
import { Pattern } from "../../libs/value-objects/pattern"

// Events
export enum PatternEvents {
  configChanged = "ph.pattern.config.changed",
  configChangeHandled = "ph.pattern.config.changeHandled",
  applied = "ph.pattern.applied",
  removed = "ph.pattern.removed",
  refreshColor = "ph.pattern.refreshColor",
}

export type PatternsEventsTypes = {
  [PatternEvents.configChanged]: (patterns: Pattern[]) => void
  [PatternEvents.configChangeHandled]: () => void
  [PatternEvents.applied]: (pattern: Pattern, editContext?: EditContext) => void
  [PatternEvents.removed]: () => void
  [PatternEvents.refreshColor]: () => void
}
