var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import _each from "lodash/each";
import _mapValues from "lodash/mapValues";
import { buildSku, getSkuData, translateSkuPartToAttributeKey, } from "../../../SkuLib";
import BOX_CONSTS from "../../../../../source/boxProductSpec/generalConfig/consts/boxPartsDefinitions/PrintMode";
var i18nPrintOn = new Map([
    [
        BOX_CONSTS.PRINT_MODE_COLOR_ECO_WITH_PRINT_INSIDE,
        "box-attribute.material.print-inside",
    ],
    [
        BOX_CONSTS.PRINT_MODE_MONOCHROME_WHITE_WITH_PRINT_INSIDE,
        "box-attribute.material.print-inside",
    ],
    [
        BOX_CONSTS.PRINT_MODE_COLOR_ECO,
        "box-attribute.print-quality.printColorECO",
    ],
    [
        BOX_CONSTS.PRINT_MODE_MONOCHROME_WHITE,
        "box-product.name.mailer-box-white-ink",
    ],
    [BOX_CONSTS.PRINT_MODE_MONOCHROME, "product.mailerBoxEco.name"],
    [
        BOX_CONSTS.PRINT_MODE_MONOCHROME_WITH_PRINT_INSIDE,
        "box-attribute.material.print-inside",
    ],
]);
var addAddonsBehaviors = function (superclass) {
    var addonsTypeMap = {
        colorModes: "colorMode",
        materials: "material",
        finishes: "finish",
    };
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.product = product;
            _this.productName = _this.product.variantManager.getName();
            _this.productVariant = _this.product.variantManager.getVariant();
            _this.productVariantStructure =
                _this.product.variantManager.getVariantStructure();
            _this.addonsArray =
                _this.product.variantManager.getModelConfig().addonsInVariants;
            _this.prepareAddonsManager();
            _this.prepareProduct(_this.product.variantManager.getSku());
            return _this;
        }
        class_1.prototype.prepareAddonsManager = function () {
            this.prepareDefaulProductSpec();
            this.prepareCurrentProductSpec();
            this.prepareProductVariantDescription();
            this.prepareAddonsAssets();
        };
        class_1.prototype.prepareAddonsAssets = function () {
            this.texturesConsts = this.product.assetsManager.getAddonsImagePaths();
            this.addonIcons = this.product.assetsManager.getAddonsSvgIconNames();
        };
        class_1.prototype.prepareDefaulProductSpec = function () {
            this.default = {
                material: this.getDefaultMaterial(),
                finish: this.getDefaultFinish(),
                colorMode: this.getDefaultColorMode(),
            };
        };
        class_1.prototype.prepareProductVariantDescription = function () {
            this.productVariantDescription = [
                this.productVariantStructure.materials,
                this.productVariantStructure.colorModes,
                this.productVariantStructure.finishes,
            ];
        };
        class_1.prototype.prepareCurrentProductSpec = function () {
            var _a = this.product.config.skuData.decodedSKU, productMaterial = _a.productMaterial, productFinish = _a.productFinish, productColorMode = _a.productColorMode;
            this.material = productMaterial;
            this.finish = productFinish;
            this.colorMode = productColorMode;
        };
        class_1.prototype.getAddonsPickerDefaultItem = function () {
            return {
                addonType: "none",
                id: "noAddons",
                isActive: false,
                name: "box-attribute.addons.no-addons",
            };
        };
        class_1.prototype.getMaterial = function () {
            return this.material;
        };
        class_1.prototype.getFinish = function () {
            return this.finish;
        };
        class_1.prototype.getColorMode = function () {
            return this.colorMode;
        };
        class_1.prototype.getCurrentAddon = function (addonType) {
            return this[addonType];
        };
        class_1.prototype.getDefaultAddon = function (addonType) {
            return this.default[addonType];
        };
        class_1.prototype.getCurrentAddonIfNotDefault = function (addonType) {
            var defaultAddonForType = this.getDefaultAddon(addonType);
            var currentAddonForType = this.getCurrentAddon(addonType);
            return currentAddonForType !== defaultAddonForType
                ? currentAddonForType
                : undefined;
        };
        class_1.prototype.getProductAddonType = function (addonId) {
            var addonsList = this.productVariantStructure;
            var addonType = null;
            _each(addonsList, function (addons, key) {
                _each(addons, function (addon) {
                    if (addonId === addon) {
                        addonType = addonsTypeMap[key];
                    }
                });
            });
            return addonType;
        };
        class_1.prototype.getDefaultColorMode = function () {
            return this.productVariantStructure.colorModes[0];
        };
        class_1.prototype.getDefaultMaterial = function () {
            return this.productVariantStructure.materials[0];
        };
        class_1.prototype.getDefaultFinish = function () {
            return this.productVariantStructure.finishes[0];
        };
        class_1.prototype.getAvailableMaterials = function (withAddons) {
            var _this = this;
            var materialsList = this.productVariantStructure.materials;
            var currentMaterial = this.product.variantManager.getMaterial();
            var availableMaterials = [];
            _each(materialsList, function (material) {
                availableMaterials.push({
                    id: material,
                    addonType: "material",
                    name: "box-configurator.material-switcher.material.".concat(material, ".name"),
                    isActive: material === currentMaterial,
                    sku: _this.product.variantManager.buildSku({ material: material }),
                });
            });
            return availableMaterials;
        };
        class_1.prototype.getAvailableFinishes = function () {
            var _this = this;
            var finishesList = this.productVariantStructure.finishes;
            var currentFinish = this.product.variantManager.getFinish();
            return finishesList.map(function (finish) {
                return {
                    id: finish,
                    addonType: "finish",
                    name: "box-configurator.material-switcher.material.".concat(finish, ".name"),
                    isActive: finish === currentFinish,
                    pic: _this.texturesConsts && _this.texturesConsts[finish],
                    sku: _this.product.variantManager.buildSku({ finish: finish }),
                };
            });
        };
        class_1.prototype.isPrintInsideAvailable = function () {
            return false;
        };
        class_1.prototype.isPrintInsideActive = function () {
            return false;
        };
        class_1.prototype.getAvailableAddons = function () {
            return this.product.variantManager.getModelConfig().addonsInVariants;
        };
        class_1.prototype.getActiveAddons = function () {
            return this.getAvailableAddons().filter(function (addon) { return addon.isActive; });
        };
        class_1.prototype.getAvailableColorModes = function () {
            var _this = this;
            var colorModesList = this.productVariantStructure.colorModes;
            var currentColorMode = this.product.variantManager.getColorMode();
            var availableColorModes = [];
            _each(colorModesList, function (colorMode) {
                availableColorModes.push({
                    id: colorMode,
                    addonType: "colorMode",
                    name: i18nPrintOn.get(colorMode),
                    isActive: colorMode === currentColorMode,
                    sku: _this.product.variantManager.buildSku({ colorMode: colorMode }),
                });
            });
            return availableColorModes;
        };
        class_1.prototype.getCurrentAddonsSet = function () {
            return [this.material, this.colorMode, this.finish];
        };
        class_1.prototype.getAddonType = function (addonId) {
            var addonsList = this.productVariantStructure;
            var addonType = null;
            _each(addonsList, function (addons, key) {
                _each(addons, function (addon) {
                    if (addonId === addon) {
                        addonType = addonsTypeMap[key];
                    }
                });
            });
            return addonType;
        };
        class_1.prototype.setDefaultAddons = function () {
            var defaultAddons = {
                material: this.getDefaultMaterial(),
                finish: this.getDefaultFinish(),
                colorMode: this.getDefaultColorMode(),
            };
            return this.buildNewSku(defaultAddons);
        };
        class_1.prototype.buildSkuWithOption = function (addonId) {
            return this.setAddon(addonId);
        };
        class_1.prototype.setAddon = function (addonId) {
            var _a;
            var addonType = this.getAddonType(addonId); // addon type can represent material, colorMode or finish
            if (this.getCurrentAddon(addonType) === addonId) {
                addonId = this.getDefaultAddon(addonType);
            }
            return this.buildNewSku((_a = {}, _a[addonType] = addonId, _a));
        };
        class_1.prototype.setSkuParams = function (_a) {
            var materialId = _a.materialId, colorModeId = _a.colorModeId, finishId = _a.finishId;
            return this.buildNewSku({
                colorMode: colorModeId,
                material: materialId,
                finish: finishId,
            });
        };
        class_1.prototype.setColorMode = function (colorModeId) {
            return this.buildNewSku({ colorMode: colorModeId });
        };
        class_1.prototype.setMaterial = function (materialId) {
            return this.buildNewSku({ material: materialId });
        };
        class_1.prototype.setFinish = function (finishId) {
            return this.buildNewSku({ finish: finishId });
        };
        class_1.prototype.setProductSize = function (size) {
            return this.buildNewSku({ productSize: size });
        };
        class_1.prototype.convertSkuData = function (sku) {
            return _mapValues(getSkuData(sku), translateSkuPartToAttributeKey);
        };
        class_1.prototype.prepareProduct = function (sku) {
            var skuData = this.convertSkuData(sku);
            var productSize = skuData.productSize, productName = skuData.productName, productRegion = skuData.productRegion;
            this.productRegion = productRegion;
            this.productName = productName;
            this.productSize = productSize;
            return skuData;
        };
        class_1.prototype.buildNewSku = function (skuParamsToSet) {
            var material = skuParamsToSet.material, colorMode = skuParamsToSet.colorMode, productSize = skuParamsToSet.productSize, finish = skuParamsToSet.finish;
            var skuData = {
                productRegion: this.productRegion,
                productName: this.productName,
                productSize: productSize || this.productSize,
                productMaterial: material || this.getMaterial(),
                productColorMode: colorMode || this.getColorMode(),
                productFinish: finish || this.getFinish(),
            };
            return buildSku(skuData);
        };
        class_1.prototype.getAvailableConfigurablePropertiesGroupedByType = function () {
            var assets = this.product.assetsManager.getEditorImagePaths();
            var materials = this.getAvailableMaterials(true);
            var finishes = this.getAvailableFinishes();
            if (finishes.length === 1) {
                finishes = finishes.filter(function (finish) { return !["none", "foilNone"].includes(finish.id); });
            }
            var mapProperty = function (property, type) { return (__assign(__assign({}, property), { title: "product.".concat(type, ".").concat(property.id, ".name"), imageUrl: assets[property.id] })); };
            return [
                {
                    title: "component.editor-tool-materials.title.material",
                    type: "materials",
                    properties: materials.map(function (material) {
                        return mapProperty(material, "material");
                    }),
                },
                {
                    title: "component.editor-tool-materials.title.finishes",
                    type: "finishes",
                    properties: finishes.map(function (finish) { return mapProperty(finish, "finish"); }),
                },
            ];
        };
        class_1.prototype.getActiveProperties = function () {
            return [];
        };
        class_1.prototype.getPrintCoverageProperty = function () {
            return undefined;
        };
        return class_1;
    }(superclass));
};
export default addAddonsBehaviors;
