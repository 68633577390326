import THREE from "../../../../libs/vendors/THREE"
import {
  BoundingPoint,
  SerializedSvgModelConfig,
  SerializedSvgSpaceConfig,
  SvgModelConfig,
} from "../types"
import { configs } from "../default-configs/list"

export function loadSvgModelConfig(data: {
  paths: THREE.ShapePath[]
  xml: any
}): SvgModelConfig {
  const parsedConfig = getConfig(data)
  const config = {
    thickness: parsedConfig?.thickness || 0,
    rotation: parsedConfig?.rotation || {},
    folding: {
      target: parsedConfig?.folding?.target || {},
    },
    spaces: {},
  }

  if (parsedConfig.spaces) {
    for (const [
      spaceId,
      spaceConfig,
    ] of Object.entries<SerializedSvgSpaceConfig>(parsedConfig.spaces)) {
      const foldingConfig = spaceConfig["folding"] || {}

      config.spaces[spaceId] = {
        parent: spaceConfig?.parent,
        folding: {
          ...foldingConfig,
          axis: parseAxis(foldingConfig.axis),
          boundingPoint: parseBoundingPoint(foldingConfig.boundingPoint),
        },
      }
    }
  }

  return config
}

function getConfig(data: {
  paths: THREE.ShapePath[]
  xml: any
}): SerializedSvgModelConfig {
  const parsedConfig = parseConfigFromSvgData(
    data.xml["attributes"]["data-model-config"]?.value
  )

  if (parsedConfig) {
    return parsedConfig
  }

  return getDefaultConfig(data.paths.map((path) => path["userData"]?.node.id))
}

function getDefaultConfig(spaces: string[]): any {
  let defaultConfig
  let bestMatchCount = 0

  for (const config of Object.values(configs)) {
    const matchCount = spaces.filter((space) => config.spaces[space]).length

    if (matchCount === spaces.length) {
      return config
    }

    if (matchCount > bestMatchCount) {
      bestMatchCount = matchCount
      defaultConfig = config
    }
  }

  return defaultConfig
}

function parseAxis(axis?: string): THREE.Vector3 {
  if (axis === "y") {
    return new THREE.Vector3(0, 1, 0)
  }

  if (axis === "z") {
    return new THREE.Vector3(0, 0, 1)
  }

  return new THREE.Vector3(1, 0, 0)
}

function parseBoundingPoint(point = "auto"): BoundingPoint {
  if (!["auto", "max", "min"].includes(point)) {
    return "auto"
  }

  return point as BoundingPoint
}

function parseConfigFromSvgData(
  config?: string
): SerializedSvgModelConfig | undefined {
  if (!config) {
    return
  }

  try {
    return JSON.parse(config)
  } catch (e: any) {
    window.Sentry?.captureException(
      new Error(`Could not load model config from SVG: ${e.message}`)
    )

    return
  }
}
