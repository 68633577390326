import React from "react"
import { observer } from "mobx-react-lite"

import { QuantityPicker } from "../../../QuantityPicker/QuantityPicker"
import { i18n } from "../utils/download-or-print.translations"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { TM } from "../../../../../TypographyI18n"
import { DetailsItem } from "./DetailsItem"
import { FormattedCurrency } from "shared-libs/src/js/shared-components/formatted-currency/FormattedCurrency"
import { PaymentAmountPresenter } from "shared-libs/src/js/shared-components/payments/PaymentAmountPresenter"
import { useCurrency } from "../../../../../hooks/useCurrency"
import { isShippingCountryComingSoon } from "shared-libs/src/js/libs/helpers/region-config.helpers"
import { WalletPanel } from "shared-libs/src/js/shared-views/loyalty/wallet/components/wallet-panel/WalletPanel"
import { Decoration } from "dsl/src/components/OutliningPanel/OutliningPanel"

import { ReactComponent as MeasureIcon } from "../../../../../assets/svg/measure.svg"
import { ReactComponent as MaterialIcon } from "../../../../../assets/svg/materials.svg"
import { ReactComponent as PatternIcon } from "../../../../../assets/svg/patterns.svg"
import { ReactComponent as TemplateIcon } from "../../../../../assets/svg/star.svg"
import { ReactComponent as FscIcon } from "../../../../../assets/svg/fsc.svg"

import styles from "./DetailsPanel.module.scss"
import { getWalletAmount } from "shared-libs/src/js/shared-views/loyalty/wallet/utils/wallet.helpers"
import { useContainerSet } from "../../../../../../_containers-react/_editor-app-hooks"
import { FscCertificateMaybeContainer } from "../../../../../../_containers/fsc-certificate-maybe-container"
import { TaxStore } from "../../../../../../stores/pricing-store/tax.store"
import { PatternMaybeContainer } from "../../../../../../_containers/asset-pattern-maybe-container"
import { TemplatesStore } from "../../../../../../stores/templates-store/templates.store"
import { ProductRenderPilot } from "../../../../../../libs/products-render-config/product-render-pilot"
import {
  EditorMode,
  Product,
} from "../../../../../../libs/products-render-config/types"
import { Pattern } from "../../../../../../libs/value-objects/pattern"
import { TemplateDesignItem } from "../../../../../../modules/predefined-assets/template-asset"
import { AddFscFormBlock } from "../../../AddFscFormBlock/AddFscFormBlock"
import { EditorToolbarItem } from "../../../../../../stores/editor-toolbar-store"
import { FscCertificateStore } from "../../../../../../stores/certificates-store/fsc-certificate.store"
import { FscCertificateUiController } from "../../../../../../stores/_controllers/fsc-certificate-ui.controller"
import { EventLocation } from "shared-libs/src/js/libs/analytics/segment/segment-analytics.consts"
import { ProductDesignStore } from "../../../../../../stores/product-design-store/product-design.store"

const getFscDetailsIfNeeded = ({
  editorMode,
  isDesignReadOnly,
  fscCertificate,
  productDesignStore,
  taxStore,
  currency,
  onAddToDesign,
}: {
  editorMode: EditorMode
  isDesignReadOnly: boolean
  fscCertificate?: FscCertificateMaybeContainer
  productDesignStore: ProductDesignStore
  taxStore: TaxStore
  currency: string
  onAddToDesign: () => void
}): JSX.Element | undefined => {
  if (
    isDesignReadOnly ||
    !fscCertificate?.available ||
    !fscCertificate.fscCertificateStore.fscCertificate
  ) {
    return
  }

  return (
    <FscDetails
      editorMode={editorMode}
      fscCertificateStore={fscCertificate.fscCertificateStore}
      fscCertificateUiController={fscCertificate.fscCertificateUiController}
      productDesignStore={productDesignStore}
      taxStore={taxStore}
      currency={currency}
      onAddToDesign={onAddToDesign}
    />
  )
}

const FscDetails = ({
  editorMode,
  fscCertificateStore,
  fscCertificateUiController,
  productDesignStore,
  taxStore,
  currency,
  onAddToDesign,
}: {
  editorMode: EditorMode
  fscCertificateStore: FscCertificateStore
  fscCertificateUiController: FscCertificateUiController
  productDesignStore: ProductDesignStore
  taxStore: TaxStore
  currency: string
  onAddToDesign: () => void
}) => {
  if (!fscCertificateStore.fscCertificate) {
    return null
  }

  const price = taxStore.getPrice(
    fscCertificateStore.fscCertificate,
    productDesignStore.state.meta.quantity
  ).displayPrice

  if (
    !fscCertificateStore.isSelected ||
    !fscCertificateUiController.isFscCertificateShapeAddedOnDieline()
  ) {
    return (
      <AddFscFormBlock
        isChecked={fscCertificateStore.isSelected}
        isDisabled={fscCertificateStore.isLoadingSingleShape}
        onChange={async () => {
          await fscCertificateUiController.toggleFscCertificate(
            EventLocation.EditorReview
          )
        }}
        price={price}
        onButtonClick={
          fscCertificateStore.isSelected && editorMode === "editor"
            ? onAddToDesign
            : undefined
        }
        learnMoreUrl={fscCertificateUiController.getFscCertificationUrl()}
      />
    )
  }

  return (
    <DetailsItem
      title={i18n.fsc}
      value={
        <Typography type={TType.Body13_350}>
          <FormattedCurrency value={price} currency={currency} />
        </Typography>
      }
      icon={<FscIcon />}
    />
  )
}

const getPatternDetailsIfNeeded = ({
  patterns,
  taxStore,
  currency,
}: {
  patterns?: PatternMaybeContainer
  taxStore: TaxStore
  currency: string
}): JSX.Element | undefined => {
  if (!patterns?.available) {
    return
  }
  const selectedPattern = patterns.patternsStore.activePattern

  if (!selectedPattern) {
    return
  }

  return (
    <PatternDetails
      currency={currency}
      taxStore={taxStore}
      pattern={selectedPattern}
    />
  )
}

const PatternDetails = ({
  pattern,
  taxStore,
  currency,
}: {
  pattern: Pattern
  taxStore: TaxStore
  currency: string
}) => {
  const price = taxStore.getPrice(pattern).displayPrice

  return (
    <DetailsItem
      title={i18n.pattern}
      value={
        <Typography type={TType.Body13_350}>
          {pattern.name}
          {" ("}
          <FormattedCurrency value={price} currency={currency} />
          {")"}
        </Typography>
      }
      icon={<PatternIcon />}
      withBorder
    />
  )
}

const getTemplateDetailsIfNeeded = ({
  templatesStore,
  taxStore,
  currency,
}: {
  templatesStore?: TemplatesStore
  taxStore: TaxStore
  currency: string
}): JSX.Element | undefined => {
  if (!templatesStore?.selectedTemplate) {
    return
  }

  return (
    <TemplateDetails
      template={templatesStore.selectedTemplate}
      taxStore={taxStore}
      currency={currency}
    />
  )
}

const TemplateDetails = ({
  template,
  taxStore,
  currency,
}: {
  template: TemplateDesignItem
  taxStore: TaxStore
  currency: string
}) => {
  const price = taxStore.getPrice(template).displayPrice

  return (
    <DetailsItem
      title={i18n.template}
      value={
        <Typography type={TType.Body13_350}>
          {template.name}
          {" ("}
          <FormattedCurrency value={price} currency={currency} />
          {")"}
        </Typography>
      }
      icon={<TemplateIcon />}
    />
  )
}

const getFinishDetailsIfNeeded = ({
  productRenderPilot,
  product,
}: {
  productRenderPilot: ProductRenderPilot
  product: Product
}): JSX.Element | undefined => {
  if (product.addonsManager.getAvailableFinishes().length <= 1) {
    return
  }

  const description = productRenderPilot.getFullProductDescription()
  const finishTitle = description.finishTitleKey || i18n.foil
  const finishKey = description.finishKey

  if (!finishKey) {
    return
  }

  return (
    <FinishDetails
      finishTitle={finishTitle}
      product={product}
      finishKey={finishKey}
    />
  )
}

const FinishDetails = ({
  product,
  finishTitle,
  finishKey,
}: {
  product: Product
  finishTitle: string
  finishKey?: string
}) => {
  if (product.addonsManager.getAvailableFinishes().length <= 1 || !finishKey) {
    return null
  }

  return (
    <DetailsItem
      title={finishTitle}
      value={<TM id={finishKey} type={TType.Body13_350} />}
      icon={<MaterialIcon />}
      withBorder
    />
  )
}

export const DetailsPanelPrint = observer(
  ({ onClose }: { onClose: () => void }) => {
    const currency = useCurrency()
    const [containerSet] = useContainerSet((c) => [
      c.patterns,
      c.fscCertificate,
      c.ui,
      c.templates,
      c.designAndProductDriver,
      c.ecommerce,
    ])

    if (!containerSet) {
      return null
    }

    const {
      patterns,
      fscCertificate,
      ui,
      templates,
      designAndProductDriver,
      ecommerce,
    } = containerSet

    if (!ecommerce.available) {
      return null
    }

    const { productDriver, productDesignStore } = designAndProductDriver
    const { productRenderPilot } = productDriver.state
    const { templatesStore } = templates
    const { taxStore, productPricingStore, cartStore } = ecommerce

    const product = productRenderPilot.getProduct()
    const editorMode = productRenderPilot.getEditorMode()

    if (!product) {
      return null
    }

    const patternDetails = getPatternDetailsIfNeeded({
      patterns,
      taxStore,
      currency,
    })

    const fscDetails = getFscDetailsIfNeeded({
      editorMode,
      isDesignReadOnly: productDesignStore.isDesignReadOnly,
      fscCertificate,
      taxStore,
      productDesignStore,
      currency,
      onAddToDesign: () => {
        ui?.editorToolbarStore.selectTab(EditorToolbarItem.fsc)
        onClose()
      },
    })
    const templateDetails = getTemplateDetailsIfNeeded({
      templatesStore,
      taxStore,
      currency,
    })
    const finishDetails = getFinishDetailsIfNeeded({
      productRenderPilot,
      product,
    })

    const description = productRenderPilot.getFullProductDescription()

    const {
      wallet_amount: walletFunds,
      wallet_percentage_limit: walletPercentageLimit,
    } = cartStore.cart!
    const totalPrice = productPricingStore.getCurrentProductTotalPrice
    const walletAmount = getWalletAmount({
      totalPrice,
      walletFunds,
      walletPercentageLimit,
    })

    const totalLabel = taxStore.taxConfig.hasTax
      ? i18n.total_with_tax
      : i18n.total

    return (
      <div className={styles.details_panel_print}>
        <div className={styles.container}>
          <TM
            id={i18n.size_and_materials}
            type={TType.Header15_500}
            htmlElement="span"
          />
          <DetailsItem
            title={i18n.size}
            value={
              <Typography type={TType.Body13_350}>
                {description.size}
              </Typography>
            }
            icon={<MeasureIcon />}
          />
          <DetailsItem
            title={i18n.material}
            value={<TM id={description.materialKey} type={TType.Body13_350} />}
            icon={<MaterialIcon />}
            withBorder
          />
        </div>
        <div className={styles.container}>
          {(finishDetails ||
            patternDetails ||
            templateDetails ||
            fscDetails) && (
            <TM id={i18n.addons} type={TType.Header15_500} htmlElement="span" />
          )}
          {finishDetails}
          {patternDetails}
          {templateDetails}
          {fscDetails}
          {cartStore.cart && Boolean(cartStore.cart.wallet_amount) && (
            <WalletPanel
              amount={walletAmount}
              currency={cartStore.cart.currency}
              panelDecoration={Decoration.Orange}
              percentageLimit={cartStore.cart.wallet_percentage_limit}
              iconColor="black"
            />
          )}
          <div className={styles.size}>
            <TM
              id={i18n.quantity_and_price}
              type={TType.Header15_500}
              htmlElement="span"
            />

            {taxStore.taxConfig.hasTax && (
              <span>
                &nbsp;(
                <TM
                  id={i18n.vat_rate_included}
                  values={{ rate: taxStore.getTaxRate() }}
                  type={TType.Body15_350}
                  htmlElement="span"
                />
                )
              </span>
            )}

            <QuantityPicker />
            <PaymentAmountPresenter
              currency={currency}
              amount={totalPrice}
              labelId={totalLabel}
            />
            {isShippingCountryComingSoon() && (
              <div className={styles.shipping_notification}>
                <Typography type={TType.Body13_350}>
                  Shipping not included
                </Typography>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
)
