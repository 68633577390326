import React from "react"
import cxBinder from "classnames/bind"
import styles from "./Card.module.scss"

const cx = cxBinder.bind(styles)

export enum CardType {
  Round = "round",
  Round16 = "round16",
  Square = "square",
  Overlay = "overlay",
  Clear = "clear",
  RoundedTop = "rounded-top",
  BorderTop = "border-top"
}

export enum HeightType {
  Small = "small",
  Medium = "medium",
  High = "high",
  Auto = "auto"
}

export type CardOwnProps = {
  type?: CardType
  mobileType?: CardType
  withShadow?: boolean
  withShadowThick?: boolean
  fluid?: boolean
  fluidOnMobile?: boolean
  noTop?: boolean
  noBorder?: boolean
  hiddenOverflow?: boolean
  e2eTarget?: string
  e2eTargetName?: string
  wrapperRef?: React.RefObject<HTMLDivElement>
  heightType?: HeightType
}
export interface CardProps
  extends React.HTMLAttributes<HTMLDivElement>,
    CardOwnProps {
  children: React.ReactNode
}

const Card = (props: CardProps) => {
  const {
    children,
    type = CardType.Square,
    withShadow,
    withShadowThick,
    fluid,
    fluidOnMobile,
    noTop,
    noBorder = false,
    mobileType = CardType.Square,
    hiddenOverflow = false,
    e2eTarget = "card",
    e2eTargetName,
    wrapperRef,
    heightType,
    ...other
  } = props

  return (
    <div
      className={cx({
        base: type !== CardType.Clear,
        round: type === CardType.Round,
        round16: type === CardType.Round16,
        "rounded-top": type === CardType.RoundedTop,
        "mobile-rounded-top": mobileType === CardType.RoundedTop,
        "mobile-border-top": mobileType === CardType.BorderTop,
        "mobile-round": mobileType === CardType.Round,
        "mobile-round16": mobileType === CardType.Round16,
        square: type === CardType.Square,
        "mobile-square": mobileType === CardType.Square,
        overlay: type === CardType.Overlay,
        "mobile-overlay": mobileType === CardType.Overlay,
        clear: type === CardType.Clear,
        "mobile-clear": mobileType === CardType.Clear,
        "base--shadow": withShadow,
        "base--shadow-thick": withShadowThick,
        "base--no-top": noTop,
        "base--no-border": noBorder,
        "base--full-height": heightType === HeightType.High,
        "base--medium-height": heightType === HeightType.Medium,
        "base--small-height": heightType === HeightType.Small,
        "base--auto-height": heightType === HeightType.Auto,
        "base--hidden-overflow": hiddenOverflow,
        fluid,
        "fluid--on-mobile": fluidOnMobile
      })}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
      ref={wrapperRef}
      {...other}
    >
      {children}
    </div>
  )
}

export { Card, Card as default }
