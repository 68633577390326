import * as shared from "../shared/shared-consts"

const Region = {
  trustpilotId: "f58ffd885b",
  trustpilotBusinessId: "5d5aad5371f03e00012e4df8",
  trustpilotInvitationTemplateId: "529c0bd6748a510aa8cb03e4",

  googleapisKey: shared.GOOGLEAPIS_KEY,
  mainRegion: true,
  hasVat: true,
  languageTag: "fr-FR",
  iterableLocale: "fr-FR",
  locale: "fr",
  phoneCountryCode: "+33",
  defaultShippingCountryCode: "FR",
  mainDomain: shared.PL_MAIN_DOMAIN,

  secondaryAnalyticsCode: "UA-71580330-12",
  productRegion: "fr",
  countryCode: "fr",
  emailAddress: "hello@packhelp.fr",
  emailSenderName: "Packhelp.fr",
  phoneNumber: "01 70 93 71 98",

  logoSVGIcon: "v3-packhelp_color_on_white",
  logoSVGIconWhite: "v3-packhelp_white_logo_on_black",

  additionalCustoms: 0,

  pageTitle: "Editor - Packhelp.fr",
  footerLabel: "Packhelp",
  frontendBoxDesignRoute: "box/design",
  frontendAccountOrdersRoute: "account/orders",

  frontendTermsOfServiceRoute: "/terms_of_service/", // english is on purpose
  frontendPrivacyPolicyRoute: "/privacy_policy/", // english is on purpose

  domain: {
    basename: "packhelp",
    tld: "fr",
  },
  externalLinks: {
    affiliates: "/recommandation-packhelp-affiliation/",
    fscCertification: "/certification-fsc/",
    helpPlantTrees: "/planter-des-arbres/",
    ecoProperties: "/guide-des-caracteristiques-ecologiques/",
    sustainability: "/eco-responsabilite/",
    designShowcase: "/designs-motifs/",
    holidays: "/saison-des-fetes/",
    inspirationsHandbook: "/inspirations-handbook/",
    mobileAppLandingPageUrl: "/application-mobile/",
    brief: "/brief/",
    artworkTipsDielines: "/conseils-pour-la-creation-artistique-et-patrons/",
    help: "/aide/",
    thankYou: "/merci/",
    designGuidelines: "/pomoc/tematy/editeur-de-boites/",
    about: "/a-propos-de-nous/",
    career: "/carriere/",
    shop: "/boutique/",
    services: "/services/",
    industries: "/i/",
    inspirations: "/inspirations/",
    impressum: "/impressum/",
    returnPolicy: "/aide/sujet/puis-je-retourner-les-boites-commandees/",
    blog: "/notre-blog/",
    contact: "/contact/",
    privacyPolicy: "/privacy_policy/",
    termsOfService: "/terms_of_service/",
    termsOfServicePlus: "/terms-of-services-plus/",
    accountSignOut: "/sign_out/",
    accountSignIn: "/sign_in/",
    accountRegister: "/registration",
    accountOrders: "/account/orders",
    accountOrderDetails: "/account/orders/:orderNumber",
    accountSettings: "/account/settings",
    accountSupportReforestation: "/account/support-reforestation",
    accountBoxes: "/account/boxes",
    accountWallet: "/account/packhelp-wallet",
    accountCart: "/cart/", // old checkout key - to be removed
    samples: "/sample-packs",
    customOffer: "/offer/:token/review",
    payment: "/order/:orderId/payment",
    paymentSummary: "/order/:orderId/payment/summary",
    quoteRequest: "account/quote_requests/:qrNumber",
    cart: "/cart/",
    bundles: "/sets-d-emballages",
    orderDetails: "/order",
    crossSell: "/cart/recommended-products-and-services/s",
    fullAndEcoColorHelpSite:
      "/aide/sujet/quelle-est-la-difference-entre-les-standards-quadrichrome-et-eco-couleur/",
    safeZonesHelpSite: "/aide/sujet/ecartez-vous-des-bords/",
    samplePacksLink:
      "/box/design?sku=box-fr--samples--samples--samples--samples--samples&dielineMode=false",
    termsAcceptedSite: "/termes-acceptes-avec-succes/",
    packhelpWallet: "/wallet-programme-fidelite",
    ecoBadge: "/eco-badge-packhelp/",
    leadership: "/le-leadership-packhelp/",
    press: "/presse/",
    urlWhitelistedToBeFetchedFromUrl: shared.urlWhitelistedToBeFetchedFromUrl,
    products: {
      "mailer-box-plain": "/boite-dexpedition-sans-impression/",
      "mailer-box-eco": "/boite-dexpedition-eco/",
      "mailer-box-eco-white": "/boite-dexpedition-eco-blanche/",
      "mailer-box-full-color": "/boite-dexpedition-quadrichrome/",
      "mailer-box-eco-color": "/boite-dexpedition-eco-couleur/",
      "delivery-mailer-box": "/boite-postale-en-carton/",
      "delivery-mailer-box-plain": "/boite-postale-en-carton-sans-impression/",
      "shipping-box-plain": "/caisse-carton-sans-impression/",
      "shipping-box": "/caisse-carton-eco/",
      "product-box": "/boite-produit-classique/",
      "product-box-two-pieces": "/boite-produit-deux-pieces/",
      "poly-mailer": "/sacs-de-polymail-classiques/",
      "paper-mailer": "/pochette-kraft-pour-colis/",
      "paper-bag": "/sacs-kraft-a-poignees-torsadees/",
      "rigid-envelope": "/enveloppe-kraft-sur-mesure/",
      "product-box-wine-box": "/boites-a-vin-personnalisees/",
      "tissue-paper": "/papier-de-soie-personnalise/",
      "packing-paper": "/papier-d-emballage/",
      "sleeved-mailer-box": "/boites-avec-bandeau/",
      "kraft-tape": "/rubans-adhesifs-kraft/",
      "bio-poly-mailer": "/sac-expedition-plastique-biodegradable/",
      "paper-can": "/emballage-cadeau-canette/",
      "packing-tape": "/rubans-adhesifs-personnalises/",
      "mailer-box-white-ink": "/boite-kraft-a-encre-blanche/",
      "mailer-box-full-color-double-sided":
        "/boite-avec-impression-interieure/",
      "rigid-box": "/boite-coffret-avec-impression/",
      "rigid-box-two-pieces": "/coffret-carton/",
      "special-box": "/boite-speciale-sur-mesure/",
      "magnetic-box": "/boite-aimantee/",
      "carrier-box": "/boite-de-transport-en-carton/",
      "rigid-drawer-box": "/boite-tiroir-en-carton/",
      "cake-box": "/boite-a-gateau",
      "bio-poly-mailer-plain": "/sac-expedition-bioplastique-pre-imprime/",
      "pre-printed-mailer-box-eco": "/boite-dexpedition-eco-pre-imprime/",
      "pre-printed-mailer-box-full-colour":
        "/boite-dexpedition-quadrichrome-pre-imprime/",
      "doypack-bag": "/sachets-stand-up/",
      "square-label": "/etiquettes-personnalisees/",
      "tote-bag": "/tote-bag/",
      "printed-card": "/carte-de-correspondance/",
      "pre-printed-kraft-tape": "/ruban-adhesif-kraft-pre-imprime/",
      "recycled-poly-mailer": "/sac-expedition-colis-plastique-recycle/",
    },
    categories: {
      "mailer-boxes": "/boites-d-expedition/",
      "custom-shipping-boxes": "/boites-carton-colis/",
      "primary-packaging": "/emballages-produit/",
      "eco-friendly-packaging": "/emballage-recyclable/",
      "luxury-packaging": "/boites-carton-rigide/",
    },
    fb_url: shared.EU_MAIN_DOMAIN,
    fb_sitename: "Packhelp",
    fb_image: "/share_image_1200x630.png",
    share_img: "/share_image_120x120.png", // Editor
    boxEditorPath: "/box/design",
    productEditor: "/editor/product/design",
    exampleDesign: "79485?language=fr&templateMode=true",
    productQuotationSystem: "/quote",
    pqsSuccessPage: "/quote/success",
    plus: {
      catalogue: "/plus/catalogue/",
      successPage: "/plus/success",
      items: "/packhelp-plus/items",
      quotes: "/packhelp-plus/quotes",
      quoteShow: "/packhelp-plus/quotes/:token",
      orders: "/packhelp-plus/orders",
      orderShow: "/packhelp-plus/orders/:orderNumber/details",
      orderPlaced: "/packhelp-plus/orders/:orderNumber/placed",
      orderItems: "/packhelp-plus/orders/:orderNumber/items",
      orderPaymentSummary: "/packhelp-plus/orders/:orderNumber/payment-summary",
      "doypack-bag": "/plus/catalogue/sachets-stand-up/",
      personalDetails: "/packhelp-plus/personal-details",
      "tote-bag": "/plus/catalogue/tote-bag-personnalise/",
      "product-boxes": "plus/catalogue/types-demballages/boites-pliables/",
      "mailer-boxes": "plus/catalogue/types-demballages/boites-d-expedition/",
    },
    pro: {
      features: "pro/features/",
      suppliers: "pro/for-suppliers/",
      termsOfService: "pro/terms-conditions/",
    },
    industriesListing: "/i/",
    whiteLabel: "/enterprise/",
  },

  outsidePackhelpDomainLinks: {
    ...shared.externalDomainPackhelpLinks,
  },

  ...shared.SHARED_ECOMMERCE_PROPERTIES.eu,
  ...shared.SHARED_CURRENCY_PROPERTIES.eur,

  remindMeOnDesktop: {
    remindme_form_url:
      "//packhelp.us12.list-manage.com/subscribe/post-json?u=2060c1de14ec30d28a9fab813&amp;id=ef5d35223f&c=?",
    remindme_hidden_input_name: "b_2060c1de14ec30d28a9fab813_ef5d35223f",
  },

  datesFormattingRules: shared.DATES_FORMATTING_RULES_EU,

  googleConversionId: "859901857",
  googleAnalytics: {
    primaryCode: "UA-71580330-2",
    secondaryCode: "UA-71580330-12",
    segmentCode: "uXDomf9GW2BCffFseKvLrarxNiGNLI5O",
  },
  googleAdWords: {
    remarketingCode: "39198",
    conversionCode: "859901857",
  },

  facebookAppId: "1660833110841347",
  supportsPlus: true,
}

export default Region
