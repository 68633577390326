var SAMPLE_PACKS_DEFINITIONS = {
    "box-eu--samples--samples--samples--samples--samples": {
        products: [
            "box-eu--mailer-box--23--cardboard-natural--print-monochrome--foil-none",
            "box-eu--mailer-box--52--cardboard-white-one-side--print-monochrome--foil-none",
            "box-eu--mailer-box--62--cardboard-coated--print-color-hd--foil-matte",
            "box-eu--mailer-box--79--cardboard-coated--print-color-hd--foil-glossy",
        ],
        metadata: {
            productionTime: {
                default: "2",
            },
        },
    },
    "box-de--samples--samples--samples--samples--samples": {
        products: [
            "box-de--mailer-box--23--cardboard-natural--print-monochrome--foil-none",
            "box-de--mailer-box--52--cardboard-white-one-side--print-monochrome--foil-none",
            "box-de--mailer-box--62--cardboard-coated--print-color-hd--foil-matte",
            "box-de--mailer-box--79--cardboard-coated--print-color-hd--foil-glossy",
        ],
        metadata: {
            productionTime: {
                default: "2",
            },
        },
    },
    "box-es--samples--samples--samples--samples--samples": {
        products: [
            "box-es--mailer-box--23--cardboard-natural--print-monochrome--foil-none",
            "box-es--mailer-box--52--cardboard-white-one-side--print-monochrome--foil-none",
            "box-es--mailer-box--62--cardboard-coated--print-color-hd--foil-matte",
            "box-es--mailer-box--79--cardboard-coated--print-color-hd--foil-glossy",
        ],
        metadata: {
            productionTime: {
                default: "2",
            },
        },
    },
    "box-cz--samples--samples--samples--samples--samples": {
        products: [
            "box-cz--mailer-box--23--cardboard-natural--print-monochrome--foil-none",
            "box-cz--mailer-box--52--cardboard-white-one-side--print-monochrome--foil-none",
            "box-cz--mailer-box--62--cardboard-coated--print-color-hd--foil-matte",
            "box-cz--mailer-box--79--cardboard-coated--print-color-hd--foil-glossy",
        ],
        metadata: {
            productionTime: {
                default: "2",
            },
        },
    },
    "box-pl--samples--samples--samples--samples--samples": {
        products: [
            "box-pl--mailer-box--23--cardboard-natural--print-monochrome--foil-none",
            "box-pl--mailer-box--52--cardboard-white-one-side--print-monochrome--foil-none",
            "box-pl--mailer-box--62--cardboard-coated--print-color-hd--foil-matte",
            "box-pl--mailer-box--79--cardboard-coated--print-color-hd--foil-glossy",
        ],
        metadata: {
            productionTime: {
                default: "2",
            },
        },
    },
    "box-gb--samples--samples--samples--samples--samples": {
        products: [
            "box-gb--mailer-box--23--cardboard-natural--print-monochrome--foil-none",
            "box-gb--mailer-box--52--cardboard-white-one-side--print-monochrome--foil-none",
            "box-gb--mailer-box--62--cardboard-coated--print-color-hd--foil-matte",
            "box-gb--mailer-box--79--cardboard-coated--print-color-hd--foil-glossy",
        ],
        metadata: {
            productionTime: {
                default: "2",
            },
        },
    },
    "box-nl--samples--samples--samples--samples--samples": {
        products: [
            "box-nl--mailer-box--23--cardboard-natural--print-monochrome--foil-none",
            "box-nl--mailer-box--52--cardboard-white-one-side--print-monochrome--foil-none",
            "box-nl--mailer-box--62--cardboard-coated--print-color-hd--foil-matte",
            "box-nl--mailer-box--79--cardboard-coated--print-color-hd--foil-glossy",
        ],
        metadata: {
            productionTime: {
                default: "2",
            },
        },
    },
    "box-ro--samples--samples--samples--samples--samples": {
        products: [
            "box-ro--mailer-box--23--cardboard-natural--print-monochrome--foil-none",
            "box-ro--mailer-box--52--cardboard-white-one-side--print-monochrome--foil-none",
            "box-ro--mailer-box--62--cardboard-coated--print-color-hd--foil-matte",
            "box-ro--mailer-box--79--cardboard-coated--print-color-hd--foil-glossy",
        ],
        metadata: {
            productionTime: {
                default: "2",
            },
        },
    },
    "box-se--samples--samples--samples--samples--samples": {
        products: [
            "box-se--mailer-box--23--cardboard-natural--print-monochrome--foil-none",
            "box-se--mailer-box--52--cardboard-white-one-side--print-monochrome--foil-none",
            "box-se--mailer-box--62--cardboard-coated--print-color-hd--foil-matte",
            "box-se--mailer-box--79--cardboard-coated--print-color-hd--foil-glossy",
        ],
        metadata: {
            productionTime: {
                default: "2",
            },
        },
    },
    "box-fr--samples--samples--samples--samples--samples": {
        products: [
            "box-fr--mailer-box--23--cardboard-natural--print-monochrome--foil-none",
            "box-fr--mailer-box--52--cardboard-white-one-side--print-monochrome--foil-none",
            "box-fr--mailer-box--62--cardboard-coated--print-color-hd--foil-matte",
            "box-fr--mailer-box--79--cardboard-coated--print-color-hd--foil-glossy",
        ],
        metadata: {
            productionTime: {
                default: "2",
            },
        },
    },
    "box-it--samples--samples--samples--samples--samples": {
        products: [
            "box-it--mailer-box--23--cardboard-natural--print-monochrome--foil-none",
            "box-it--mailer-box--52--cardboard-white-one-side--print-monochrome--foil-none",
            "box-it--mailer-box--62--cardboard-coated--print-color-hd--foil-matte",
            "box-it--mailer-box--79--cardboard-coated--print-color-hd--foil-glossy",
        ],
        metadata: {
            productionTime: {
                default: "2",
            },
        },
    },
};
export default SAMPLE_PACKS_DEFINITIONS;
