import React from "react"
import { observer } from "mobx-react-lite"
import { Loader } from "../../dsl/atoms/Loader/Loader"
import { useRootStore } from "../../hooks/useRootStore"
import { useContainerSet } from "../../../_containers-react/_editor-app-hooks"
import { useWindowSize } from "../../hooks/useWindowSize"
import { isViewportTooSmall } from "../proscenium/utils/proscenium.helpers"

export enum LoadingStatesIntl {
  generic = "components.curtain.loading-states.generic-loading",
  loadingDesign = "components.curtain.loading-states.loading-design",
  loadingSide = "components.curtain.loading-states.loading-side",
  savingDesign = "components.curtain.loading-states.saving-design",
  addingToCart = "components.curtain.loading-states.adding-to-cart",
  resizing = "components.renderer.loaders.resizing",
  restarting = "components.renderer.loaders.reseting",
  duplicatingDesign = "components.renderer.loaders.duplicating-design",
}

export const Curtain = observer(({ children }) => {
  const { isBootstraped } = useRootStore()

  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.ecommerce,
  ])
  const windowSize = useWindowSize()
  const isTooSmall =
    windowSize.width &&
    windowSize.height &&
    isViewportTooSmall(windowSize.width, windowSize.height)

  if (!containerSet) {
    return null
  }

  const { designAndProductDriver, ecommerce } = containerSet
  const { productDesignStore, productDriver } = designAndProductDriver
  const { cartStore } = ecommerce

  let isLoading = false
  let isLoadingText = LoadingStatesIntl.generic

  if (cartStore?.isAddingToCart || cartStore?.isTriggeredAddingToCart) {
    isLoadingText = LoadingStatesIntl.addingToCart
  }

  if (productDesignStore.state.isProductDesignLoading) {
    isLoadingText = LoadingStatesIntl.loadingDesign
  }

  if (productDesignStore.state.isProductDesignDuplicating) {
    isLoadingText = LoadingStatesIntl.duplicatingDesign
  }

  if (
    productDesignStore.state.isProductDesignDuplicating ||
    productDriver.state.isProductLoading ||
    cartStore?.isAddingToCart ||
    cartStore?.isTriggeredAddingToCart ||
    productDesignStore.state.isProductDesignLocking ||
    !isBootstraped
  ) {
    isLoading = true
  }

  const isDraftPurchaseEnabled = true

  const renderLoaderWithChildren =
    isDraftPurchaseEnabled && isTooSmall && cartStore?.isInstantPurchase

  if (isLoading) {
    return <Loader formattedMessageID={isLoadingText} />
  } else {
    if (renderLoaderWithChildren) {
      //renders children under loader - for snapshots bndl
      return (
        <>
          <Loader formattedMessageID={isLoadingText} />
          <div style={{ opacity: 0 }}> {children} </div>
        </>
      )
    } else {
      return children
    }
  }
})
