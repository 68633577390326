import EuRegion from "./eu"
import * as shared from "../shared/shared-consts"

const Region = {
  ...EuRegion,

  languageTag: "nl-NL",
  iterableLocale: "nl-NL",
  locale: "nl",
  phoneCountryCode: "+45",
  defaultShippingCountryCode: "NL",
  mainDomain: shared.NL_MAIN_DOMAIN,

  secondaryAnalyticsCode: "UA-71580330-29",
  productRegion: "nl",
  countryCode: "nl",
  emailAddress: "hello@packhelp.com",
  emailSenderName: "Packhelp.com",
  phoneNumber: "",

  logoSVGIcon: "v3-packhelp_color_on_white",
  logoSVGIconWhite: "v3-packhelp_white_logo_on_black",

  additionalCustoms: 0,

  pageTitle: "Editor - Packhelp.nl",
  footerLabel: "Packhelp",
  frontendBoxDesignRoute: "box/design",
  frontendAccountOrdersRoute: "account/orders",

  frontendTermsOfServiceRoute: "/terms_of_service/", // english is on purpose
  frontendPrivacyPolicyRoute: "/privacy_policy/", // english is on purpose

  domain: {
    basename: "packhelp",
    tld: "com",
    pathBased: true,
    pathRegion: "/nl-nl",
  },
  externalLinks: {
    affiliates: "/affiliate/",
    suppliers: "/leveranciers/",
    fscCertification: "/fsc-certificering/",
    helpPlantTrees: "/help-bomen-planten/",
    ecoProperties: "/eco-eigenschappen/",
    sustainability: "/duurzaamheid/",
    designShowcase: "/design-vitrine/",
    inspirationsHandbook: "/inspiratiegids/",
    mobileAppLandingPageUrl: "/mobiele-app/",
    brief: "/brief/",
    artworkTipsDielines: "/artwork-tips-sjabloon/",
    help: "/help/",
    thankYou: "/bedankt/",
    designGuidelines: "/help/onderwerpen/editor/",
    about: "/over-ons/",
    career: "/carriere/",
    shop: "/webshop/",
    services: "/services/",
    industries: "/i/",
    bundles: "/bundels",
    inspirations: "/inspiratie/",
    impressum: "/impressum/",
    returnPolicy: "/help/onderwerp/bestelde-dozen-retourneren/",
    blog: "/blog/",
    contact: "/contact/",
    holidays: "/holidays/",
    privacyPolicy: "/privacy_policy/",
    termsOfService: "/terms_of_service/",
    termsOfServicePlus: "/terms-of-services-plus/",
    accountSignOut: "/sign_out/",
    accountSignIn: "/sign_in/",
    accountRegister: "/registration",
    accountOrders: "/account/orders",
    accountOrderDetails: "/account/orders/:orderNumber",
    accountSettings: "/account/settings",
    accountWallet: "/account/packhelp-wallet",
    accountBoxes: "/account/boxes",
    accountSupportReforestation: "/account/support-reforestation",
    accountCart: "/cart/",
    samples: "/sample-packs",
    customOffer: "offer/:token/review",
    quoteRequest: "account/quote_requests/:qrNumber",
    cart: "/cart/",
    orderDetails: "/order",
    crossSell: "/cart/recommended-products-and-services/s",
    fullAndEcoColorHelpSite: "/help/onderwerpen/full-color-en-eco-kleur/",
    safeZonesHelpSite: "/help/onderwerp/veilige-zones/",
    samplePacksLink:
      "/box/design?sku=box-eu--samples--samples--samples--samples--samples&dielineMode=false",
    termsAcceptedSite: "/voorwaarden-geaccepteerd",
    paymentSummary: "/order/:orderId/payment/summary",
    packhelpWallet: "/packhelp-wallet",
    ecoBadge: "/eco-badge/",
    leadership: "/bestuur/",
    press: "/pers/",
    urlWhitelistedToBeFetchedFromUrl: [
      "/order/:orderNumber/payment",
      "/order/:orderNumber/details",
    ],
    products: {
      "mailer-box-plain": "/onbedrukte-verzenddozen/",
      "mailer-box-eco": "/eco-verzenddozen/",
      "mailer-box-eco-white": "/eco-verzenddozen/",
      "mailer-box-full-color": "/full-color-verzenddozen/",
      "mailer-box-eco-color": "/eco-kleur-verzenddozen/",
      "delivery-mailer-box": "/e-commerce-postdozen/",
      "delivery-mailer-box-plain": "/onbedrukte-postdozen/",
      "shipping-box-plain": "/onbedrukte-kartonnen-dozen/",
      "shipping-box": "/speciale-kartonnen-dozen/",
      "product-box": "/klassieke-productdozen/",
      "product-box-two-pieces": "/dekseldozen/",
      "poly-mailer": "/klassieke-poly-verzendzakken/",
      "paper-mailer": "/kraft-blokbodemzakken/",
      "paper-bag": "/papieren-tassen/",
      "rigid-envelope": "/speciale-kartonnen-enveloppen/",
      "product-box-wine-box": "/wijndozen/",
      "tissue-paper": "/vloeipapier-op-maat/",
      "packing-paper": "/inpakpapier/",
      "packing-tape": "/bedrukt-plakband/",
      "sleeved-mailer-box": "/verzenddozen-met-sleeve/",
      "kraft-tape": "/nastri-adesivi-kraft/",
      "bio-poly-mailer": "/biologisch-afbreekbare-verzendzakken/",
      "paper-can": "/papieren-kokers/",
      "mailer-box-white-ink": "/wit-op-kraft-verzenddozen/",
      "mailer-box-full-color-double-sided":
        "/dubbelzijdig-bedrukte-verzenddoos/",
      "rigid-box": "/luxe-doos/",
      "rigid-box-two-pieces": "/luxe-doos-met-deksel/",
      "special-box": "/scatola-speciale-su-misura/", // no links in CMS
      "magnetic-box": "/magneetdoos/",
      "carrier-box": "/doos-met-handvat/",
      "rigid-drawer-box": "/kartonnen-schuifdozen/",
      "cake-box": "/taartdoos/",
      "bio-poly-mailer-plain": "/voorbedrukte-bio-poly-verzendzakken/",
      "pre-printed-mailer-box-eco": "/voorbedrukte-eco-verzenddozen/",
      "pre-printed-mailer-box-full-colour":
        "/voorbedrukte-full-color-verzenddozen/",
      "doypack-bag": "/stazakken/",
      "square-label": "/etiketten-op-maat/",
      "tote-bag": "/tote-bag/",
      "printed-card": "/bedankkaartjes/",
      "pre-printed-kraft-tape": "/voorbedrukte-papieren-tape/",
      "recycled-poly-mailer": "/verzendzakken-van-gerecycled-plastic/",
    },
    categories: {
      "mailer-boxes": "/verzenddozen/",
      "custom-shipping-boxes": "/kartonnen-dozen/",
      "primary-packaging": "/productverpakkingen/",
      "eco-friendly-packaging": "/milieuvriendelijke-verpakkingen/",
      "luxury-packaging": "/presentatiedozen/",
    },
    fb_url: shared.EU_MAIN_DOMAIN,
    fb_sitename: "Packhelp",
    fb_image: "/share_image_1200x630.png",
    share_img: "/share_image_120x120.png", // Editor
    boxEditorPath: "/box/design",
    productEditor: "/editor/product/design",
    exampleDesign: "79485?language=nl&templateMode=true",
    productQuotationSystem: "/quote",
    pqsSuccessPage: "/quote/success",
    plus: {
      catalogue: "/plus/catalogue/",
      successPage: "/plus/success",
      items: "/packhelp-plus/items",
      quotes: "/packhelp-plus/quotes",
      orders: "/packhelp-plus/orders",
      quoteShow: "/packhelp-plus/quotes/:token",
      orderShow: "/packhelp-plus/orders/:orderNumber/details",
      orderPlaced: "/packhelp-plus/orders/:orderNumber/placed",
      orderItems: "/packhelp-plus/orders/:orderNumber/items",
      orderPaymentSummary: "/packhelp-plus/orders/:orderNumber/payment-summary",
      personalDetails: "/packhelp-plus/personal-details",
    },
    pro: {
      features: "pro/features/",
      suppliers: "pro/for-suppliers/",
      termsOfService: "pro/terms-conditions/",
    },
    industriesListing: "/i/",
    whiteLabel: "/enterprise/",
  },

  ...shared.SHARED_ECOMMERCE_PROPERTIES.eu,
  ...shared.SHARED_CURRENCY_PROPERTIES.eur,
  allowedPaymentMethods: [
    "paypal",
    "check",
    "stripe",
    "p24",
    "ideal",
    "bancontact",
    "eps",
    "giropay",
    "card",
  ],

  remindMeOnDesktop: {
    remindme_form_url:
      "//packhelp.us12.list-manage.com/subscribe/post-json?u=2060c1de14ec30d28a9fab813&amp;id=489822501e&c=?",
    remindme_hidden_input_name: "b_2060c1de14ec30d28a9fab813_489822501e",
  },

  datesFormattingRules: shared.DATES_FORMATTING_RULES_EU,

  googleConversionId: "859901857",
  googleAnalytics: {
    primaryCode: "UA-71580330-2",
    secondaryCode: "UA-71580330-29",
    segmentCode: "uXDomf9GW2BCffFseKvLrarxNiGNLI5O",
  },
  googleAdWords: {
    remarketingCode: "39198",
    conversionCode: "859901857",
  },

  facebookAppId: "1660833110841347",
  supportsPlus: false,
}

export default Region
