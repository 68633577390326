import React, { useState } from "react"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import { PriceBreak, SelectQty } from "dsl/src/components/SelectQty/SelectQty"
import _camelCase from "lodash/camelCase"
import { calculatePromoPrice } from "dsl/src/components/QuantityPicker/utils/calculate-promo-price"
import Badge, { BadgeColorTypes } from "dsl/src/atoms/Badge/Badge"
import { FormattedCurrency } from "shared-libs/src/js/shared-components/formatted-currency/FormattedCurrency"
import { useCurrency } from "../../../hooks/useCurrency"

import styles from "./QuantityPicker.module.scss"
import cxBinder from "classnames/bind"

import { TType } from "dsl/src/atoms/Typography/Typography"
import { TM } from "../../../TypographyI18n"
import { useTranslate } from "../../../hooks/useTranslate"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import { eventTree } from "../../../../stores/editor-events"
import { SelectItemData } from "dsl/src/atoms/Select/types"
import { shouldDisplayFixedDmsPromo } from "shared-libs/src/js/libs/helpers/experiment.helpers"

const cx = cxBinder.bind(styles)

const QuantityPickerPrice = (
  item: SelectItemData,
  currency: string,
  piecesPerUnit: number,
  shouldDisplayPromoBadge: boolean
) => {
  const sellableUnitQuantity = item.data.qty / piecesPerUnit

  const price = shouldDisplayPromoBadge
    ? calculatePromoPrice(40, item.data.price)
    : item.data.price

  if (!price) {
    return null
  }

  return (
    <div>
      {shouldDisplayPromoBadge && (
        <Badge badgeColor={BadgeColorTypes.WhiteThreeWithEntan}>-40%</Badge>
      )}
      <FormattedCurrency
        currency={currency}
        value={sellableUnitQuantity * price + item.data.priceTotalAdjuster}
        e2eTarget="total-money-amount-with-currency-symbol"
      />
    </div>
  )
}

const QuantityPickerPricePerItemTag = (
  item: PriceBreak,
  currency: string,
  piecesPerUnit: number,
  shouldDisplayPromoBadge: boolean
) => {
  const priceTotalAdjuster = item.priceTotalAdjuster || 0
  const price = item.price / piecesPerUnit
  const displayPrice = shouldDisplayPromoBadge
    ? calculatePromoPrice(40, price)
    : price

  if (!displayPrice) {
    return (
      <span className={styles.price_per_item_tag}>
        <TM
          id="box-configurator.z-box-configurator.get-a-quote"
          type={TType.Header13_500}
          htmlElement="span"
        />
      </span>
    )
  }

  const addonsPrice = priceTotalAdjuster / item.qty

  return (
    <span className={styles.price_per_item_tag}>
      <FormattedCurrency
        currency={currency}
        value={displayPrice + addonsPrice}
        e2e-target="per-unit-money-amount-with-currency-symbol"
      />
      <TM
        id="cart.single-order-view.order.piece-caption"
        type={TType.Header13_500}
        htmlElement="span"
      />
    </span>
  )
}

const QuantityPickerWired = observer(() => {
  const t = useTranslate()
  const currency = useCurrency()
  const [customQuantity, setCustomQuantity] = useState<number>()

  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.ecommerce,
    c.envUtil,
  ])

  if (!containerSet || !containerSet.ecommerce.available) {
    return null
  }

  const { productDriver, productDesignStore } =
    containerSet.designAndProductDriver
  const { taxStore, productPricingStore, currencyStore } =
    containerSet.ecommerce
  const { localeConfig } = currencyStore
  const { productRenderPilot } = productDriver.state
  const { ee } = containerSet.envUtil

  const product = productRenderPilot.getProduct()

  if (!product) {
    return null
  }

  const piecesPerUnit = product.variantManager.getPiecesPerUnit()
  const { meta: productDesignMeta, isDesignInCart } = productDesignStore.state
  const { designItems } = productDesignMeta
  const shouldDisplayDmsDiscountPromotion = shouldDisplayFixedDmsPromo(
    _camelCase(product.getEnglishName()),
    localeConfig.productRegion
  )

  const countVisibleQty = (sellableQty: number): number => {
    return sellableQty * piecesPerUnit
  }

  const countSellableQty = (visibleQty: number): number => {
    return visibleQty / piecesPerUnit
  }

  const getPriceBreaks = (): PriceBreak[] => {
    return productPricingStore
      .getPriceBreaks(
        product,
        toJS(designItems),
        customQuantity ? [customQuantity] : []
      )
      .map((priceBreak) => ({
        ...priceBreak,
        qty: countVisibleQty(priceBreak.qty),
      }))
  }

  const handleChangeQuantity = (visibleQty: number): void => {
    const sellableQty = countSellableQty(visibleQty)

    setCustomQuantity(undefined)
    productDesignStore.setProductQuantity(sellableQty)
  }

  const handleCloseSelect = (): void => {
    setCustomQuantity(undefined)
  }

  const handleInputValueChange = (visibleQty: number, price: number): void => {
    const sellableQty = countSellableQty(visibleQty)

    if (!product.quantitiesManager.getQuantityRanges().includes(sellableQty)) {
      setCustomQuantity(visibleQty)
    }

    ee.emit(eventTree.pd.customQtyTyped, sellableQty, price)
  }

  const isDisabled = isDesignInCart || productDesignMeta.dataReadOnly

  const productQuantityRange = productPricingStore.getQuantityRanges(product)
  const min = countVisibleQty(productQuantityRange[0])
  const max = countVisibleQty(
    productQuantityRange[productQuantityRange.length - 1]
  )

  return (
    <div
      className={cx({
        custom_select: true,
        "custom_select--long": true,
      })}
    >
      <SelectQty
        handleQtyChange={handleChangeQuantity}
        handleInputValueChange={handleInputValueChange}
        handleCloseSelect={handleCloseSelect}
        itemRenderProp={(item) =>
          QuantityPickerPrice(
            item,
            currency,
            piecesPerUnit,
            shouldDisplayDmsDiscountPromotion
          )
        }
        tagRenderProp={(item) =>
          QuantityPickerPricePerItemTag(
            item,
            currency,
            piecesPerUnit,
            shouldDisplayDmsDiscountPromotion
          )
        }
        step={product.quantitiesManager.getQuantityPickerStep()}
        disabled={isDisabled}
        selectedQty={countVisibleQty(productDesignMeta.quantity)}
        searchDisabled={isDisabled}
        priceBreaks={getPriceBreaks()}
        min={min}
        max={max}
        selectedItemBehaviour={{
          renderBadge: true,
          renderPrice: true,
        }}
        showBlueHeaderLabel={taxStore.taxConfig.hasTax}
        blueHeaderLabelText={t("generic.prices-vat-included")}
      />
    </div>
  )
})

export { QuantityPickerWired as QuantityPicker }
