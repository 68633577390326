import { action, observable, makeObservable } from "mobx"

import { ee, eventTree } from "../editor-events"
import { EditorMode } from "../../libs/products-render-config/types"

export enum EditorToolbarItem {
  background = "Background",
  patterns = "Patterns",
  eco = "Eco",
  addons = "Addons",
  text = "Text",
  shapes = "Shapes",
  images = "Images",
  fonts = "Fonts",
  logo = "Logo",
  templates = "Templates",
  materialAndColor = "MaterialAndColor",
  replicablePatterns = "Replicable Patterns",
  logoPlaceholder = "Logo Placeholder",
  fsc = "FSC",
  changeSize = "ChangeSize",
  productConfiguration = "ProductConfiguration",
  productSwitcher = "ProductSwitcher",

  // DevTools / Designer Mode
  devTools = "DevTools",
}

interface AvailableFeatures {
  trees: boolean
  patterns: boolean
  templates: boolean
  fsc: boolean
  productSwitcher: boolean
}

export class EditorToolbarStore {
  @observable public selectedTab: EditorToolbarItem | undefined

  constructor(
    private readonly editorMode: EditorMode,
    private readonly availableFeatures: AvailableFeatures
  ) {
    makeObservable(this)

    ee.on(eventTree.logo.plusClicked, () => {
      this.selectTab(EditorToolbarItem.logo)
    })

    ee.on(eventTree.productDriver.viewChanged, ({ previousSpace, space }) => {
      if (
        !previousSpace &&
        space &&
        !this.isTabAvailableInBoth3dAnd2d(this.selectedTab)
      ) {
        this.closeTab()
      }
    })

    ee.on(eventTree.activeTab.close, this.closeTab.bind(this))
  }

  public get availableToolbarItems(): EditorToolbarItem[] {
    const toolbarItems = [
      EditorToolbarItem.logo,
      EditorToolbarItem.logoPlaceholder,
      EditorToolbarItem.productConfiguration,
      EditorToolbarItem.replicablePatterns,
      EditorToolbarItem.materialAndColor,
      EditorToolbarItem.background,
      EditorToolbarItem.text,
      EditorToolbarItem.shapes,
      EditorToolbarItem.images,
      EditorToolbarItem.devTools,
    ]

    if (this.availableFeatures.templates) {
      toolbarItems.push(EditorToolbarItem.templates)
    }

    if (this.availableFeatures.patterns) {
      toolbarItems.push(EditorToolbarItem.patterns)
    }

    if (this.availableFeatures.fsc) {
      toolbarItems.push(EditorToolbarItem.fsc)
    }

    if (this.availableFeatures.productSwitcher) {
      toolbarItems.push(EditorToolbarItem.productSwitcher)
    }

    if (this.editorMode !== "dby" || this.availableFeatures.trees) {
      toolbarItems.push(EditorToolbarItem.eco)
    }

    return toolbarItems
  }

  @action
  private setSelectedTab(tab: EditorToolbarItem | undefined) {
    if (this.selectedTab === tab) {
      return
    }

    ee.emit(eventTree.tab.tabChanged, tab, this.selectedTab)
    this.selectedTab = tab

    if (tab) {
      ee.emit(eventTree.keyboard.setScope, "active-tab")
    } else {
      ee.emit(eventTree.keyboard.clearScope, "active-tab")
    }
  }

  public selectTab(tab: EditorToolbarItem) {
    this.setSelectedTab(tab)
  }

  public isTabSelected(tab?: EditorToolbarItem) {
    if (tab) {
      return this.selectedTab === tab
    }

    return !!this.selectedTab
  }

  public isSubTabSelected(tab?: EditorToolbarItem): boolean {
    if (
      this.selectedTab === EditorToolbarItem.changeSize &&
      tab === EditorToolbarItem.productConfiguration
    ) {
      return true
    }

    return false
  }

  public closeTab() {
    if (this.selectedTab === EditorToolbarItem.changeSize) {
      return this.setSelectedTab(EditorToolbarItem.productConfiguration)
    }

    this.setSelectedTab(undefined)
  }

  public toggleTab(tab: EditorToolbarItem) {
    if (!this.isTabSelected(tab)) {
      this.selectTab(tab)
    } else {
      this.closeTab()
    }
  }

  private isTabAvailableInBoth3dAnd2d(tab: EditorToolbarItem | undefined) {
    return tab === EditorToolbarItem.logo
  }
}
