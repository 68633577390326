import { ProductDesignStore } from "../../product-design-store/product-design.store"
import { ProductDesignSaveController } from "./product-design-save.controller"
import { UrlManipulatorProvider } from "../../../services/url-manipulator"
import ProductDriver from "../../product-driver/product.driver"
import { DesignApi } from "@ph/design-api"
import type { EcommerceApi } from "../../../modules/ecommerce-api/types"

export class ProductDesignUiController {
  constructor(
    private readonly productDriver: ProductDriver,
    private readonly productDesignStore: ProductDesignStore,
    private readonly saveController: ProductDesignSaveController,
    private readonly designApi: DesignApi,
    private readonly ecommerceApi: EcommerceApi,
    private readonly uri: UrlManipulatorProvider
  ) {}

  public async share() {
    this.productDesignStore.setIsProductDesignSharing(true)
    this.productDesignStore.setIsProductDesignPublic(true)

    await this.saveController.save({ force: true })

    this.productDesignStore.setIsProductDesignSharing(false)
  }

  public async lock() {
    try {
      await this.designApi.lock(this.designId)
    } catch (e) {
      this.productDesignStore.setLockDesignError(e)
    }
  }

  public async shareWithEmail(email: string) {
    try {
      this.productDesignStore.setIsSharedWithEmail(false)
      this.productDesignStore.setIsSharingWithEmail(true)
      await this.designApi.share(this.designId, email)
      this.productDesignStore.setIsSharingWithEmail(false)
      this.productDesignStore.setIsSharedWithEmail(true)
    } catch (e) {
      this.productDesignStore.setIsSharingWithEmail(false)
      this.productDesignStore.setIsSharedWithEmail(false)
      this.productDesignStore.setShareWithEmailError(e)
    }
  }

  public async duplicate() {
    this.productDesignStore.setIsProductDesignDuplicating(true)

    try {
      const response = await this.designApi.duplicate(this.designId)
      await this.ecommerceApi.user.duplicateDesign({
        id: response.id,
        read_token: response.read_token,
        write_token: response.write_token,
        thumbnail: response.thumbnail,
        name: response.name,
        external_pim_sku: response.external_pim_sku,
        design_status: response.design_status,
      })
      this.uri.redirectToDesign(response.id)
    } catch (e) {
      this.productDesignStore.setDuplicateDesignError(e)
    }
  }

  public async save(options?: { name?: string; notifyOnSuccess?: boolean }) {
    if (options?.name) {
      this.productDesignStore.setDesignName(options.name)
    }

    await this.saveController.save({
      force: true,
      notifyOnSuccess: !!options?.notifyOnSuccess,
    })
  }

  public async restart() {
    this.productDesignStore.setDesignTouched(false)

    const quantity = this.productDesignStore.state.meta.quantity
    const sku = this.productDriver.productStore.productSku

    this.uri.resetPathToBase(sku, quantity)
  }

  private get designId(): string | number {
    const { id } = this.productDesignStore.state.meta

    if (!id) {
      throw new Error("Design ID is not defined.")
    }

    return id
  }
}
