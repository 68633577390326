import React from "react"
import { FormattedMessage } from "react-intl"
import { MenuItem } from "./EditorToolbar/EditorToolbar"
import {
  EditContext,
  ModelEditableSpaces,
  UIConfig,
  ViewType,
} from "../../../../libs/products-render-config/types"
import { EditorToolbarItem } from "../../../../stores/editor-toolbar-store"
import { ProductRenderPilot } from "../../../../libs/products-render-config/product-render-pilot"

export function filterMenuItems({
  items,
  productRenderPilot,
  activeViewType,
  activeContext,
  activeSpace,
  isDevToolsEnabled,
}: {
  items: MenuItem[]
  productRenderPilot: ProductRenderPilot
  activeViewType: ViewType
  activeContext: EditContext
  activeSpace: ModelEditableSpaces | null
  isDevToolsEnabled: boolean
}): MenuItem[] {
  return items.filter((item) => {
    // Check for dev tools use case
    if (item.devTools === true) {
      return isDevToolsEnabled
    }

    // All other use cases
    const isEnabled = isMenuItemEnabled(item, productRenderPilot.uiConfig)
    const isVisible = isMenuItemVisible(
      item,
      productRenderPilot.is3DProduct(),
      activeViewType,
      activeContext,
      activeSpace
    )

    return isEnabled && isVisible
  })
}

function isMenuItemEnabled(item: MenuItem, uiConfig: UIConfig): boolean {
  const keyIndex = Object.values(EditorToolbarItem).findIndex(
    (x) => x === item.name
  )
  const key = Object.keys(EditorToolbarItem)[keyIndex]

  const isTabEnabled = !!uiConfig.tabs[key]

  if (isTabEnabled !== undefined) {
    return isTabEnabled
  }

  return true
}

function isMenuItemVisible(
  menuItem: MenuItem,
  is3Product: boolean,
  activeViewType: ViewType,
  activeContext: EditContext,
  activeSpace: ModelEditableSpaces | null
): boolean {
  if (!is3Product) {
    const visibilityConfig = menuItem.visibility.product2D

    if (Array.isArray(visibilityConfig)) {
      return (
        visibilityConfig.includes(activeContext) ||
        !!(activeSpace && visibilityConfig.includes(activeSpace))
      )
    }

    return visibilityConfig
  }

  if (!menuItem.visibility.product3D) {
    return false
  }

  if (activeViewType === ViewType.SPACE) {
    return isMenuItemVisibleInContext(
      menuItem.visibility.product3D.spaceView,
      activeContext
    )
  }

  if (activeViewType === ViewType.DIELINE) {
    return isMenuItemVisibleInContext(
      menuItem.visibility.product3D.dielineView,
      activeContext
    )
  }

  return isMenuItemVisibleInContext(
    menuItem.visibility.product3D.modelView,
    activeContext
  )
}

function isMenuItemVisibleInContext(
  config: boolean | EditContext[],
  editContext: EditContext
): boolean {
  if (Array.isArray(config)) {
    return config.includes(editContext)
  }

  return !!config
}

export const getMenuItemLabel = (item: MenuItem) => {
  if (item.hideTitle) return <></>

  if (item.nameIntl) {
    return <FormattedMessage id={item.nameIntl} />
  }

  return <>{item.name}</>
}
