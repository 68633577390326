export const i18n = {
  print_with_us: "modal.download-or-print.print-with-us",
  download_assets: "modal.download-or-print.download-assets",
  review_and_purchase: "modal.download-or-print.review-and-purchase",
  buy_dieline: "modal.download-or-print.buy-dieline",
  produce_with_us: "modal.download-or-print.produce-with-us",
  also_buy: "modal.download-ir-print.also-buy",

  dieline: "modal.download-or-print.dieline",
  dieline_print: "modal.download-or-print.dieline-edit",
  dieline_edit: "modal.download-or-print.dieline-print",
  dieline_description: "modal.download-or-print.dieline-description",
  dieline_how_to_use: "modal.download-or-print.dieline-how-to-use",
  dieline_info: "modal.download-or-print.dieline-info",
  photography: "modal.download-or-print.photography",
  photography_description: "modal.download-or-print.photography-description",

  size: "modal.download-or-print.size",
  material: "modal.download-or-print.material",
  foil: "modal.download-or-print.foil",
  pattern: "modal.download-or-print.pattern",

  size_and_materials: "modal.download-or-print.size-and-material",
  addons: "modal.download-or-print.addons",
  template: "modal.download-or-print.template",
  fsc: "modal.download-or-print.fsc",

  quantity_and_price: "modal.download-or-print.quantity-and-price",
  price: "modal.download-or-print.price",
  vat_rate_included: "generic.vat-included", //  "{rate}% VAT included",

  not_available_in_your_country:
    "modal.download-or-print.not-available-in-your-country",

  add_to_cart: "generic.add-to-cart",

  saving: "box-editor.top.actions.label.saving",
  total: "order-flow-summary.value.total-price-summary.total-net-value",
  total_with_tax:
    "order-flow-summary.value.total-price-summary.total-value-with-tax",
}
