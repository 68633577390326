import React, { PropsWithChildren, useState } from "react"
import { observer } from "mobx-react-lite"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { useTranslate } from "../../../../hooks/useTranslate"
import { isHowToDataPresent } from "./utils/editor-header-menu.helpers"
import { ReactComponent as IconSave } from "../../../../assets/svg/save.svg"
import { ReactComponent as IconMore } from "../../../../assets/svg/dots.svg"
import { ReactComponent as IconShare } from "../../../../assets/svg/share.svg"
import { ReactComponent as IconGuidelines } from "../../../../assets/svg/guidelines.svg"
import { ReactComponent as IconRestart } from "../../../../assets/svg/restart.svg"
import { ReactComponent as IconHowTo } from "../../../../assets/svg/how-to.svg"
import { ReactComponent as IconDownloadDieline } from "../../../../assets/svg/downloadDielines.svg"
import { ReactComponent as IconUploadDesign } from "../../../../assets/svg/uploadDesign.svg"
import { ReactComponent as IconDesignOnline } from "../../../../assets/svg/designOnline.svg"
import { Dropdown } from "../../Dropdown/Dropdown"
import { DropdownItem } from "../../Dropdown/DropdownItem"
import cxBinder from "classnames/bind"
import styles from "./EditorHeaderMenu.module.scss"

const cx = cxBinder.bind(styles)

enum EditorMenuItem {
  restart = "component.editor-menu-item.restart",
  save = "component.editor-menu-item.save-project",
  share = "component.editor-menu-item.share",
  guidelines = "component.editor-menu-item.guidelines",
  howto = "component.editor-menu-item.how-to-use",
  more = "component.editor-menu-item.more",
  downloadDielines = "component.editor-menu-item.download-dielines",
  uploadDesign = "component.editor-menu-item.upload-design",
  designOnline = "component.editor-menu-item.design-online",
}

interface EditorHeaderMenuProps {
  onRestart(): void
  onSave(): void
  onShare(): void
  onShowGuidelines(): void
  onShowHowTo(): void
  onShowDownloadDielines(): void
}

interface EditorHeaderMenuItemProps {
  name: EditorMenuItem
  onClick(): void
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  e2eTarget?: string
  isHighlited?: boolean
  isDisabled?: boolean
}

const EditorHeaderMenuItem = ({
  Icon,
  name,
  onClick,
  e2eTarget,
  isHighlited,
  isDisabled,
}: EditorHeaderMenuItemProps) => {
  const t = useTranslate()

  return (
    <li
      className={cx({
        editor_header_menu__item: true,
        editor_header_menu__item__disabled: isDisabled,
      })}
      onClick={() => !isDisabled && onClick()}
      e2e-target={e2eTarget}
    >
      {Icon && (
        <Icon
          className={cx({
            editor_header_menu__item__icon__highlited: isHighlited,
          })}
        />
      )}

      <span>{t(name)}</span>
    </li>
  )
}

export const EditorHeaderMenu = observer(
  (props: PropsWithChildren<EditorHeaderMenuProps>) => {
    const t = useTranslate()
    const [isDropDownOpen, setIsDropDownOpen] = useState(false)

    const [headerSet, headerSetErrors] = useContainerSet((c) => [
      c.designAndProductDriver,
      c.envUtil,
      c.dbyMode,
      c.analytics,
      c.ui,
    ])

    if (headerSet == null || headerSetErrors != null) return null

    const { designAndProductDriver, dbyMode, analytics, ui } = headerSet

    const { productRenderPilot } = designAndProductDriver.productDriver.state
    const { isProductDesignSaving, isDraftDesignInCart } =
      designAndProductDriver.productDesignStore.state
    const { editorModeController } = ui
    const is3DProduct = productRenderPilot.is3DProduct()
    const isDbyMode = productRenderPilot.isDbyMode()
    const isEditorMode = productRenderPilot.getEditorMode() === "editor"
    const isProductDbyOnly = productRenderPilot.isDbyOnly()
    const isDbyModeFileNotUploaded = !dbyMode.dbyModeStore.uploadedFile
    const isDbyModeFileUploadFailed = !!dbyMode.dbyModeStore.uploadError
    const isSaveLocked =
      isProductDesignSaving ||
      (isDbyMode && (isDbyModeFileNotUploaded || isDbyModeFileUploadFailed))
    const isAfterPurchaseEdit = productRenderPilot.isAfterPurchaseEdit

    return (
      <nav className={styles.editor_header__menu}>
        <ul>
          <EditorHeaderMenuItem
            name={EditorMenuItem.downloadDielines}
            onClick={props.onShowDownloadDielines}
            Icon={IconDownloadDieline}
          />

          {isEditorMode && (
            <EditorHeaderMenuItem
              name={EditorMenuItem.uploadDesign}
              onClick={() => {
                editorModeController.switchToDbyMode()

                analytics.analyticsController?.trackHeaderElementClicked(
                  "uploadDesign"
                )
              }}
              Icon={IconUploadDesign}
            />
          )}

          {isDbyMode && !isProductDbyOnly && (
            <EditorHeaderMenuItem
              name={EditorMenuItem.designOnline}
              onClick={() => {
                editorModeController.switchToEditorMode()
                analytics.analyticsController?.trackHeaderElementClicked(
                  "designOnline"
                )
              }}
              Icon={IconDesignOnline}
            />
          )}

          {!isAfterPurchaseEdit && !isDraftDesignInCart && (
            <EditorHeaderMenuItem
              name={EditorMenuItem.save}
              onClick={props.onSave}
              Icon={IconSave}
              e2eTarget="header-button-save"
              isDisabled={isSaveLocked}
            />
          )}
          {!isDbyMode && (
            <EditorHeaderMenuItem
              name={EditorMenuItem.more}
              onClick={() => {
                setIsDropDownOpen(true)
              }}
              Icon={IconMore}
              e2eTarget="header-button-more"
              isHighlited={isDropDownOpen}
            />
          )}

          <div className={styles.editor_header_menu__dropdown}>
            {isDropDownOpen && (
              <Dropdown
                onClose={() => {
                  setIsDropDownOpen(false)
                }}
              >
                {!isAfterPurchaseEdit && !isDraftDesignInCart && (
                  <DropdownItem
                    name={t(EditorMenuItem.share)}
                    onClick={props.onShare}
                    Icon={IconShare}
                    e2eTarget="header-dropdown-button-share"
                  />
                )}
                {!isAfterPurchaseEdit && !isDraftDesignInCart && (
                  <DropdownItem
                    name={t(EditorMenuItem.restart)}
                    onClick={props.onRestart}
                    Icon={IconRestart}
                    withDivision={true}
                    e2eTarget="header-dropdown-button-restart"
                  />
                )}
                <DropdownItem
                  name={t(EditorMenuItem.guidelines)}
                  onClick={props.onShowGuidelines}
                  Icon={IconGuidelines}
                  e2eTarget="header-dropdown-button-guidelines"
                />
                {isHowToDataPresent() && is3DProduct && (
                  <DropdownItem
                    name={t(EditorMenuItem.howto)}
                    onClick={props.onShowHowTo}
                    Icon={IconHowTo}
                  />
                )}
              </Dropdown>
            )}
          </div>
        </ul>
        {props.children}
      </nav>
    )
  }
)
